import { GET_INTERESTS } from '../actions/interests';

const INITIAL_STATE = {
  interests: { data: [] },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INTERESTS:
      if (action.payload.status === 200) {
        return { ...state, interests: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, interests: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, interests: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, interests: action.payload.response };
      }

      break;
    default:
      return state;
  }
}
