import {
  POST_INSTITUTIONS,
  FETCH_INSTITUTIONS,
  FETCH_INSTITUTIONS_CATEGORIES,
  POST_INSTITUTION_PAYPAL_DONATION,
  POST_INSTITUTION_CANCEL_PAYPAL_DONATION,
  POST_INSTITUTION_EXECUTE_PAYPAL_DONATION,
  GET_NPO_DONATIONS_LIST,
  GET_NPO_PAYMENTS,
  GET_NPO_PAYMENT_DETAIL,
  POST_INSTITUTION_RECEIPT,
  POST_REQUIRE_PAYMENT,
  GET_INSTITUTION,
  POST_INSTITUTION_CROWDFUNDING_IMAGES,
  DELETE_CROWDFUNDING_INSTITUTION_IMAGE,
  POST_INSTITUTION_CROWDFUNDING,
  GET_INSTITUTION_CROWDFUNDING_DETAIL,
  EDIT_INSTITUTION_CROWDFUNDING,
} from '../actions';

const INITIAL_STATE = {
  newInstitution: null,
  institutions: null,
  categories: null,
  createPaypalDonation: null,
  cancelPaypalDonation: null,
  executePaypalDonation: null,
  npoDonationsList: null,
  npoPaymentsList: {},
  npoPaymentListDetails: null,
  institutionReceipt: null,
  requirePayment: null,
  myInstitution: null,
  institutionCrowdfundingImages: null,
  institutionCrowdfundingImageDeleted: null,
  institutionCrowdfunding: null,
  institutionCrowdfundingDetail: null,
  editInstitutionCrowdfunding: null,
};

export default function reducerInstitutions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_INSTITUTIONS:
      if (action.payload.status === 200) {
        return {
          ...state,
          newInstitution: action.payload.data,
          shouldRedirect: true,
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newInstitution: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newInstitution: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newInstitution: action.payload.response };
      }
      if (action.payload.response.status === 405) {
        return { ...state, newInstitution: action.payload.response };
      }
      break;

    case FETCH_INSTITUTIONS:
      if (action.payload.status === 200) {
        return { ...state, institutions: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, institutions: action.payload.response };
      }
      break;

    case FETCH_INSTITUTIONS_CATEGORIES:
      if (action.payload.status === 200) {
        return { ...state, categories: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, categories: action.payload.response };
      }
      break;

    case POST_INSTITUTION_PAYPAL_DONATION:
      if (action.payload.status === 200) {
        return { ...state, createPaypalDonation: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, createPaypalDonation: action.payload.response };
      }
      return { ...state, createPaypalDonation: action.payload.message };

    case POST_INSTITUTION_CANCEL_PAYPAL_DONATION:
      if (action.payload.status === 200) {
        return { ...state, cancelPaypalDonation: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, createPaypalDonation: action.payload.response };
      }
      return { ...state, cancelPaypalDonation: action.payload.message };

    case POST_INSTITUTION_EXECUTE_PAYPAL_DONATION:
      if (action.payload.status === 200) {
        return { ...state, executePaypalDonation: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, createPaypalDonation: action.payload.response };
      }
      return { ...state, executePaypalDonation: action.payload.message };

    case GET_NPO_DONATIONS_LIST:
      if (action.payload.status === 200) {
        return { ...state, npoDonationsList: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, npoDonationsList: action.payload.response };
      }
      return { ...state, npoDonationsList: action.payload.message };

    case GET_NPO_PAYMENTS:
      if (action.payload.status === 200) {
        return { ...state, npoPaymentsList: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, npoPaymentsList: action.payload.response };
      }
      return { ...state, npoPaymentsList: action.payload.message };

    case GET_NPO_PAYMENT_DETAIL:
      if (action.payload.status === 200) {
        return { ...state, npoPaymentListDetails: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, npoPaymentListDetails: action.payload.response };
      }
      return { ...state, npoPaymentListDetails: action.payload.message };

    case POST_INSTITUTION_RECEIPT:
      if (action.payload.status === 200) {
        return { ...state, institutionReceipt: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, institutionReceipt: action.payload.response };
      }
      return { ...state, institutionReceipt: action.payload.message };

    case POST_REQUIRE_PAYMENT:
      if (action.payload.status === 200) {
        return { ...state, requirePayment: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, requirePayment: action.payload.response };
      }
      return { ...state, requirePayment: action.payload.message };

    case GET_INSTITUTION:
      if (action.payload.status === 200) {
        return { ...state, myInstitution: action.payload.data };
      }
      if (action.payload.response.status === 500) {
        return { ...state, myInstitution: action.payload.response };
      }
      return { ...state, myInstitution: action.payload.message };

    case POST_INSTITUTION_CROWDFUNDING_IMAGES:
      if (action.payload.status === 200) {
        return { ...state, institutionCrowdfundingImages: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          institutionCrowdfundingImages: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          institutionCrowdfundingImages: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          institutionCrowdfundingImages: action.payload.response,
        };
      }

      break;

    case DELETE_CROWDFUNDING_INSTITUTION_IMAGE:
      if (action.payload.status === 200) {
        return {
          ...state,
          institutionCrowdfundingImageDeleted: action.payload.data,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          institutionCrowdfundingImageDeleted: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          institutionCrowdfundingImageDeleted: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          institutionCrowdfundingImageDeleted: action.payload.response,
        };
      }

      break;

    case POST_INSTITUTION_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, institutionCrowdfunding: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, institutionCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, institutionCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, institutionCrowdfunding: action.payload.response };
      }

      break;

    case GET_INSTITUTION_CROWDFUNDING_DETAIL:
      if (action.payload.status === 200) {
        return { ...state, institutionCrowdfundingDetail: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          institutionCrowdfundingDetail: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          institutionCrowdfundingDetail: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          institutionCrowdfundingDetail: action.payload.response,
        };
      }

      break;

    case EDIT_INSTITUTION_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, editInstitutionCrowdfunding: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          editInstitutionCrowdfunding: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          editInstitutionCrowdfunding: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          editInstitutionCrowdfunding: action.payload.response,
        };
      }

      break;

    default:
      return state;
  }
}
