import axios from 'axios';
import { FETCH_NOTIFICATIONS, POST_NOTIFICATIONS_ALL_AS_READ, POST_NOTIFICATION_AS_READ } from '..';

import ROOT_URL from '../../../shared/constants/apiUrl';

export function getNotifications(companyId, page = 1, readed = false) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/notifications?received_as=EMPLOYEE,MEMBER&page=${page}&readed=${readed}`
  );

  return {
    type: FETCH_NOTIFICATIONS,
    payload: request,
  };
}

export function markAllNotificationsAsRead(companyId) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/notifications/mark-all-read`, '');

  return {
    type: POST_NOTIFICATIONS_ALL_AS_READ,
    payload: request,
  };
}

export function markNotificationAsRead(companyId, notificationId) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/notifications/${notificationId}/mark-read`,
    ''
  );

  return {
    type: POST_NOTIFICATION_AS_READ,
    payload: request,
  };
}
