import {
  GET_CROUDFUNDING,
  GET_CROUDFUNDING_DETAIL,
  GET_CROUDFUNDING_CONTRIBUTES,
  GET_USERS_CONTRIBUTES_LIST,
  GET_SERVERTIME,
  GET_USERS_CROWDFUNDING_COMMENTS,
  POST_USERS_CROWDFUNDING_COMMENTS,
  DELETE_USERS_CROWDFUNDING_COMMENTS,
  POST_CROWDFUNDING_COMMENT_RESPONSE,
  GET_USER_CROWDFUNDING_COMMENT_RESPONSE,
  POST_USER_CROWDFUNDING_COMMENT_RESPONSE,
  GET_USERS_CROWDFUNDING_COMMENTS_PUSHER,
  GET_USER_CROWDFUNDING_COMMENT_RESPONSE_PUSHER,
  GET_PRIVATE_CROWDFUNDING_LIST,
  GET_PRIVATE_CROWDFUNDING_DETAIL,
  GET_PRIVATE_CROWDFUNDING_CONTRIBUTES_LIST,
  FETCH_PRIVATE_CROWDFUNDING_COMMENTS,
  POST_PRIVATE_CROWDFUNDING_COMMENT,
  DELETE_PRIVATE_CROWDFUNDING_COMMENT,
  GET_CROWDFUNDING_COMMENT_RESPONSE,
  POST_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE,
  DELETE_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE,
  POST_PRIVATE_CAMPAIGN_CONTRIBUTE,
  UPLOAD_USER_IMAGE_CROWDFUNDING,
  DELETE_USER_CROWDFUNDING_IMAGE,
  POST_USER_CROWDFUNDING,
  GET_USER_CROWDFUNDING,
  POST_UPDATE_CROWDFUNDING,
  GET_USER_MY_CROWDFUNDING,
  GET_MY_PRIVATE_CROWDFUNDING_LIST,
} from '../actions';

const INITIAL_STATE = {
  crowdfundingList: {},
  crowdfundingDetail: {},
  crowdfundingContributes: {},
  userContributesList: {},
  serverTime: null,
  userCrowdfundingCommentsList: { data: [] },
  userNewCrowdfundingCommentsList: {},
  userDeletedCrowdfundingCommentsList: {},
  newCrowdfundingCommentsResponse: {},
  userCrowdfundingCommentsResponse: {},
  newUserCrowdfundingCommentsResponse: {},
  userCrowdfundingCommentsListPusher: {},
  userCrowdfundingCommentsResponsePusher: {},
  crowdfundingPrivteList: {},
  crowdfundingPrivteDetail: {},
  privateContributesList: {},
  privateCrowdfundingCommentsList: { data: [] },
  newPrivateCrowdfundingComment: {},
  deletedPrivateCrowdfundingComment: {},
  privateCrowdfundingCommentResponsesList: {},
  newPrivateCrowdfundingCommentsResponse: {},
  deletePrivateCrowdfundingCommentsResponse: {},
  privateCampaignContribute: {},
  userImageCrowdfunding: {},
  userDeleteImageCrowdfunding: {},
  userAddCrowdfunding: {},
  userCrowdfunding: {},
  updateUserCrowdfunding: {},
  userMyCrowdfunding: {},
  crowdfundingMyPrivteList: {},
};

export default function reducerCrowdfunding(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CROUDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingList: action.payload.response };
      }
      break;

    case GET_CROUDFUNDING_DETAIL:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingDetail: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingDetail: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      break;

    case GET_CROUDFUNDING_CONTRIBUTES:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingContributes: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingContributes: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingContributes: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingContributes: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingContributes: action.payload.response };
      }
      break;

    case GET_USERS_CONTRIBUTES_LIST:
      if (action.payload.status === 200) {
        return { ...state, userContributesList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userContributesList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userContributesList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userContributesList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userContributesList: action.payload.response };
      }
      break;

    case GET_SERVERTIME:
      if (action.payload.status === 200) {
        return { ...state, time: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, time: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, time: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, time: action.payload.response };
      }
      break;

    case GET_USERS_CROWDFUNDING_COMMENTS:
      if (action.payload.status === 200) {
        return { ...state, userCrowdfundingCommentsList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userCrowdfundingCommentsList: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userCrowdfundingCommentsList: action.payload.response,
        };
      }
      break;

    case POST_USERS_CROWDFUNDING_COMMENTS:
      if (action.payload.status === 200) {
        return {
          ...state,
          userNewCrowdfundingCommentsList: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userNewCrowdfundingCommentsList: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userNewCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userNewCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userNewCrowdfundingCommentsList: action.payload.response,
        };
      }
      break;

    case DELETE_USERS_CROWDFUNDING_COMMENTS:
      if (action.payload.status === 200) {
        return {
          ...state,
          userDeletedCrowdfundingCommentsList: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userDeletedCrowdfundingCommentsList: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userDeletedCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userDeletedCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userDeletedCrowdfundingCommentsList: action.payload.response,
        };
      }
      break;

    case POST_CROWDFUNDING_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return {
          ...state,
          newCrowdfundingCommentsResponse: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          newCrowdfundingCommentsResponse: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          newCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          newCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          newCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      break;

    case GET_USER_CROWDFUNDING_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return {
          ...state,
          userCrowdfundingCommentsResponse: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userCrowdfundingCommentsResponse: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      break;

    case POST_USER_CROWDFUNDING_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return {
          ...state,
          newUserCrowdfundingCommentsResponse: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          newUserCrowdfundingCommentsResponse: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          newUserCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          newUserCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          newUserCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      break;

    case GET_USERS_CROWDFUNDING_COMMENTS_PUSHER:
      if (action.payload.status === 200) {
        return {
          ...state,
          userCrowdfundingCommentsListPusher: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userCrowdfundingCommentsListPusher: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userCrowdfundingCommentsListPusher: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userCrowdfundingCommentsListPusher: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userCrowdfundingCommentsListPusher: action.payload.response,
        };
      }
      break;

    case GET_USER_CROWDFUNDING_COMMENT_RESPONSE_PUSHER:
      if (action.payload.status === 200) {
        return {
          ...state,
          userCrowdfundingCommentsResponsePusher: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userCrowdfundingCommentsResponsePusher: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userCrowdfundingCommentsResponsePusher: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userCrowdfundingCommentsResponsePusher: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userCrowdfundingCommentsResponsePusher: action.payload.response,
        };
      }
      break;

    case GET_PRIVATE_CROWDFUNDING_LIST:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingPrivteList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingPrivteList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingPrivteList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingPrivteList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingPrivteList: action.payload.response };
      }
      break;

    case GET_PRIVATE_CROWDFUNDING_DETAIL:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingPrivteDetail: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingPrivteDetail: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingPrivteDetail: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingPrivteDetail: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingPrivteDetail: action.payload.response };
      }
      break;

    case GET_PRIVATE_CROWDFUNDING_CONTRIBUTES_LIST:
      if (action.payload.status === 200) {
        return { ...state, privateContributesList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, privateContributesList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, privateContributesList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, privateContributesList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, privateContributesList: action.payload.response };
      }
      break;

    case FETCH_PRIVATE_CROWDFUNDING_COMMENTS:
      if (action.payload.status === 200) {
        return {
          ...state,
          privateCrowdfundingCommentsList: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          privateCrowdfundingCommentsList: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          privateCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          privateCrowdfundingCommentsList: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          privateCrowdfundingCommentsList: action.payload.response,
        };
      }
      break;

    case POST_PRIVATE_CROWDFUNDING_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, newPrivateCrowdfundingComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          newPrivateCrowdfundingComment: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          newPrivateCrowdfundingComment: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          newPrivateCrowdfundingComment: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          newPrivateCrowdfundingComment: action.payload.response,
        };
      }
      break;

    case DELETE_PRIVATE_CROWDFUNDING_COMMENT:
      if (action.payload.status === 200) {
        return {
          ...state,
          deletedPrivateCrowdfundingComment: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          deletedPrivateCrowdfundingComment: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          deletedPrivateCrowdfundingComment: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          deletedPrivateCrowdfundingComment: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          deletedPrivateCrowdfundingComment: action.payload.response,
        };
      }
      break;

    case GET_CROWDFUNDING_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return {
          ...state,
          privateCrowdfundingCommentResponsesList: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          privateCrowdfundingCommentResponsesList: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          privateCrowdfundingCommentResponsesList: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          privateCrowdfundingCommentResponsesList: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          privateCrowdfundingCommentResponsesList: action.payload.response,
        };
      }
      break;

    case POST_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return {
          ...state,
          newPrivateCrowdfundingCommentsResponse: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          newPrivateCrowdfundingCommentsResponse: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          newPrivateCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          newPrivateCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          newPrivateCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      break;

    case DELETE_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return {
          ...state,
          deletePrivateCrowdfundingCommentsResponse: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          deletePrivateCrowdfundingCommentsResponse: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          deletePrivateCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          deletePrivateCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          deletePrivateCrowdfundingCommentsResponse: action.payload.response,
        };
      }
      break;

    case POST_PRIVATE_CAMPAIGN_CONTRIBUTE:
      if (action.payload.status === 200) {
        return { ...state, privateCampaignContribute: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, privateCampaignContribute: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, privateCampaignContribute: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, privateCampaignContribute: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, privateCampaignContribute: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, privateCampaignContribute: action.payload.response };
      }
      break;

    case UPLOAD_USER_IMAGE_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, userImageCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userImageCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userImageCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userImageCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userImageCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, userImageCrowdfunding: action.payload.response };
      }
      break;

    case POST_USER_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, userAddCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userAddCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userAddCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userAddCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userAddCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, userAddCrowdfunding: action.payload.response };
      }
      break;

    case DELETE_USER_CROWDFUNDING_IMAGE:
      if (action.payload.status === 200) {
        return { ...state, userDeleteImageCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          userDeleteImageCrowdfunding: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          userDeleteImageCrowdfunding: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          userDeleteImageCrowdfunding: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          userDeleteImageCrowdfunding: action.payload.response,
        };
      }
      if (action.payload.response.status === 500) {
        return {
          ...state,
          userDeleteImageCrowdfunding: action.payload.response,
        };
      }
      break;

    case GET_USER_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, userCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, userCrowdfunding: action.payload.response };
      }
      break;

    case POST_UPDATE_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, updateUserCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateUserCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateUserCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateUserCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updateUserCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, updateUserCrowdfunding: action.payload.response };
      }
      break;

    case GET_USER_MY_CROWDFUNDING:
      if (action.payload.status === 200) {
        return { ...state, userMyCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userMyCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userMyCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userMyCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userMyCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, userMyCrowdfunding: action.payload.response };
      }
      break;

    case GET_MY_PRIVATE_CROWDFUNDING_LIST:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingMyPrivteList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingMyPrivteList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingMyPrivteList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingMyPrivteList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingMyPrivteList: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, crowdfundingMyPrivteList: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
