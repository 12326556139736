import { useState, useEffect, useContext } from 'react';
import isEmpty from '@esolidar/toolkit/build/utils/isEmpty';
import { useSelector } from 'react-redux';
import isDefined from '@esolidar/toolkit/build/utils/isDefined';
import PropsContext from '../../contexts/PropsContext';
import useUser from './useUser';

const useIsLoggedIn = () => {
  const propsContext = useContext(PropsContext);
  const user = useUser();
  const loginModal = useSelector(state => state.auth.loginModal);

  const { token } = propsContext;

  const [isLoggedIn, setIsLoggedIn] = useState(!!token && !isEmpty(user));

  useEffect(() => {
    const user =
      typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user') || 'null') : null;
    setIsLoggedIn(isDefined(user) ? !!Object.keys(user).length : false);
  }, [loginModal]);

  return isLoggedIn;
};

export default useIsLoggedIn;
