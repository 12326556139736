import axios from 'axios';
import {
  uploadFormData,
  uploadFeedGallery,
  uploadImagesReview,
  uploadFilesReview,
} from '../../../shared/utils/formData';

import ROOT_URL from '../../../shared/constants/apiUrl';

export const POST_INSTITUTION_IMAGE_REQUESTS = 'POST_INSTITUTION_IMAGE_REQUESTS';
export const POST_INSTITUTION_REQUESTS = 'POST_INSTITUTION_REQUESTS';
export const UPDATE_INSTITUTION_REQUESTS = 'UPDATE_INSTITUTION_REQUESTS';
export const GET_INSTITUTION_REQUEST = 'GET_INSTITUTION_REQUEST';
export const POST_CLOSE_REQUEST = 'POST_CLOSE_REQUEST';
export const POST_DELETE_INSTITUTION_IMAGE_REQUESTS = 'POST_DELETE_INSTITUTION_IMAGE_REQUESTS';
export const GET_INSTITUTION_LIST_REQUEST = 'GET_INSTITUTION_LIST_REQUEST';
export const GET_COMPANIES_JOINED = 'GET_COMPANIES_JOINED';
export const POST_UNDO_COMPANIES_JOINED = 'POST_UNDO_COMPANIES_JOINED';
export const POST_DECLINE_COMPANIES_JOINED = 'POST_DECLINE_COMPANIES_JOINED';
export const POST_ACCEPT_COMPANIES_JOINED = 'POST_ACCEPT_COMPANIES_JOINED';
export const GET_REQUEST_COMMENTS = 'GET_REQUEST_COMMENTS';
export const POST_REQUEST_COMMENT_RESPONSE = 'POST_REQUEST_COMMENT_RESPONSE';
export const POST_REQUEST_COMMENT = 'POST_REQUEST_COMMENT';
export const GET_REQUEST_COMMENT_RESPONSES = 'GET_REQUEST_COMMENT_RESPONSES';
export const GET_REQUEST_COMPANIES_LIST = 'GET_REQUEST_COMPANIES_LIST';
export const GET_INSTITUTION_REVIEW = 'GET_INSTITUTION_REVIEW';
export const POST_INSTITUTION_REVIEW = 'POST_INSTITUTION_REVIEW';
export const UPLOAD_IMAGES_INSTITUTION_REVIEW = 'UPLOAD_IMAGES_INSTITUTION_REVIEW';
export const DELETE_IMAGES_INSTITUTION_REVIEW = 'DELETE_IMAGES_INSTITUTION_REVIEW';
export const UPLOAD_FILES_INSTITUTION_REVIEW = 'UPLOAD_FILES_INSTITUTION_REVIEW';
export const DELETE_FILES_INSTITUTION_REVIEW = 'DELETE_FILES_INSTITUTION_REVIEW';
export const GET_ALL_ALLOWED_USER_COMPANY_REQUESTS = 'GET_ALL_ALLOWED_USER_COMPANY_REQUESTS';
export const GET_EMPLOYEE_REQUESTS = 'GET_EMPLOYEE_REQUESTS';
export const POST_JOIN_REQUESTS = 'POST_JOIN_REQUESTS';
export const GET_REQUEST_REPORT_COMPANY_NPO = 'GET_REQUEST_REPORT_COMPANY_NPO';
export const GET_EMPLOYEE_REQUESTS_COMMENTS = 'GET_EMPLOYEE_REQUESTS_COMMENTS';
export const GET_REQUEST_USER_COMMENT_RESPONSES = 'GET_REQUEST_USER_COMMENT_RESPONSES';
export const POST_USER_REQUEST_COMMENT = 'POST_USER_REQUEST_COMMENT';
export const POST_USER_REQUEST_COMMENT_RESPONSE = 'POST_USER_REQUEST_COMMENT_RESPONSE';
export const POST_USER_REQUEST_REVIEW = 'POST_USER_REQUEST_REVIEW';
export const POST_CANCEL_REQUESTS = 'POST_CANCEL_REQUESTS';
export const GET_ALL_ALLOWED_USER_COMPANY_REQUESTS_HISTORY =
  'GET_ALL_ALLOWED_USER_COMPANY_REQUESTS_HISTORY';
export const GET_VOLUNTEERS_COMPANIES_JOINED = 'GET_VOLUNTEERS_COMPANIES_JOINED';
export const GET_VOLUNTTERS_BY_COMPANY = 'GET_VOLUNTTERS_BY_COMPANY';

export function requestsInstitutionUploadImages(institutionId, files) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/images`,
    uploadFeedGallery(files)
  );

  return {
    type: POST_INSTITUTION_IMAGE_REQUESTS,
    payload: request,
  };
}

export function deleteInstitutionImageRequest(institutionId, imageId) {
  const request = axios.delete(
    `${ROOT_URL}institutions/${institutionId}/requests/images/${imageId}`,
    ''
  );

  return {
    type: POST_DELETE_INSTITUTION_IMAGE_REQUESTS,
    payload: request,
  };
}

export function postInstitutionRequest(institutionId, data) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests`,
    uploadFormData(data)
  );

  return {
    type: POST_INSTITUTION_REQUESTS,
    payload: request,
  };
}

export function updateInstitutionRequest(institutionId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}`,
    uploadFormData(data)
  );

  return {
    type: UPDATE_INSTITUTION_REQUESTS,
    payload: request,
  };
}

export function getInstitutionRequest(institutionId, requestId) {
  const request = axios.get(`${ROOT_URL}institutions/${institutionId}/requests/${requestId}`);

  return {
    type: GET_INSTITUTION_REQUEST,
    payload: request,
  };
}

export function postCloseRequest(institutionId, requestId) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/close`,
    ''
  );

  return {
    type: POST_CLOSE_REQUEST,
    payload: request,
  };
}

export function getInstitutionListRequest(
  institutionId,
  status,
  perPage = 10,
  page = 1,
  type = '',
  sort = 'status,desc'
) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests?status=${status}&per_page=${perPage}&page=${page}${type}&sort=${sort}`
  );

  return {
    type: GET_INSTITUTION_LIST_REQUEST,
    payload: request,
  };
}

export function getCompaniesJoined(institutionId, requestId, page = 1, perPage = 5) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/companies-joined?page=${page}&per_page=${perPage}`
  );

  return {
    type: GET_COMPANIES_JOINED,
    payload: request,
  };
}

export function getVolunteersCompaniesJoined(
  institutionId,
  requestId,
  page = 1,
  perPage = 5,
  status = 'A,F'
) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/companies-joined?page=${page}&per_page=${perPage}&status=${status}`
  );

  return {
    type: GET_VOLUNTEERS_COMPANIES_JOINED,
    payload: request,
  };
}

export function postUndoRequestCompany(institutionId, requestCompanyId) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests-companies/${requestCompanyId}/undo`,
    ''
  );

  return {
    type: POST_UNDO_COMPANIES_JOINED,
    payload: request,
  };
}

export function postDeclineRequestCompany(institutionId, requestCompanyId) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests-companies/${requestCompanyId}/decline`,
    ''
  );

  return {
    type: POST_DECLINE_COMPANIES_JOINED,
    payload: request,
  };
}

export function postAcceptRequestCompany(institutionId, requestCompanyId) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests-companies/${requestCompanyId}/accept`,
    ''
  );

  return {
    type: POST_ACCEPT_COMPANIES_JOINED,
    payload: request,
  };
}

export function getRequestComments(institutionId, requestId, page = 1) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/comments?page=${page}`
  );

  return {
    type: GET_REQUEST_COMMENTS,
    payload: request,
  };
}

export function postRequestCommentResponse(institutionId, requestId, commentId, data) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/comments/${commentId}/responses`,
    data
  );

  return {
    type: POST_REQUEST_COMMENT_RESPONSE,
    payload: request,
  };
}

export function postUserRequestComment(companyId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/requests/${requestId}/comments`,
    data
  );

  return {
    type: POST_USER_REQUEST_COMMENT,
    payload: request,
  };
}

export function postRequestComment(institutionId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/comments`,
    data
  );

  return {
    type: POST_REQUEST_COMMENT,
    payload: request,
  };
}

export function getRequestCommentResponses(institutionId, requestId, commentId, page = 1) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/comments/${commentId}/responses?page=${page}`
  );

  return {
    type: GET_REQUEST_COMMENT_RESPONSES,
    payload: request,
  };
}

export function getRequestUserCommentResponses(companyId, requestId, commentId, page = 1) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/requests/${requestId}/comments/${commentId}/responses?page=${page}`
  );

  return {
    type: GET_REQUEST_USER_COMMENT_RESPONSES,
    payload: request,
  };
}

export function getReportCompaniesList(institutionId, requestId, page = 1) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/companies-reviews?page=${page}`
  );

  return {
    type: GET_REQUEST_COMPANIES_LIST,
    payload: request,
  };
}

export function getReportInstitutionReview(institutionId, requestId) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/review`
  );

  return {
    type: GET_INSTITUTION_REVIEW,
    payload: request,
  };
}

export function postInstitutionReview(institutionId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/review`,
    data
  );

  return {
    type: POST_INSTITUTION_REVIEW,
    payload: request,
  };
}

export function postImagesInstitutionReview(institutionId, images, requestId, requestCompanyId) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/report/images`,
    uploadImagesReview(images, requestId, requestCompanyId)
  );

  return {
    type: UPLOAD_IMAGES_INSTITUTION_REVIEW,
    payload: request,
  };
}

export function deleteImagesInstitutionReview(institutionId, imageId) {
  const request = axios.delete(
    `${ROOT_URL}institutions/${institutionId}/requests/report/images/${imageId}`,
    ''
  );

  return {
    type: DELETE_IMAGES_INSTITUTION_REVIEW,
    payload: request,
  };
}

export function deleteFileInstitutionReview(institutionId, imageId) {
  const request = axios.delete(
    `${ROOT_URL}institutions/${institutionId}/requests/report/files/${imageId}`,
    ''
  );

  return {
    type: DELETE_FILES_INSTITUTION_REVIEW,
    payload: request,
  };
}

export function postFilesInstitutionReview(institutionId, files, requestId, requestCompanyId) {
  const request = axios.post(
    `${ROOT_URL}institutions/${institutionId}/requests/report/files`,
    uploadFilesReview(files, requestId, requestCompanyId)
  );

  return {
    type: UPLOAD_FILES_INSTITUTION_REVIEW,
    payload: request,
  };
}

export function getAllAllowedUserCompanyRequests(companyId, employeeReviewed = 0, page = 1) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/requests/accepted/allowed-employees?employee_reviewed=${employeeReviewed}&page=${page}&per_page=6`
  );

  return {
    type: GET_ALL_ALLOWED_USER_COMPANY_REQUESTS,
    payload: request,
  };
}

export function getAllAllowedUserCompanyRequestsHistory(
  companyId,
  employeeReviewed = 1,
  page = 1,
  perPage = 6
) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/requests/accepted/allowed-employees?employee_reviewed=${employeeReviewed}&page=${page}&per_page=${perPage}&sort=id,desc`
  );

  return {
    type: GET_ALL_ALLOWED_USER_COMPANY_REQUESTS_HISTORY,
    payload: request,
  };
}

export function getEmployeeRequest(companyId, userId, requestId) {
  const request = axios.get(
    `${ROOT_URL}employees/${userId}/companies/${companyId}/requests/${requestId}`
  );

  return {
    type: GET_EMPLOYEE_REQUESTS,
    payload: request,
  };
}

export function postJoinUserRequest(userId, companyId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}employees/${userId}/companies/${companyId}/requests/${requestId}/join`,
    data
  );

  return {
    type: POST_JOIN_REQUESTS,
    payload: request,
  };
}

export function postCancelUserRequest(userId, companyId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}employees/${userId}/companies/${companyId}/requests/${requestId}/cancel`,
    data
  );

  return {
    type: POST_CANCEL_REQUESTS,
    payload: request,
  };
}

export function getReportCompanyAndNpoReview(userId, companyId, requestId) {
  const request = axios.get(
    `${ROOT_URL}employees/${userId}/companies/${companyId}/requests/${requestId}/reviews`
  );

  return {
    type: GET_REQUEST_REPORT_COMPANY_NPO,
    payload: request,
  };
}

export function getEmployeeRequestComments(companyId, requestId, page = 1, perPage = 3) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/requests/${requestId}/comments?page=${page}&per_page=${perPage}`
  );

  return {
    type: GET_EMPLOYEE_REQUESTS_COMMENTS,
    payload: request,
  };
}

export function postUserRequestCommentResponse(companyId, requestId, commentId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/requests/${requestId}/comments/${commentId}/responses`,
    data
  );

  return {
    type: POST_USER_REQUEST_COMMENT_RESPONSE,
    payload: request,
  };
}

export function postUserReview(userId, companyId, requestId, data) {
  const request = axios.post(
    `${ROOT_URL}employees/${userId}/companies/${companyId}/requests/${requestId}/review`,
    data
  );

  return {
    type: POST_USER_REQUEST_REVIEW,
    payload: request,
  };
}

export function getVolunteersByCompany(institutionId, requestId, companies) {
  const request = axios.get(
    `${ROOT_URL}institutions/${institutionId}/requests/${requestId}/companies/${companies}/volunteers`
  );

  return {
    type: GET_VOLUNTTERS_BY_COMPANY,
    payload: request,
  };
}
