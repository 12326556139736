import React, { FC, useContext } from 'react';
import Icon from '@esolidar/toolkit/build/elements/icon';
import PropsContext from '../../../contexts/PropsContext';

const SocialButtons: FC = (): JSX.Element => {
  const context = useContext(PropsContext);
  const company = context.initialState
    ? context.initialState.whitelabel.whiteLabelInfo.data.company
    : {};

  const icons = [
    {
      name: 'Public',
      href: company?.website_link,
    },
    {
      name: 'Facebook',
      href: company?.facebook_link,
    },
    {
      name: 'Twitter',
      href: company?.twitter_link,
    },
    {
      name: 'Instagram',
      href: company?.instagram_link,
    },
    {
      name: 'Linkedin',
      href: company?.linkedin_link,
    },
  ];

  return (
    <div className="social-buttons-list">
      <ul>
        {icons.map((icon, index) => {
          if (icon.href) {
            return (
              <li key={index}>
                <a href={icon.href} rel="noopener noreferrer" target="_blank">
                  <Icon name={icon.name} />
                </a>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default SocialButtons;
