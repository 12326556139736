export const RESET_REDUCER = 'RESET_REDUCER';
export const SET_LOCALE = 'SET_LOCALE';

export function resetReducer(reducerName) {
  return {
    type: RESET_REDUCER,
    payload: reducerName,
  };
}

export function setLocale(locale) {
  return {
    type: SET_LOCALE,
    payload: locale,
  };
}
