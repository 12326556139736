import {
  POST_INSTITUTION_IMAGE_REQUESTS,
  POST_INSTITUTION_REQUESTS,
  GET_INSTITUTION_REQUEST,
  POST_CLOSE_REQUEST,
  GET_COMPANIES_JOINED,
  GET_VOLUNTEERS_COMPANIES_JOINED,
  POST_UNDO_COMPANIES_JOINED,
  POST_DECLINE_COMPANIES_JOINED,
  POST_ACCEPT_COMPANIES_JOINED,
  GET_INSTITUTION_LIST_REQUEST,
  UPDATE_INSTITUTION_REQUESTS,
  GET_REQUEST_COMMENTS,
  POST_REQUEST_COMMENT,
  GET_REQUEST_COMMENT_RESPONSES,
  POST_REQUEST_COMMENT_RESPONSE,
  POST_USER_REQUEST_COMMENT,
  GET_REQUEST_COMPANIES_LIST,
  GET_INSTITUTION_REVIEW,
  POST_INSTITUTION_REVIEW,
  UPLOAD_IMAGES_INSTITUTION_REVIEW,
  DELETE_IMAGES_INSTITUTION_REVIEW,
  UPLOAD_FILES_INSTITUTION_REVIEW,
  DELETE_FILES_INSTITUTION_REVIEW,
  GET_ALL_ALLOWED_USER_COMPANY_REQUESTS,
  GET_EMPLOYEE_REQUESTS,
  POST_JOIN_REQUESTS,
  POST_CANCEL_REQUESTS,
  GET_REQUEST_REPORT_COMPANY_NPO,
  GET_EMPLOYEE_REQUESTS_COMMENTS,
  GET_REQUEST_USER_COMMENT_RESPONSES,
  POST_USER_REQUEST_COMMENT_RESPONSE,
  POST_USER_REQUEST_REVIEW,
  GET_ALL_ALLOWED_USER_COMPANY_REQUESTS_HISTORY,
  GET_VOLUNTTERS_BY_COMPANY,
} from '../actions/requests';

const INITIAL_STATE = {
  saved: false,
  requestCompany: null,
  institutionRequstImages: null,
  institutionRequests: null,
  updateInstitutionRequests: null,
  requestDetail: null,
  listRequest: null,
  companiesJoined: null,
  volunteeringCompaniesJoined: null,
  undoRequestCompany: null,
  declineRequestCompany: null,
  acceptRequestCompany: null,
  requestComments: null,
  newRequestComment: null,
  requestCommentResponses: null,
  requestUserCommentResponses: null,
  updateRequestCommentReplies: null,
  updateUserRequestComment: null,
  reportCompaniesList: null,
  reportInstitutionReview: null,
  reportInstitutionReviewCreate: null,
  reportInstitutionImages: null,
  reportInstitutionImagesDelete: null,
  reportInstitutionFiles: null,
  reportInstitutionFilesDelete: null,
  allowedUserRequestsList: null,
  allowedUserRequestsListHistory: null,
  joinUserRequest: null,
  cancelUserRequest: null,
  companyNpoReportRequest: {},
  employeeCommentsRequest: null,
  updateUserRequestCommentReplies: null,
  reportUserReview: null,
  volunteersByCompany: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_INSTITUTION_IMAGE_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, institutionRequstImages: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, institutionRequstImages: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, institutionRequstImages: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, institutionRequstImages: action.payload.response };
      }
      break;

    case POST_INSTITUTION_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, institutionRequests: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, institutionRequests: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, institutionRequests: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, institutionRequests: action.payload.response };
      }
      break;

    case UPDATE_INSTITUTION_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, updateInstitutionRequests: action.payload.data, updated: true };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateInstitutionRequests: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateInstitutionRequests: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateInstitutionRequests: action.payload.response };
      }
      break;

    case GET_INSTITUTION_REQUEST:
      if (action.payload.status === 200) {
        return { ...state, requestDetail: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, requestDetail: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, requestDetail: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, requestDetail: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, requestDetail: action.payload.response };
      }
      break;

    case POST_CLOSE_REQUEST:
      if (action.payload.status === 200) {
        return { ...state, requestDetail: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, requestDetail: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, requestDetail: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, requestDetail: action.payload.response };
      }
      break;

    case GET_INSTITUTION_LIST_REQUEST:
      if (action.payload.status === 200) {
        return { ...state, listRequest: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, listRequest: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, listRequest: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, listRequest: action.payload.response };
      }
      break;

    case GET_COMPANIES_JOINED:
      if (action.payload.status === 200) {
        return { ...state, companiesJoined: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, companiesJoined: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, companiesJoined: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, companiesJoined: action.payload.response };
      }
      break;

    case GET_VOLUNTEERS_COMPANIES_JOINED:
      if (action.payload.status === 200) {
        return { ...state, volunteeringCompaniesJoined: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, volunteeringCompaniesJoined: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, volunteeringCompaniesJoined: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, volunteeringCompaniesJoined: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, volunteeringCompaniesJoined: action.payload.response };
      }
      break;

    case POST_UNDO_COMPANIES_JOINED:
      if (action.payload.status === 200) {
        return { ...state, undoRequestCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, undoRequestCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, undoRequestCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, undoRequestCompany: action.payload.response };
      }
      break;

    case POST_DECLINE_COMPANIES_JOINED:
      if (action.payload.status === 200) {
        return { ...state, declineRequestCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, declineRequestCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, declineRequestCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, declineRequestCompany: action.payload.response };
      }
      break;

    case POST_ACCEPT_COMPANIES_JOINED:
      if (action.payload.status === 200) {
        return { ...state, acceptRequestCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, acceptRequestCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, acceptRequestCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, acceptRequestCompany: action.payload.response };
      }
      break;

    case GET_REQUEST_COMMENTS:
      if (action.payload.status === 200) {
        return { ...state, requestComments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, requestComments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, requestComments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, requestComments: action.payload.response };
      }
      break;

    case POST_REQUEST_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, newRequestComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newRequestComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newRequestComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newRequestComment: action.payload.response };
      }
      break;

    case GET_REQUEST_COMMENT_RESPONSES:
      if (action.payload.status === 200) {
        return { ...state, requestCommentResponses: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, requestCommentResponses: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, requestCommentResponses: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, requestCommentResponses: action.payload.response };
      }
      break;

    case GET_REQUEST_USER_COMMENT_RESPONSES:
      if (action.payload.status === 200) {
        return { ...state, requestUserCommentResponses: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, requestUserCommentResponses: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, requestUserCommentResponses: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, requestUserCommentResponses: action.payload.response };
      }
      break;

    case POST_REQUEST_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return { ...state, updateRequestCommentReplies: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateRequestCommentReplies: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateRequestCommentReplies: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateRequestCommentReplies: action.payload.response };
      }
      break;

    case POST_USER_REQUEST_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, updateUserRequestComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateUserRequestComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateUserRequestComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateUserRequestComment: action.payload.response };
      }
      break;

    case GET_REQUEST_COMPANIES_LIST:
      if (action.payload.status === 200) {
        return { ...state, reportCompaniesList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportCompaniesList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportCompaniesList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportCompaniesList: action.payload.response };
      }
      break;

    case GET_INSTITUTION_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportInstitutionReview: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportInstitutionReview: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportInstitutionReview: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportInstitutionReview: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportInstitutionReview: action.payload.response };
      }
      break;

    case POST_INSTITUTION_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportInstitutionReviewCreate: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportInstitutionReview: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportInstitutionReviewCreate: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportInstitutionReviewCreate: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportInstitutionReviewCreate: action.payload.response };
      }
      break;

    case UPLOAD_IMAGES_INSTITUTION_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportInstitutionImages: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportInstitutionImages: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportInstitutionImages: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportInstitutionImages: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportInstitutionImages: action.payload.response };
      }
      break;

    case DELETE_IMAGES_INSTITUTION_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportInstitutionImagesDelete: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportInstitutionImagesDelete: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportInstitutionImagesDelete: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportInstitutionImagesDelete: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportInstitutionImagesDelete: action.payload.response };
      }
      break;

    case UPLOAD_FILES_INSTITUTION_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportInstitutionFiles: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportInstitutionFiles: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportInstitutionFiles: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportInstitutionFiles: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportInstitutionFiles: action.payload.response };
      }
      break;

    case DELETE_FILES_INSTITUTION_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportInstitutionFilesDelete: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportInstitutionFilesDelete: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportInstitutionFilesDelete: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportInstitutionFilesDelete: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportInstitutionFilesDelete: action.payload.response };
      }
      break;

    case GET_ALL_ALLOWED_USER_COMPANY_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, allowedUserRequestsList: action.payload.data.data.joinedRequests };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, allowedUserRequestsList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, allowedUserRequestsList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, allowedUserRequestsList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, allowedUserRequestsList: action.payload.response };
      }
      break;

    case GET_ALL_ALLOWED_USER_COMPANY_REQUESTS_HISTORY:
      if (action.payload.status === 200) {
        return {
          ...state,
          allowedUserRequestsListHistory: action.payload.data.data.joinedRequests,
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, allowedUserRequestsListHistory: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, allowedUserRequestsListHistory: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, allowedUserRequestsListHistory: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, allowedUserRequestsListHistory: action.payload.response };
      }
      break;

    case GET_EMPLOYEE_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, employeeRequest: action.payload.data.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, employeeRequest: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, employeeRequest: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, employeeRequest: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, employeeRequest: action.payload.response };
      }
      break;

    case POST_JOIN_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, joinUserRequest: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, joinUserRequest: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, joinUserRequest: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, joinUserRequest: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, joinUserRequest: action.payload.response };
      }
      break;

    case POST_CANCEL_REQUESTS:
      if (action.payload.status === 200) {
        return { ...state, cancelUserRequest: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, cancelUserRequest: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, cancelUserRequest: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, cancelUserRequest: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, cancelUserRequest: action.payload.response };
      }
      break;

    case GET_REQUEST_REPORT_COMPANY_NPO:
      if (action.payload.status === 200) {
        return { ...state, companyNpoReportRequest: action.payload.data.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, companyNpoReportRequest: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, companyNpoReportRequest: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, companyNpoReportRequest: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, companyNpoReportRequest: action.payload.response };
      }
      break;

    case GET_EMPLOYEE_REQUESTS_COMMENTS:
      if (action.payload.status === 200) {
        return { ...state, employeeCommentsRequest: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, employeeCommentsRequest: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, employeeCommentsRequest: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, employeeCommentsRequest: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, employeeCommentsRequest: action.payload.response };
      }
      break;

    case POST_USER_REQUEST_COMMENT_RESPONSE:
      if (action.payload.status === 200) {
        return { ...state, updateUserRequestCommentReplies: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateUserRequestCommentReplies: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateUserRequestCommentReplies: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateUserRequestCommentReplies: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updateUserRequestCommentReplies: action.payload.response };
      }
      break;

    case POST_USER_REQUEST_REVIEW:
      if (action.payload.status === 200) {
        return { ...state, reportUserReview: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, reportUserReview: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, reportUserReview: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, reportUserReview: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, reportUserReview: action.payload.response };
      }
      break;

    case GET_VOLUNTTERS_BY_COMPANY:
      if (action.payload.status === 200) {
        return { ...state, volunteersByCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, volunteersByCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, volunteersByCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, volunteersByCompany: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, volunteersByCompany: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
