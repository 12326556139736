import axios from 'axios';

const ROOT_URL = `${process.env.NEXT_PUBLIC_app_url}${process.env.NEXT_PUBLIC_app_version}/`;

export const GET_SKILLS = 'GET_SKILLS';

export function getSkills() {
  const request = axios.get(`${ROOT_URL}skills`);

  return {
    type: GET_SKILLS,
    payload: request,
  };
}
