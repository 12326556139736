import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import getUrlParam from '@esolidar/toolkit/build/utils/getUrlParam';
import Button from '@esolidar/toolkit/build/elements/button';
import Icon from '@esolidar/toolkit/build/components/icon';
import UserMenu from './UserMenu';
import { postVerifyUserEmail, toggleLoginModal } from '../../../store/actions/authUser';
import setAuthorizationToken from '../../../shared/utils/setAuthorizationToken';
import setLocalStorageUser from '../../../shared/utils/setLocalStorageUser';
import { showAlert } from '../../../store/actions/alert';
import setUserCookie from '../../../shared/utils/setUserCookie';

const nextUrl = getUrlParam('next_url') || null;
const emailValidation = getUrlParam('email') || null;
const queryData = getUrlParam('data') || null;

class UserButtons extends Component {
  state = {
    config: null,
    lang: '',
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const email = urlParams.get('email');
    if (code && email) {
      const data = {
        email: email.replace(' ', '+'),
        code,
      };

      this.props.postVerifyUserEmail(data).then(response => {
        if (response.code === 200) {
          const params = new URLSearchParams(window.location.search);
          const mail = params.get('email');
          const newurl = `auth/login?email=${mail}`;
          window.location.href = newurl;
        }
      });
    }

    this.updateState({
      config: JSON.parse(localStorage.getItem('config')),
      lang: localStorage.getItem('lang'),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.fbAuth !== prevProps.fbAuth) {
      if (this.props.fbAuth.code === 200) {
        localStorage.setItem('user', JSON.stringify(this.props.fbAuth.data.user));
        localStorage.setItem('token', this.props.fbAuth.data.token);
        localStorage.setItem('refreshToken', this.props.fbAuth.data.refreshToken);
      }
    }
    if (this.props.user !== prevProps.user) {
      if (this.props.user.code === 200) {
        localStorage.setItem('user', JSON.stringify(this.props.user.data.user));
        localStorage.setItem('token', this.props.user.data.token);
        localStorage.setItem('refreshToken', this.props.user.data.refreshToken);
      }
    }
    if (this.props.joinUserMember !== prevProps.joinUserMember) {
      if (this.props.joinUserMember.code === 200) {
        localStorage.setItem('user', JSON.stringify(this.props.joinUserMember.data));
        localStorage.setItem('token', this.props.joinUserMember.data.token);
        localStorage.setItem('refreshToken', this.props.joinUserMember.data.refreshToken);
      }
    }
    if (this.props.joinUserEmployee !== prevProps.joinUserEmployee) {
      if (this.props.joinUserEmployee.code === 200) {
        localStorage.setItem('user', JSON.stringify(this.props.joinUserEmployee.data));
        localStorage.setItem('token', this.props.joinUserEmployee.data.token);
        localStorage.setItem('refreshToken', this.props.joinUserEmployee.data.refreshToken);
      }
    }
    if (this.props.setNewPasswordResponse !== prevProps.setNewPasswordResponse) {
      if (this.props.setNewPasswordResponse.code === 200) {
        localStorage.setItem('user', JSON.stringify(this.props.setNewPasswordResponse.data.user));
        localStorage.setItem('token', this.props.setNewPasswordResponse.data.token);
        localStorage.setItem('refreshToken', this.props.setNewPasswordResponse.data.refreshToken);
      }
    }
    if (prevProps.verifyEmail !== this.props.verifyEmail) {
      if (this.props.verifyEmail.code === 200) {
        const { user, token, refreshToken } = this.props.verifyEmail.data;
        cookie.save('eSolidar_has_login', '1', {
          maxAge: 6 * 30 * 24 * 3600,
          path: '/',
        });

        setUserCookie(user);

        setAuthorizationToken(token, refreshToken);
        setLocalStorageUser(user, token, refreshToken);
        localStorage.setItem('rememberMe', '0');

        this.props.showAlert({
          alertBox: {
            alertVisible: true,
            alertClass: 'success',
            messageId: 'user.email.validated.success',
          },
        });

        if (nextUrl) window.location.href = decodeURIComponent(nextUrl);
        else {
          window.history.pushState({}, document.title, window.location.pathname);
          window.location.reload();
        }
      } else if (this.props.verifyEmail.status === 404) {
        this.props.showAlert({
          alertBox: {
            type: 'toast',
            variant: 'description',
            alertVisible: true,
            alertClass: 'danger',
            messageTitleId: 'user.resendEmailVerification.error',
            messageId: 'session.lost.message',
          },
        });
      } else {
        const { lang } = this.state;

        window.location.href = `${lang}/auth/expired-email-validation?email=${emailValidation.replace(
          ' ',
          '+'
        )}&data=${queryData || ''}`;
      }
    }
  }

  handleClickOnLogin = () => {
    this.props.toggleLoginModal({ isOpen: true, nextUrl: `/${this.state.lang}/feed` });
  };

  updateState = state => {
    this.setState(state);
  };

  render() {
    const { config, lang } = this.state;
    const { hideBtnSignIn, hideBtnSignUp, isLoggedIn } = this.props;
    const allowUsers = _.isObject(config) ? config.allow_member_registration : false;

    return (
      <div className="menu-user-buttons">
        {!isLoggedIn && (
          <div className="d-flex">
            {!hideBtnSignIn && (
              <Button
                extraClass={`success-full d-none d-lg-block ${!hideBtnSignUp ? 'btn-login' : ''}`}
                onClick={this.handleClickOnLogin}
                text={<FormattedMessage id="login" />}
              />
            )}
            {allowUsers && !hideBtnSignUp && (
              <Button
                type="button"
                extraClass="dark d-none d-lg-block"
                href={`/${lang}/auth/register`}
                text={<FormattedMessage id="signup" />}
              />
            )}
            <div className="icon-user-login d-lg-none">
              <a onClick={this.handleClickOnLogin} onKeyDown={this.handleClickOnLogin}>
                <Icon iconClass="icon-user" />
              </a>
            </div>
          </div>
        )}
        {isLoggedIn && (
          <div className="mw-60">
            <div className="user-buttons d-flex">
              <UserMenu />
            </div>
          </div>
        )}
      </div>
    );
  }
}

UserButtons.propTypes = {
  fbAuth: PropTypes.object,
  setNewPasswordResponse: PropTypes.object,
  user: PropTypes.object,
  postVerifyUserEmail: PropTypes.func,
  verifyEmail: PropTypes.object,
  showAlert: PropTypes.func,
  joinUserMember: PropTypes.object,
  joinUserEmployee: PropTypes.object,
  toggleLoginModal: PropTypes.func,
  hideBtnSignIn: PropTypes.bool,
  hideBtnSignUp: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    fbAuth: state.auth.fbAuth,
    user: state.auth.user,
    verifyEmail: state.auth.verifyEmail,
    setNewPasswordResponse: state.auth.setNewPasswordResponse,
    joinUserMember: state.members.joinUserMember,
    joinUserEmployee: state.members.joinUserEmployee,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAlert,
      postVerifyUserEmail,
      toggleLoginModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserButtons);
