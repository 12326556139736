import getQueryParams from '../../shared/utils/getQueryParams';
import {
  FETCH_FEED,
  FETCH_OTHER_COMPANIES_FEED,
  FETCH_POST_COMMENT,
  FETCH_POST_PUBLIC_COMMENT,
  FETCH_POST,
  FETCH_COMMENT_POST,
  DELETE_POST,
  DELETE_COMMENT,
  UPDATE_POST,
  FETCH_RESPONSE_COMMENT,
  FETCH_PUBLIC_RESPONSE_COMMENT,
  POST_RESPONSE_COMMENT,
  UPDATE_COMMENT,
  DELETE_RESPONSE,
  UPDATE_RESPONSE,
  FETCH_LOVE,
  FETCH_SCRAPTER,
  UPLOAD_GALLERY,
  DELETE_IMAGE_GALLERY,
  FETCH_POST_BY_ID,
  FETCH_NEW_POST_BY_ID,
  GET_PUBLIC_FEED,
  FETCH_PUBLIC_POST_COMMENT,
  FETCH_PUBLIC_REPLIES,
} from '../actions';

const INITIAL_STATE = {
  feed: {},
  otherCompaniesFeed: {},
  comments: {},
  newPost: {},
  replyComment: {},
  deletedPost: {},
  deletedComment: {},
  updatePost: {},
  getResponse: {},
  getNewResponse: {},
  updateComment: {},
  deleteResponse: {},
  updateResponse: {},
  love: {},
  scrapter: {},
  gallery: {},
  deletedImage: {},
  postById: {},
  publicComments: {},
  newPostById: {},
};

export default function reducerFeed(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FEED:
      if (action.payload.status === 200) {
        return { ...state, feed: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, feed: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, feed: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, feed: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, feed: action.payload.response };
      }
      break;

    case GET_PUBLIC_FEED:
      if (action.payload.status === 200) {
        const { data } = action.payload;
        data.public = true;
        return { ...state, feed: data };
      }
      /*
      if (action.payload.status === 200) {
        return { ...state, feed: action.payload.data };
      }
      */
      if (action.payload.message === 'Network Error') {
        return { ...state, feed: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, feed: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, feed: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, feed: action.payload.response };
      }
      break;

    case FETCH_OTHER_COMPANIES_FEED:
      if (action.payload.status === 200) {
        return {
          ...state,
          otherCompaniesFeed: action.payload.data,
          newPost: null,
        };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          otherCompaniesFeed: action.payload.message,
          newPost: null,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          otherCompaniesFeed: action.payload.response,
          newPost: null,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          otherCompaniesFeed: action.payload.response,
          newPost: null,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          otherCompaniesFeed: action.payload.response,
          newPost: null,
        };
      }
      break;

    case FETCH_POST_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, comments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, comments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, comments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, comments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, comments: action.payload.response };
      }
      break;

    case FETCH_PUBLIC_POST_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, comments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, comments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, comments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, comments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, comments: action.payload.response };
      }
      break;

    case FETCH_POST_PUBLIC_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, publicComments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, publicComments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, publicComments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, publicComments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, publicComments: action.payload.response };
      }
      break;

    case FETCH_POST:
      if (action.payload.status === 200) {
        return { ...state, newPost: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newPost: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newPost: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newPost: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, newPost: action.payload.response };
      }
      break;

    case FETCH_COMMENT_POST:
      if (action.payload.status === 200) {
        return { ...state, replyComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, replyComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, replyComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, replyComment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, replyComment: action.payload.response };
      }
      break;

    case DELETE_POST:
      if (action.payload.status === 200) {
        const postId = getQueryParams('post-id', action.payload.request.responseURL);
        const { data } = action.payload;
        data.post_id = +postId;
        return { ...state, deletedPost: data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deletedPost: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deletedPost: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deletedPost: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deletedPost: action.payload.response };
      }
      break;

    case DELETE_COMMENT:
      if (action.payload.status === 200) {
        const commentId = getQueryParams('comment-id', action.payload.request.responseURL);
        const { data } = action.payload;
        data.comment_id = +commentId;
        return { ...state, deletedComment: data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deletedComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deletedComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deletedComment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deletedComment: action.payload.response };
      }
      break;

    case DELETE_RESPONSE:
      if (action.payload.status === 200) {
        const responseId = getQueryParams('response-id', action.payload.request.responseURL);
        const { data } = action.payload;
        data.response_id = +responseId;
        return { ...state, deleteResponse: data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deleteResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deleteResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deleteResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deleteResponse: action.payload.response };
      }
      break;

    case UPDATE_POST:
      if (action.payload.status === 200) {
        return { ...state, updatePost: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updatePost: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updatePost: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updatePost: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updatePost: action.payload.response };
      }
      break;

    case FETCH_RESPONSE_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, getResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, getResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, getResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, getResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, getResponse: action.payload.response };
      }
      break;

    case FETCH_PUBLIC_REPLIES:
      if (action.payload.status === 200) {
        return { ...state, getResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, getResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, getResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, getResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, getResponse: action.payload.response };
      }
      break;

    case FETCH_PUBLIC_RESPONSE_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, getPublicResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, getPublicResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, getPublicResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, getPublicResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, getPublicResponse: action.payload.response };
      }
      break;

    case POST_RESPONSE_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, getNewResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, getNewResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, getNewResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, getNewResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, getNewResponse: action.payload.response };
      }
      break;

    case UPDATE_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, updateComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateComment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updateComment: action.payload.response };
      }
      break;

    case UPDATE_RESPONSE:
      if (action.payload.status === 200) {
        return { ...state, updateResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updateResponse: action.payload.response };
      }
      break;

    case FETCH_LOVE:
      if (action.payload.status === 200) {
        const postId = getQueryParams('post-id', action.payload.request.responseURL);
        const { data } = action.payload;
        data.post_id = +postId;
        return { ...state, love: data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, love: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, love: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, love: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, love: action.payload.response };
      }
      break;

    case FETCH_SCRAPTER:
      if (action.payload.status === 200) {
        return { ...state, scrapter: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, scrapter: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, scrapter: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, scrapter: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, scrapter: action.payload.response };
      }
      break;

    case UPLOAD_GALLERY:
      if (action.payload.status === 200) {
        return { ...state, gallery: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          gallery: action.payload.response,
          position: getQueryParams('position', action.payload.request.responseURL),
        };
      }
      if (action.payload.response.status === 400) {
        const { response } = action.payload;
        response.position = getQueryParams('position', action.payload.request.responseURL);
        return { ...state, gallery: response };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          gallery: action.payload.response,
          position: getQueryParams('position', action.payload.request.responseURL),
        };
      }
      if (action.payload.response.status === 403) {
        return {
          ...state,
          gallery: action.payload.response,
          position: getQueryParams('position', action.payload.request.responseURL),
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          gallery: action.payload.response,
          position: getQueryParams('position', action.payload.request.responseURL),
        };
      }
      break;

    case DELETE_IMAGE_GALLERY:
      if (action.payload.status === 200) {
        return { ...state, deletedImage: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deletedImage: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deletedImage: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deletedImage: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deletedImage: action.payload.response };
      }
      break;

    case FETCH_POST_BY_ID:
      if (action.payload.status === 200) {
        return { ...state, postById: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, postById: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, postById: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, postById: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, postById: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, postById: action.payload.response };
      }
      break;

    case FETCH_NEW_POST_BY_ID:
      if (action.payload.status === 200) {
        return { ...state, newPostById: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newPostById: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newPostById: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newPostById: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, newPostById: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, newPostById: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
