import cookie from 'react-cookies';
import { DEFAULT_LOCALE } from '../../shared/constants/locales';

const INITIAL_STATE = {
  serverLocale: DEFAULT_LOCALE,
  locale: cookie.load('NEXT_LOCALE'),
};

export default function reducerMisc(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOCALE':
      return { ...state, serverLocale: action.payload };

    case 'SET_LOCALE':
      return { ...state, locale: action.payload };

    default:
  }
  return state;
}
