const setReducerFromLocalStorage = (key: string, defaultValue: string = '{}') => {
  let value = JSON.parse(defaultValue);

  if (typeof window !== 'undefined') {
    value = JSON.parse(localStorage.getItem(key) || defaultValue);
  }
  return value;
};

export default setReducerFromLocalStorage;
