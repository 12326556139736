import React, { FC } from 'react';

interface Props {
  style?: style;
  dataTestId?: string;
  className?: string;
}
interface style {
  color?: string;
  backgroundColor?: string;
  padding?: string;
  margin?: string;
}

const PoweredBy: FC<Props> = ({
  className,
  style,
  dataTestId = 'poweredBy',
}: Props): JSX.Element => (
  <a
    href={process.env.NEXT_PUBLIC_esolidar_main_url}
    rel="noopener noreferrer"
    target="_blank"
    className={className}
    style={style}
    data-testid={dataTestId}
  >
    <img
      src={`${process.env.NEXT_PUBLIC_cdn_static_url}/whitelabel-frontend/assets/footer_powered_by_esolidar.svg`}
      alt="powered by esolidar"
    />
  </a>
);

export default PoweredBy;
