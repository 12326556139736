import { useContext } from 'react';
import PropsContext from '../../contexts/PropsContext';

const useSubscriptions = () => {
  const context = useContext(PropsContext);
  const subscriptions =
    context.initialState.whitelabel.whiteLabelInfo.data.company.subscription.features;

  return subscriptions;
};

export default useSubscriptions;
