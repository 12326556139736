import { HYDRATE } from 'next-redux-wrapper';
import {
  POST_BID,
  POST_SUBSCRIBE,
  POST_USER_COMMENT,
  POST_COMPANY_COMMENT,
  GET_AUCTION_DETAIL,
  GET_SUBSCRIBE,
  FETCH_AUCTIONS,
  FETCH_AUCTIONS_LIST,
  FETCH_AUCTIONS_COMMENTS_LIST,
  FETCH_AUCTIONS_BID_LIST,
  FETCH_STATISTICS,
  FETCH_ADD,
  FETCH_ADDIMAGES,
  FETCH_DELETEIMAGES,
  FETCH_PUBLIC_AUCTIONS_BY_COMPANY,
  FETCH_AUCTIONS_USER_COMMENTS_RESPONSE,
  DELETE_AUCTION_COMMENT,
} from '../actions';

const INITIAL_STATE = {
  auctions: {},
  auctionsList: {},
  auctionSubscribe: {},
  auctionSubscribeList: {},
  auctionBidList: {},
  auctionDetail: {},
  auctionComments: {},
  newBid: {},
  auctionUserComment: {},
  auctionCompanyComment: {},
  auctionUserCommentsResponse: {},
  deleteComment: {},
  statistics: null,
  addAuction: null,
  addImages: null,
  deleteImages: null,
};

export default function reducerAuctions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.data };

    case FETCH_AUCTIONS:
      if (action.payload.status === 200) {
        return { ...state, auctions: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctions: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctions: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctions: action.payload.response };
      }
      break;

    case FETCH_PUBLIC_AUCTIONS_BY_COMPANY:
      if (action.payload.status === 200) {
        return { ...state, publicAuctions: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, publicAuctions: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, publicAuctions: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, publicAuctions: action.payload.response };
      }
      break;

    case FETCH_STATISTICS:
      if (action.payload.status === 200) {
        return { ...state, statistics: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, statistics: action.payload.response };
      }
      break;

    case FETCH_ADD:
      if (action.payload.status === 200) {
        return { ...state, addAuction: action.payload.data, success: true };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, addAuction: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, addAuction: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, addAuction: action.payload.response };
      }
      break;

    case FETCH_ADDIMAGES:
      if (action.payload.status === 200) {
        return { ...state, addImages: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, addImages: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, addImages: action.payload.response.data };
      }
      if (action.payload.response.status === 401) {
        return { ...state, addImages: action.payload.response };
      }
      break;

    case FETCH_DELETEIMAGES:
      if (action.payload.status === 200) {
        return { ...state, deleteImages: action.payload.data, addImages: null };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deleteImages: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deleteImages: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deleteImages: action.payload.response };
      }
      break;

    case GET_AUCTION_DETAIL:
      if (action.payload.status === 200) {
        return { ...state, auctionDetail: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionDetail: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionDetail: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionDetail: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, auctionDetail: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, auctionDetail: action.payload.response };
      }
      break;

    case FETCH_AUCTIONS_LIST:
      if (action.payload.status === 200) {
        return { ...state, auctionsList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionsList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionsList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionsList: action.payload.response };
      }
      break;

    case FETCH_AUCTIONS_BID_LIST:
      if (action.payload.status === 200) {
        return { ...state, auctionBidList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionBidList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionBidList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionBidList: action.payload.response };
      }
      break;

    case POST_SUBSCRIBE:
      if (action.payload.status === 200) {
        return { ...state, auctionSubscribe: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionSubscribe: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionSubscribe: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionSubscribe: action.payload.response };
      }
      break;

    case GET_SUBSCRIBE:
      if (action.payload.status === 200) {
        return { ...state, auctionSubscribeList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionSubscribeList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionSubscribeList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionSubscribeList: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, auctionSubscribeList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, auctionSubscribeList: action.payload.response };
      }
      break;

    case FETCH_AUCTIONS_COMMENTS_LIST:
      if (action.payload.status === 200) {
        return { ...state, auctionComments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionComments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionComments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionComments: action.payload.response };
      }
      break;

    case POST_BID:
      if (action.payload.status === 200) {
        return { ...state, newBid: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newBid: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newBid: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newBid: action.payload.response };
      }
      break;

    case POST_USER_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, auctionUserComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionUserComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionUserComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionUserComment: action.payload.response };
      }
      break;

    case POST_COMPANY_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, auctionCompanyComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, auctionCompanyComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, auctionCompanyComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, auctionCompanyComment: action.payload.response };
      }
      break;

    case FETCH_AUCTIONS_USER_COMMENTS_RESPONSE:
      if (action.payload.status === 200) {
        return { ...state, auctionUserCommentsResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          auctionUserCommentsResponse: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          auctionUserCommentsResponse: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          auctionUserCommentsResponse: action.payload.response,
        };
      }
      break;

    case DELETE_AUCTION_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, deleteComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deleteComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deleteComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deleteComment: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
