import axios from 'axios';
import queryString from 'query-string';
import { uploadImage, uploadProject } from '../../../shared/utils/formData';
import {
  GET_PROJECT_CATEGORIES,
  GET_PROJECT_CONFIG,
  GET_PROJECT_CONFIG_BY_ID,
  UPLOAD_PROJECT_IMAGE,
  DELETE_PROJECT_IMAGE,
  POST_CREATE_PROJECT,
  GET_USER_PROJECTS_LIST,
  GET_PROJECTS_DETAIL,
  POST_UPDATE_PROJECT,
  GET_PUBLIC_PROJECTS_LIST,
} from '..';

import ROOT_URL from '../../../shared/constants/apiUrl';

export function getProjectCategories(whitelabelConfigId, projectConfigId) {
  const params = queryString.stringify({
    status: 1,
    project_config_id: projectConfigId || undefined,
  });

  const request = axios.get(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects/categories?${params}`
  );

  return {
    type: GET_PROJECT_CATEGORIES,
    payload: request,
  };
}

export function getProjectConfigById(whitelabelConfigId, id) {
  const request = axios.get(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects-config/${id}/public`
  );

  return {
    type: GET_PROJECT_CONFIG_BY_ID,
    payload: request,
  };
}

export async function getServerProjectConfigById(whitelabelConfigId, id) {
  const request = await axios.get(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects-config/${id}/public`
  );

  return {
    type: GET_PROJECT_CONFIG_BY_ID,
    payload: request,
  };
}

export async function getProjectConfig(whitelabelConfigId) {
  const request = await axios.get(`${ROOT_URL}whitelabel/${whitelabelConfigId}/projects/config`);

  return {
    type: GET_PROJECT_CONFIG,
    payload: request,
  };
}

export function uploadProjectImage(whitelabelConfigId, data) {
  const request = axios.post(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects/images?position=${data.position}`,
    uploadImage(data)
  );

  return {
    type: UPLOAD_PROJECT_IMAGE,
    payload: request,
  };
}

export function deleteProjectImage(whitelabelConfigId, imageId) {
  const request = axios.delete(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects/images/${imageId}`
  );

  return {
    type: DELETE_PROJECT_IMAGE,
    payload: request,
  };
}

export function postCreateProject(whitelabelConfigId, data) {
  const request = axios.post(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects`,
    uploadProject(data)
  );

  return {
    type: POST_CREATE_PROJECT,
    payload: request,
  };
}

export function postUpdateProject(whitelabelConfigId, postId, data) {
  const request = axios.post(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects/${postId}`,
    uploadProject(data)
  );

  return {
    type: POST_UPDATE_PROJECT,
    payload: request,
  };
}

export function getUserProjectsList(
  whitelabelId,
  page,
  status,
  categotyId,
  title,
  projectOds = []
) {
  const params = queryString.stringify({
    whitelabel_id: whitelabelId,
    page,
    status: status || undefined,
    category_id: categotyId || undefined,
    title: title ? `%${title}%` : undefined,
    'projectOds:ods_id': projectOds.length > 0 ? projectOds.join() : undefined,
    sort: 'id,desc',
  });

  const request = axios.get(`${ROOT_URL}user/projects?${params}`);

  return {
    type: GET_USER_PROJECTS_LIST,
    payload: request,
  };
}

export async function getUserProjectsDetail(projectId) {
  const request = await axios.get(`${ROOT_URL}user/projects/${projectId}`);

  return {
    type: GET_PROJECTS_DETAIL,
    payload: request,
  };
}

export async function getPublicProjectsDetail(whitelabelConfigId, projectId) {
  const request = await axios.get(
    `${ROOT_URL}whitelabel/${whitelabelConfigId}/projects/${projectId}/public`
  );

  return {
    type: GET_PROJECTS_DETAIL,
    payload: request,
  };
}

export async function getAsyncPublicProjectsList(whitelabelId, page, perPage) {
  const params = queryString.stringify({
    page,
    per_page: perPage || undefined,
  });

  const request = await axios.get(
    `${ROOT_URL}whitelabel/${whitelabelId}/projects/public?${params}`
  );

  return {
    type: GET_PUBLIC_PROJECTS_LIST,
    payload: request,
  };
}

export function getPublicProjectsList(whitelabelId, page, categotyId, projectOds) {
  const params = queryString.stringify({
    page,
    category_id: categotyId,
    'projectOds:ods_id': projectOds.length > 0 ? projectOds.join() : undefined,
    per_page: 9,
    sort: 'id,desc',
  });

  const request = axios.get(`${ROOT_URL}whitelabel/${whitelabelId}/projects/public?${params}`);

  return {
    type: GET_PUBLIC_PROJECTS_LIST,
    payload: request,
  };
}
