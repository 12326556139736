import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';
import PropsContext from '../contexts/PropsContext';

const Div = styled.div`
  .main-app {
    font-family: ${props => props.fontFamily};
  }
  .header {
    #nav-icon3 span {
      background: ${props => props.primaryColor};
    }
    .header-menu {
      a.nav-link {
        &:hover,
        &:active,
        &:focus,
        &.active {
          color: ${props => props.primaryColor};
          border-bottom: 2px solid ${props => props.primaryColor};
        }
      }
      .dropdown {
        & > a.nav-link.dropdown-toggle[aria-expanded='true'] {
          color: ${props => props.primaryColor};
        }
        &.active {
          & > a.nav-link.dropdown-toggle {
            color: ${props => props.primaryColor};
            border-bottom: 2px solid ${props => props.primaryColor};
          }
        }
      }
    }
    .menu-user-buttons {
      .icon-user-login {
        i {
          color: ${props => props.primaryColor};
        }
      }
      .user-menu.dropdown-toggle {
        &:hover,
        &:active,
        &:focus {
          color: ${props => props.primaryColor};
        }
      }
      .show > .user-menu.dropdown-toggle {
        color: ${props => props.primaryColor};
      }
      .dropdown-menu a {
        &:hover,
        &:active,
        &:focus {
          color: ${props => props.primaryColor};
        }
      }
    }
    .user-top-menu-company {
      .dropdown-menu a {
        &:hover,
        &:active,
        &:focus,
        &:nth-child(2) {
          color: ${props => props.primaryColor};
        }
      }
    }
  }
  .sidebar-menu {
    li {
      &.active {
        a {
          color: ${props => props.primaryColor};
          border-left: 2px solid ${props => props.primaryColor};

          @media (max-width: 992px) {
            color: ${props => props.primaryColor};
            border-bottom: 2px solid ${props => props.primaryColor};
          }
        }
      }
      a {
        &:hover,
        &:active,
        &:focus {
          color: ${props => props.primaryColor};
          border-left: 2px solid ${props => props.primaryColor};
        }
      }
    }
  }
  .create-post-write {
    .company-name {
      color: ${props => props.primaryColor};
    }
  }
  .btn-esolidar.btn-success {
    border-color: ${props => props.primaryColor};
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: ${props => props.primaryColor};
    }
  }
  .btn-esolidar.btn-primary-full {
    border-color: ${props => props.primaryColor};
    background-color: ${props => props.primaryColor};
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: ${props => {
        if (props.primaryColor) return lighten(0.2, props.primaryColor);
      }};
      border-color: ${props => {
        if (props.primaryColor) return lighten(0.2, props.primaryColor);
      }};
    }
  }
  .btn-esolidar.btn-success-full {
    background-color: ${props => props.primaryColor};
    border-color: ${props => props.primaryColor};
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: ${props => {
        if (props.primaryColor) return lighten(0.2, props.primaryColor);
      }};
      border-color: ${props => {
        if (props.primaryColor) return lighten(0.2, props.primaryColor);
      }};
    }
  }
  .footer-sc {
    background-color: ${props => props.secundaryColor};
  }
`;

const Theme = ({ children }) => {
  const context = useContext(PropsContext);
  const whiteLabelInfoData = JSON.parse(context?.initialState?.whitelabel.whiteLabelInfo.data.data);

  const [primaryColor, setPrimaryColor] = useState(whiteLabelInfoData.style.primaryColor);
  const [secundaryColor, setSecondaryColor] = useState(whiteLabelInfoData.style.secondaryColor);
  const [fontFamily, setFontFamily] = useState(whiteLabelInfoData.style.fontFamily);

  useEffect(() => {
    setPrimaryColor(whiteLabelInfoData.style.primaryColor);
    setSecondaryColor(whiteLabelInfoData.style.secondaryColor);
    setFontFamily(whiteLabelInfoData.style.fontFamily);
  }, [context]);

  return (
    <Div primaryColor={primaryColor} secundaryColor={secundaryColor} fontFamily={fontFamily}>
      {children}
    </Div>
  );
};

Theme.propTypes = {
  children: PropTypes.any,
};

export default Theme;
