import { FormattedMessage } from 'react-intl';
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateLoginForm(data) {
  const errors = {};

  if (Validator.isEmpty(data.email)) {
    errors.email = <FormattedMessage id="required" />;
  }
  if (!Validator.isEmail(data.email)) {
    errors.email = <FormattedMessage id="user.register.error.email.invalid" />;
  }
  if (Validator.isEmpty(data.password)) {
    errors.password = <FormattedMessage id="required" />;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
