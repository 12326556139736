/* eslint-disable prefer-rest-params */
/* eslint-disable no-inner-declarations */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { NotificationContainer } from 'react-notifications';
import classnames from 'classnames';
import isObject from '@esolidar/toolkit/build/utils/isObject';
import isEmpty from '@esolidar/toolkit/build/utils/isEmpty';
import variables from '@esolidar/toolkit/build/assets/sass/_export.module.scss';
import SwitchAccountsModal from '@esolidar/toolkit/build/components/switchAccountsModal';
import SetUpPasswordModal from '@esolidar/toolkit/build/user/components/setUpPasswordModal';
import Loading from '@esolidar/toolkit/build/components/loading';
import cookie from 'react-cookies';
import AlertBox from '../src/components/common/container/AlertBox';
import Header from '../src/components/views/header/Header';
import Footer from '../src/components/views/footer/Footer';
import LoginModal from '../src/components/views/auth/LoginModal';
import ColorContext from '../src/contexts/ColorContext';
import setAuthorizationToken from '../src/shared/utils/setAuthorizationToken';
import CookiesBox from './cookies/CookiesBox';
import { toggleSwitchAccountsModal } from '../src/store/actions/authUser';
import useUser from '../src/shared/hooks/useUser';
import Sidebar from './sidebar/Sidebar';
import isExceptionPages from '../src/shared/utils/isExceptionPages';

const Layout = ({ children, config, subscription, contextProps }) => {
  const [showCookies, setShowCookies] = useState(
    cookie.load('skip_cookies') ? cookie.load('skip_cookies') : ''
  );
  const router = useRouter();
  const dispatch = useDispatch();
  const switchAccountsModal = useSelector(state => {
    return state.auth.switchAccountsModal;
  });

  const whitelabel = config;

  const isLoginPage = contextProps.pathname.includes('/auth/login');
  const isSignUpPage =
    contextProps.pathname.includes('/auth/expired-email-validation') ||
    contextProps.pathname.includes('/auth/register') ||
    contextProps.pathname.includes('/user/join-company') ||
    contextProps.pathname.includes('/user/company-invite');
  const isRecoveryPage =
    contextProps.pathname.includes('/auth/recover-password') ||
    contextProps.pathname.includes('/auth/set-password') ||
    contextProps.pathname.includes('/auth/configure-password');

  const isSetPasswordPage =
    contextProps.pathname.includes('/auth/set-password') ||
    contextProps.pathname.includes('/auth/logout');
  const isLoginOrSignUpPage = isLoginPage || isSignUpPage || isRecoveryPage;
  const isExceptionPage = isExceptionPages(router.pathname);

  const isLoggedIn = !!contextProps.token;
  const user = useUser();
  const logout = !!contextProps.pathname.includes('/auth/logout');

  useEffect(() => {
    localStorage.setItem('subscription', JSON.stringify(subscription));
  }, []);

  const handleClickSelectUser = () => {
    dispatch(toggleSwitchAccountsModal({ isOpen: false, redirectNextUrl: true }));
  };

  const handleClickSelectCompany = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_ESOLIDAR_BUSINESS_URL}login?from=${window.location.host}`;
  };

  setAuthorizationToken(
    typeof window !== 'undefined' ? localStorage.getItem('token') : null,
    typeof window !== 'undefined' ? localStorage.getItem('refreshToken') : null
  );

  const hideCookiesMessage = () => {
    cookie.save('skip_cookies', '1', { path: '/' });
    setShowCookies(cookie.load('skip_cookies'));
  };
  let primaryColor = variables['theme-colors-primary'];
  let secondaryColor = variables['theme-colors-dark-primary'];
  let fontFamily = variables['fonts-main'];
  if (whitelabel.data) {
    if (!isEmpty(JSON.parse(whitelabel.data))) {
      primaryColor = JSON.parse(whitelabel.data).style.primaryColor;
      secondaryColor = JSON.parse(whitelabel.data).style.secondaryColor;
      fontFamily = JSON.parse(whitelabel.data).style.fontFamily
        ? JSON.parse(whitelabel.data).style.fontFamily
        : variables['fonts-main'];
    }
  }

  const hasEmailPassword = user?.auth_email === 0;

  return (
    <>
      <Head>
        <title key="title">{whitelabel.company.name}</title>
        <link rel="icon" href={whitelabel.company.logo} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1"
        />
        <link href={whitelabel.company.logo} rel="shortcut icon" type="image/vnd.microsoft.icon" />
        <meta
          key="description"
          name="description"
          content={JSON.parse(whitelabel.data).share.shareDescription}
        />
        <meta key="og:type" property="og:title" content={whitelabel.company.name} />
        <meta
          key="og:description"
          property="og:description"
          content={JSON.parse(whitelabel.data).share.shareDescription}
        />
        <meta
          key="og:image"
          property="og:image"
          content={
            isObject(JSON.parse(whitelabel.data).share)
              ? `${process.env.NEXT_PUBLIC_cdn_uploads_url}/${
                  JSON.parse(whitelabel.data).share.shareImage
                }`
              : whitelabel.data.company.cover_image
          }
        />
        <meta
          key="og:url"
          property="og:url"
          content={`https://${whitelabel.domain ? whitelabel.domain : whitelabel.subdomain}`}
        />
        <meta key="og:type" property="og:type" content="website" data-doc-meta="true" />
        <meta key="og:image:width" property="og:image:width" content="1200" />
        <meta key="og:image:height" property="og:image:height" content="630" />
        <meta key="og:image:alt" property="og:image:alt" content={whitelabel.company.name} />
        <meta key="og:image:type" property="og:image:type" content="image/png" />
        <link href="/robots.txt" rel="author" />
        <link
          href={`https://${whitelabel.domain ? whitelabel.domain : whitelabel.subdomain}/en`}
          hrefLang="en-us"
          rel="alternate"
        />
        <link
          href={`https://${whitelabel.domain ? whitelabel.domain : whitelabel.subdomain}/br`}
          hrefLang="pt-br"
          rel="alternate"
        />
        <link
          href={`https://${whitelabel.domain ? whitelabel.domain : whitelabel.subdomain}/pt`}
          hrefLang="pt-pt"
          rel="alternate"
        />
        {process.env.NEXT_PUBLIC_ENV === 'production' && (
          <>
            <meta name="robots" content="index, follow, noarchive" />
            <meta name="googlebot" content="index, follow, noarchive" />
          </>
        )}
        {process.env.NEXT_PUBLIC_ENV !== 'production' && (
          <>
            <meta name="robots" content="noindex, nofollow, noarchive" />
            <meta name="googlebot" content="noindex, nofollow, noarchive" />
          </>
        )}
        <meta
          key="keywords"
          name="keywords"
          content={
            isObject(JSON.parse(whitelabel.data).share)
              ? JSON.parse(whitelabel.data).share.tags
              : ''
          }
        />
        <link
          key="canonical"
          href={`https://${whitelabel.domain ? whitelabel.domain : whitelabel.subdomain}`}
          rel="canonical"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat+Alternates%7CRaleway%7CRoboto%7CScope+One&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/xse0hrt.css" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_google_ga}`}
        />
        {config.google_analytics && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.google_analytics}`}
          />
        )}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_google_ga}', {
                page_path: window.location.pathname,
              });
              ${
                config.google_analytics
                  ? `gtag('config', '${config.google_analytics}', {
                page_path: window.location.pathname,
              });`
                  : ''
              }
          `,
          }}
        />
      </Head>
      <ColorContext.Provider
        value={{
          primaryColor,
          secondaryColor,
          fontFamily,
        }}
      >
        <div className="main-app">
          <NotificationContainer />
          <AlertBox />
          <LoginModal />
          {!isSetPasswordPage && (
            <SetUpPasswordModal
              buttonUrl="/auth/set-password"
              cdnStaticUrl={process.env.NEXT_PUBLIC_cdn_static_url}
              showModal={hasEmailPassword}
            />
          )}
          <SwitchAccountsModal
            title={switchAccountsModal.title || 'header.switchAccounts'}
            numberOfAccounts={2}
            subtitle="switchAccounts.subtitle"
            isOpen={switchAccountsModal.isOpen}
            user={user}
            companies={[whitelabel.company]}
            handleClickSelectUser={handleClickSelectUser}
            handleClickSelectCompany={handleClickSelectCompany}
          />
          <div>
            {isLoggedIn && !isExceptionPage && <Sidebar />}

            <main
              className={classnames(
                { main_page: !isLoginOrSignUpPage },
                {
                  'border-bottom-header': isLoggedIn && !isExceptionPage,
                  ' container': (!isLoginOrSignUpPage && !isLoggedIn) || isExceptionPage,
                  'absolute-footer': isLoggedIn && !isExceptionPage,
                }
              )}
              style={{
                backgroundColor:
                  isLoginOrSignUpPage || isRecoveryPage
                    ? variables['background-secondary']
                    : 'unset',
              }}
            >
              {!isLoginOrSignUpPage && (
                <Header logo={whitelabel.logo} hideMenu={isLoggedIn && !isExceptionPage} />
              )}
              {isLoginPage && <Header logo={whitelabel.logo} hideMenu hideBtnSignIn />}
              {isSignUpPage && <div />}
              {isRecoveryPage && <Header logo={whitelabel.logo} hideMenu />}

              {logout && (
                <div>
                  <Loading />
                </div>
              )}
              <div
                className={classnames(
                  { 'main-container': !isSignUpPage },
                  { 'signup-header-page': isSignUpPage }
                )}
              >
                {children}
              </div>
            </main>
          </div>
          {((!isLoggedIn && !isLoginOrSignUpPage) || isExceptionPage) && !logout && <Footer />}
          {typeof window !== 'undefined' && showCookies !== '1' && (
            <CookiesBox config={config} hideCookiesMessage={hideCookiesMessage} />
          )}
        </div>
      </ColorContext.Provider>
      <ReactTooltip className="tooltip-component" />
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.any,
  config: PropTypes.object,
  contextProps: PropTypes.object,
  data: PropTypes.shape({
    whitelabel: PropTypes.shape({
      whiteLabelInfo: PropTypes.shape({
        data: PropTypes.shape({
          company: PropTypes.shape({
            logo: PropTypes.any,
            name: PropTypes.any,
            subscription: PropTypes.shape({
              id: PropTypes.any,
            }),
          }),
          data: PropTypes.shape({
            company: PropTypes.shape({
              cover_image: PropTypes.any,
            }),
          }),
          domain: PropTypes.any,
          logo: PropTypes.any,
          subdomain: PropTypes.any,
        }),
      }),
    }),
  }),
  subscription: PropTypes.any,
  whitelabel: PropTypes.shape({
    company: PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
    }),
    data: PropTypes.string,
    domain: PropTypes.string,
    logo: PropTypes.string,
    subdomain: PropTypes.string,
  }),
};
export default Layout;
