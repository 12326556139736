const exceptionPages = [
  '/about',
  '/about/feed',
  '/about/auction/list',
  '/about/auction/detail/[id]',
  '/about/crowdfunding/list',
  '/about/crowdfunding/detail/[id]',
  '/accelerator',
  '/accelerator/[id]',
  '/accelerator/detail/[id]',
  '/about/projects/detail/[id]',
];

const isExceptionPages = path => !!exceptionPages.find(element => element === path);

export default isExceptionPages;
