import { FETCH_FORM, POST_SURVEY_RESPONSE } from '../actions';

const INITIAL_STATE = {
  formQuestions: null,
  surveyResponse: null,
};

export default function reducerSurveys(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FORM:
      if (action.payload.status === 200) {
        return { ...state, formQuestions: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, formQuestions: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, formQuestions: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, formQuestions: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, formQuestions: action.payload.response };
      }
      break;
    case POST_SURVEY_RESPONSE:
      if (action.payload.status === 200) {
        return { ...state, surveyResponse: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, surveyResponse: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, surveyResponse: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, surveyResponse: action.payload.response };
      }
      break;
    default:
      return state;
  }
}
