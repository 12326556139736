import {
  POST_COMPANY,
  GET_COMPANY,
  POST_UPDATE_MY_COMPANY,
  GET_ADMINS_COMPANY,
  GET_COMPANY_INFO,
  POST_CREATE_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
  POST_EDIT_DEPARTMENT,
  DELETE_DEPARTMENT,
  POST_UPLOAD_DOCUMENT_COMPANY,
  GET_UPLOAD_DOCUMENT_COMPANY,
  DELETE_UPLOAD_DOCUMENT_COMPANY,
  UPDATE_VOLUNTEER_HOURS,
  GET_DOCUMENTS,
  GET_TEAMS_WITH_FILTERS,
} from '../actions';

const INITIAL_STATE = {
  company: null,
  myCompany: null,
  updateMyCompany: null,
  admins: null,
  companyInfo: {},
  createDepartment: null,
  allDepartments: {},
  editDepartment: null,
  deletedDepartment: null,
  uploadedDocument: null,
  companyDocuments: null,
  deletedDocument: null,
  volunteerHoursUpdated: null,
  paymentStatistics: null,
  teams: null,
};

export default function reducerCompanies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_COMPANY:
      if (action.payload.status === 200) {
        return { ...state, company: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, company: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, company: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, company: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, error: action.payload.response };
      }
      break;

    case GET_COMPANY:
      if (action.payload.status === 200) {
        return { myCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { myCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { myCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { myCompany: action.payload.response };
      }
      break;

    case POST_UPDATE_MY_COMPANY:
      if (action.payload.status === 200) {
        return { updateMyCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { updateMyCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { updateMyCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { updateMyCompany: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { updateMyCompany: action.payload.response };
      }
      break;

    case GET_ADMINS_COMPANY:
      if (action.payload.status === 200) {
        return { admins: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { admins: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { admins: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { admins: action.payload.response };
      }
      break;

    case GET_COMPANY_INFO:
      if (action.payload.status === 200) {
        return { companyInfo: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { companyInfo: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { companyInfo: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { companyInfo: action.payload.response };
      }
      break;

    case POST_CREATE_DEPARTMENT:
      if (action.payload.status === 200) {
        return { createDepartment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { createDepartment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { createDepartment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { createDepartment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { createDepartment: action.payload.response };
      }
      break;

    case GET_ALL_DEPARTMENTS:
      if (action.payload.status === 200) {
        return { allDepartments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { allDepartments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { allDepartments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { allDepartments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { allDepartments: action.payload.response };
      }
      break;

    case POST_EDIT_DEPARTMENT:
      if (action.payload.status === 200) {
        return { editDepartment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { editDepartment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { editDepartment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { editDepartment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { editDepartment: action.payload.response };
      }
      break;

    case DELETE_DEPARTMENT:
      if (action.payload.status === 200) {
        return { deletedDepartment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { deletedDepartment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { deletedDepartment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { deletedDepartment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { deletedDepartment: action.payload.response };
      }
      break;

    case POST_UPLOAD_DOCUMENT_COMPANY:
      if (action.payload.status === 200) {
        return { uploadedDocument: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { uploadedDocument: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { uploadedDocument: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { uploadedDocument: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { uploadedDocument: action.payload.response };
      }
      break;

    case GET_UPLOAD_DOCUMENT_COMPANY:
      if (action.payload.status === 200) {
        return { companyDocuments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { companyDocuments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { companyDocuments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { companyDocuments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { companyDocuments: action.payload.response };
      }
      break;

    case DELETE_UPLOAD_DOCUMENT_COMPANY:
      if (action.payload.status === 200) {
        return { deletedDocument: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { deletedDocument: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { deletedDocument: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { deletedDocument: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { deletedDocument: action.payload.response };
      }
      break;

    case UPDATE_VOLUNTEER_HOURS:
      if (action.payload.status === 200) {
        return { volunteerHoursUpdated: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { volunteerHoursUpdated: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { volunteerHoursUpdated: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { volunteerHoursUpdated: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { volunteerHoursUpdated: action.payload.response };
      }
      break;

    case GET_DOCUMENTS:
      if (action.payload.status === 200) {
        return { ...state, companyDocuments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, companyDocuments: action.payload.message };
      }
      if (action.payload.response.status === 500) {
        return { ...state, companyDocuments: action.payload.response };
      }
      if (action.payload.response.status === 400) {
        return { ...state, companyDocuments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, companyDocuments: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, companyDocuments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, companyDocuments: action.payload.response };
      }
      break;

    case GET_TEAMS_WITH_FILTERS:
      if (action.payload.status === 200) {
        return { ...state, teamsWithFilters: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, teamsWithFilters: action.payload.message };
      }
      if (action.payload.response.status === 500) {
        return { ...state, teamsWithFilters: action.payload.response };
      }
      if (action.payload.response.status === 400) {
        return { ...state, teamsWithFilters: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, teamsWithFilters: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, teamsWithFilters: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, teamsWithFilters: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
