import { GET_FILES, UPLOAD_FILE, DELETE_FILE } from '../actions';

const INITIAL_STATE = {
  files: null,
  newFile: null,
  deletedFile: null,
};

export default function reducerProjectsFiles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FILES:
      if (action.payload.status === 200) {
        return { ...state, files: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, files: action.payload.message };
      }
      if (action.payload.response.status === 500) {
        return { ...state, files: action.payload.response };
      }
      if (action.payload.response.status === 400) {
        return { ...state, files: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, files: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, files: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, files: action.payload.response };
      }
      break;

    case UPLOAD_FILE:
      if (action.payload.status === 200) {
        return { ...state, newFile: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newFile: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newFile: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newFile: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, newFile: action.payload.response };
      }
      break;

    case DELETE_FILE:
      if (action.payload.status === 200) {
        return { ...state, deletedFile: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deletedFile: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deletedFile: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deletedFile: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, deletedFile: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deletedFile: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
