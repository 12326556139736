import { FETCH_GIFTCARDS_BY_EMPLOYEE, POST_GIFTCARDS_EMPLOYEE_DONATE } from '../actions';

const INITIAL_STATE = {
  giftCardsByEmployee: null,
  giftCardsEmployeeDonate: null,
};

export default function reducerGiftCards(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GIFTCARDS_BY_EMPLOYEE:
      if (action.payload.status === 200) {
        return { ...state, giftCardsByEmployee: action.payload.data };
      }
      return { ...state, giftCardsByEmployee: action.payload.message };

    case POST_GIFTCARDS_EMPLOYEE_DONATE:
      if (action.payload.status === 200) {
        return { ...state, giftCardsEmployeeDonate: action.payload.data };
      }
      return { ...state, giftCardsEmployeeDonate: action.payload.message };

    default:
      return state;
  }
}
