import { FETCH_COUNTRIES } from '../actions';

const INITIAL_STATE = { countries: null };

export default function reducerCountries(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COUNTRIES:
      if (action.payload.status === 200) {
        return { ...state, countries: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, countries: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, countries: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, countries: action.payload.response };
      }

      break;
    default:
      return state;
  }
}
