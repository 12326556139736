import { filter } from 'lodash';

const locationIncludes = path => {
  return typeof window !== 'undefined' ? window.location.href.includes(path) : '';
};

const activeFeed = locationIncludes('feed') ? 'active' : '';
const activeProjects = locationIncludes('accelerator') ? 'active' : '';
const activeCrowdfundingList = locationIncludes('crowdfunding') ? 'active' : '';
const activeAuctionsList = locationIncludes('auction') ? 'active' : '';

const renderMenu = (locale, initiatives, subscriptions, isLoggedIn) => [
  {
    id: 'menu.feed',
    href: isLoggedIn ? `/${locale}/about/feed` : `/${locale}/feed`,
    show: initiatives ? initiatives.posts : false,
    isFeatureActive: 1,
    active: activeFeed,
  },
  {
    id: 'whitelabel.accelarator.title',
    href: `/${locale}/accelerator`,
    show: initiatives ? initiatives.projects : false,
    isFeatureActive: filter(subscriptions, { name: 'projects' }).length,
    active: activeProjects,
    // submenus: [
    //   {
    //     id: 'header.projects.about',
    //     href: `/${locale}/accelerator`,
    //     active: typeof window !== 'undefined' ? activeProjectsAbout : '',
    //     show: true,
    //   },
    // ],
    showSubmenus: false,
  },
  {
    id: 'menu.crowdfunding',
    href: isLoggedIn ? `/${locale}/about/crowdfunding/list` : `/${locale}/needs/crowdfunding/list`,
    show: initiatives ? initiatives.crowdfundings : false,
    isFeatureActive: filter(subscriptions, { name: 'crowdfunding-public' }).length,
    active: activeCrowdfundingList,
  },
  {
    id: 'header.auctions',
    href: isLoggedIn ? `/${locale}/about/auction/list` : `/${locale}/needs/auction/list`,
    show: initiatives ? initiatives.auctions : false,
    isFeatureActive: filter(subscriptions, { name: 'auctions' }).length,
    active: activeAuctionsList,
  },
];

export default renderMenu;
