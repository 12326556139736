import axios from 'axios';
import queryString from 'query-string';
import { uploadFormData, uploadCompanyFile } from '../../../shared/utils/formData';
import {
  POST_COMPANY,
  GET_COMPANY,
  POST_UPDATE_MY_COMPANY,
  GET_ADMINS_COMPANY,
  GET_COMPANY_INFO,
  POST_CREATE_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
  POST_EDIT_DEPARTMENT,
  DELETE_DEPARTMENT,
  POST_UPLOAD_DOCUMENT_COMPANY,
  GET_UPLOAD_DOCUMENT_COMPANY,
  DELETE_UPLOAD_DOCUMENT_COMPANY,
  UPDATE_VOLUNTEER_HOURS,
  GET_DOCUMENTS,
  GET_TEAMS_WITH_FILTERS,
} from '..';

import ROOT_URL from '../../../shared/constants/apiUrl';

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
};

export function companyAddPost(userId, data) {
  const request = axios.post(`${ROOT_URL}user/${userId}/companies/register`, uploadFormData(data));

  return {
    type: POST_COMPANY,
    payload: request,
  };
}

export function CompanyGet(companyId) {
  const request = axios.get(`${ROOT_URL}companies/${companyId}`);

  return {
    type: GET_COMPANY,
    payload: request,
  };
}

export function updateCompanyPost(companyId, props) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}`, uploadFormData(props), config);

  return {
    type: POST_UPDATE_MY_COMPANY,
    payload: request,
  };
}

export function companyAdminsGet(companyId) {
  const request = axios.get(`${ROOT_URL}companies/${companyId}/admins`);

  return {
    type: GET_ADMINS_COMPANY,
    payload: request,
  };
}

export function companyInfoGet(companyId) {
  const request = axios.get(`${ROOT_URL}companies/${companyId}/info`);
  return {
    type: GET_COMPANY_INFO,
    payload: request,
  };
}

export function createDepartmentPost(companyId, data) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/departments`, data);

  return {
    type: POST_CREATE_DEPARTMENT,
    payload: request,
  };
}

export function getAllDepartments(companyId) {
  const request = axios.get(`${ROOT_URL}companies/${companyId}/departments`);

  return {
    type: GET_ALL_DEPARTMENTS,
    payload: request,
  };
}

export function editDepartmentPost(companyId, departmentId, data) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/departments/${departmentId}`, data);

  return {
    type: POST_EDIT_DEPARTMENT,
    payload: request,
  };
}

export function deleteDepartment(companyId, departmentId) {
  const request = axios.delete(`${ROOT_URL}companies/${companyId}/departments/${departmentId}`);

  return {
    type: DELETE_DEPARTMENT,
    payload: request,
  };
}

export function postUploadDocumentCompany(companyId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/documents`,
    uploadCompanyFile(data),
    config
  );

  return {
    type: POST_UPLOAD_DOCUMENT_COMPANY,
    payload: request,
  };
}

export function getUploadDocumentCompany(companyId, page = 1, search) {
  let searchTerm;
  if (search) {
    searchTerm = `&search=${search}`;
  } else {
    searchTerm = '';
  }

  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/documents?page=${page}${searchTerm}`
  );

  return {
    type: GET_UPLOAD_DOCUMENT_COMPANY,
    payload: request,
  };
}

export function deleteUploadDocumentCompany(companyId, documentId) {
  const request = axios.delete(`${ROOT_URL}companies/${companyId}/documents/${documentId}`);

  return {
    type: DELETE_UPLOAD_DOCUMENT_COMPANY,
    payload: request,
  };
}

export function updateVolunteerHours(companyId, userId, reviewId, data) {
  const params = `${ROOT_URL}companies/${companyId}/employees/${userId}/reviews/${reviewId}/volunteer-hours`;
  const request = axios.post(params, data);

  return {
    type: UPDATE_VOLUNTEER_HOURS,
    payload: request,
  };
}

export function getCompanyDocuments(companyId, page = 1, search) {
  let newSearch = '';
  if (search) {
    newSearch = `&search=${search}`;
  } else {
    newSearch = '';
  }
  const request = axios.get(`${ROOT_URL}companies/${companyId}/documents?page=${page}${newSearch}`);

  return {
    type: GET_DOCUMENTS,
    payload: request,
  };
}

export function getTeamsWithFilters(companyId, permission) {
  const params = queryString.stringify({
    permission,
  });
  const request = axios.get(`${ROOT_URL}companies/${companyId}/departments?${params}`);

  return {
    type: GET_TEAMS_WITH_FILTERS,
    payload: request,
  };
}
