/* global Echo */

import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import slugify from '@esolidar/toolkit/build/utils/slugify';
import isDefined from '@esolidar/toolkit/build/utils/isDefined';
import isEmpty from '@esolidar/toolkit/build/utils/isEmpty';
import Loading from '@esolidar/toolkit/build/components/loading';
import NotificationsBell from '@esolidar/toolkit/build/components/noticationsBell';
import NotificationsMobile from '@esolidar/toolkit/build/components/noticationsMobile';
import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from '../../../store/actions/notifications';
import { companyInfoGet } from '../../../store/actions/companies';
import translateMessage from '../../../shared/translations/translateMessage';
import getRoute from '../../../shared/utils/routes';
import PropsContext from '../../../contexts/PropsContext';

class NotificationsPage extends Component {
  static contextType = PropsContext;

  state = {
    totalNotifications: 0,
    totalUnreadNotifications: 0,
    notificationsList: [],
    page: 1,
    hasMore: false,
    isLoadingPage: true,
    config: null,
    isLoggedIn: false,
  };

  componentDidMount() {
    const user = isEmpty(this.props.user) ? this.context.user : this.props.user;

    this.updateState({
      token: localStorage.getItem('token'),
      config: localStorage.getItem('config'),
      isLoggedIn: !!this.context.token && !isEmpty(user),
    });

    if (localStorage.getItem('user')) {
      const userId = JSON.parse(localStorage.getItem('user')).id;
      if (typeof window !== 'undefined') {
        Echo.private(`App.User.${userId}`).notification(notification => {
          const { notificationsList, totalUnreadNotifications } = this.state;
          const newNotificationText = notification.notification.text.replace(/(<([^>]+)>)/gi, '');

          notificationsList.unshift(notification.notification);
          const total = totalUnreadNotifications + 1;
          this.updateState({
            totalUnreadNotifications: total,
            notificationsList,
          });

          NotificationManager.success(
            newNotificationText,
            translateMessage({
              id: 'newNotification',
            }),
            5000
          );
        });
      }
    }

    if (localStorage.getItem('token')) {
      const companyId = JSON.parse(localStorage.getItem('config')).company_id;
      this.props.getNotifications(companyId);
      this.props.companyInfoGet(companyId);
      this.updateState({
        isLoadingPage: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { notificationsList, config } = this.state;
    if (prevProps.notifications !== this.props.notifications) {
      const { notifications } = this.props;
      if (notifications.code === 200) {
        let allNotifications = [];
        if (notifications.readed) {
          allNotifications = notifications.data.data;
        } else {
          allNotifications = [...notificationsList, ...notifications.data.data];
        }
        this.updateState({
          notificationsList: allNotifications,
          page: notifications.data.current_page,
          totalNotifications: notifications.data.total,
          isLoadingPage: false,
        });
      }
    }
    if (prevProps.readed !== this.props.readed) {
      const companyId = JSON.parse(config).company_id;
      this.props.getNotifications(companyId, 1, true);
      this.props.companyInfoGet(companyId);
      this.updateState({
        isLoadingPage: true,
      });
    }
    if (prevProps.companyInfo !== this.props.companyInfo) {
      if (this.props.companyInfo) {
        this.updateState({
          totalUnreadNotifications: this.props.companyInfo.data.unread_notifications,
        });
      }
    }
    if (prevProps.loginModal !== this.props.loginModal) {
      const user =
        typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user') || 'null') : null;
      this.updateState({
        isLoggedIn: isDefined(user) ? !!Object.keys(user).length : false,
      });
    }
  }

  handleScroll = () => {
    const { totalNotifications, notificationsList } = this.state;
    if (totalNotifications > notificationsList.length) {
      this.updateState({
        hasMore: true,
      });
    } else {
      this.updateState({
        hasMore: false,
      });
    }
  };

  updateState = state => {
    this.setState(state);
  };

  nextPage = () => {
    const { config } = this.state;
    const { isLoadingPage, totalNotifications, notificationsList } = this.state;
    const companyId = JSON.parse(config).company_id;
    const page = this.state.page + 1;

    if (!isLoadingPage && totalNotifications > notificationsList.length) {
      this.props.getNotifications(companyId, page);
      this.updateState({
        isLoadingPage: true,
      });
    }
  };

  markAllAsRead = () => {
    const { config } = this.state;
    const companyId = JSON.parse(config).company_id;
    this.props.markAllNotificationsAsRead(companyId);
  };

  markAsRead = row => {
    const { config } = this.state;
    const { id, type, data } = row;
    const companyId = JSON.parse(config).company_id;
    this.props.markNotificationAsRead(companyId, id);
    let url;

    switch (type) {
      case 'CommentCreatedNotification':
        url = `/${this.context.locale}/feed/${companyId}/post/${data.post_id}/comment/${data.comment_id}`;
        break;

      case 'PostCreatedNotification':
      case 'CommentResponseCreatedNotification':
        url = `/${this.context.locale}/feed/${companyId}/post/${data.post_id}`;
        break;

      case 'UsersThatRepliedToTheCommentTooNotification':
        url = `/${this.context.locale}/post/${data.post_id}`;
        break;

      case 'ProjectTicketCreatedByCompanyNotification':
      case 'TicketProjectStatusChangedNotification':
      case 'TicketProjectCommentCreatedByCompanyNotification':
      case 'TicketProjectCommentCreatedByUserNotification':
        url = `/${this.context.locale}/projects/detail/${data.project_id}-${slugify(
          data.project_title
        )}?tab=tickets&id=${data.ticket_id}&owner=true`;
        break;

      case 'CrowdfundingStartedNotification':
      case 'CrowdfundingResponseCreatedMemberNotification':
      case 'CrowdfundingApprovedByCompanyNotification':
      case 'CrowdfundingRejectedByCompanyNotification':
      case 'CrowdfundingStartedOwnerUserNotification':
      case 'CrowdfundingContributeCreatedUserOwnerNotification':
      case 'CrowdfundingCommentCreatedUserOwnerNotification':
      case 'CrowdfundingEndedUserOwnerNotification':
      case 'CrowdfundingIsEndingUserOwnerNotification':
        url = `/${this.context.locale}/needs/crowdfunding/detail/${data.crowdfunding_id}-${slugify(
          data.crowdfunding_title
        )}`;
        break;

      case 'NewAuctionBidCreatedLastBidderNotification':
      case 'AuctionCommentResponseCreatedUserNotification':
      case 'AuctionCreatedByCompanyProjectOwnerNotification':
        url = `/${this.context.locale}/needs/auction/detail/${data.auction_id}-${slugify(
          data.auction_title
        )}`;
        break;

      case 'GiftcardCreatedNotification':
      case 'GiftcardWasNotSpentNotification':
        url = `/${this.context.locale}/donations/giftcards`;
        break;

      case 'ProjectConfigArchivedCommunityNotification':
        url = getRoute.private.accelerator.program.LIST(this.context.locale);
        break;

      case 'ProjectConfigPublishedCommunityNotification':
        url = getRoute.private.accelerator.project.LIST_MY(
          this.context.locale,
          data.project_config_id
        );
        break;

      case 'ProjectStatusChangeNotification':
        url = getRoute.private.accelerator.project.DETAIL(
          this.context.locale,
          data.project_config_id,
          data.project_id,
          'project',
          true
        );
        break;

      case 'UploadedProjectFileNotification':
      case 'ProjectFileUploadedNotification':
      case 'ProjectFileDeletedByAdminNotification':
        url = getRoute.private.accelerator.project.DETAIL(
          this.context.locale,
          data.project_config_id,
          data.project_id,
          data.project_title.toLowerCase(),
          true
        );
        break;

      case 'ProjectCommentReplyCreatedNotification':
      case 'ProjectCommentCreatedNotification':
      case 'ProjectPublicCommentCreatedNotification':
      case 'ProjectPublicCommentDeletedByAdminToOthersAdminsNotification':
      case 'ProjectPublicCommentDeletedByAdminToOwnerNotification':
        url = `${getRoute.private.accelerator.project.DETAIL(
          this.context.locale,
          data.project_config_id,
          data.project_id,
          data.project_title.toLowerCase(),
          false
        )}?tab=comments`;
        break;

      default:
        url = '#';
        break;
    }

    window.location.href = url;
  };

  render() {
    const { hasMore, notificationsList, totalUnreadNotifications, isLoadingPage, isLoggedIn } =
      this.state;
    const { isNotificationsPage } = this.props;

    return (
      <>
        {isLoggedIn && (
          <NotificationsBell
            notificationsHeadTitle={translateMessage({ id: 'Notifications.head.title' })}
            totalNotifications={totalUnreadNotifications}
            markAllAsReadTitle={translateMessage({ id: 'Notifications.head.mark.all' })}
            markAllAsReadFunc={this.markAllAsRead}
            handleScrollFunc={this.handleScroll}
            notifications={notificationsList}
            loadMoreFunc={this.nextPage}
            hasMoreToLoad={hasMore}
            markAsReadFunc={this.markAsRead}
          />
        )}

        {isNotificationsPage && isLoggedIn && (
          <div>
            {isLoadingPage && <Loading loadingClass="loading-messages" />}
            <NotificationsMobile
              notificationsHeadTitle={translateMessage({ id: 'Notifications.head.title' })}
              totalNotifications={totalUnreadNotifications}
              markAllAsReadTitle={translateMessage({ id: 'Notifications.head.mark.all' })}
              markAllAsReadFunc={this.markAllAsRead}
              handleScrollFunc={this.handleScroll}
              notifications={notificationsList}
              loadMoreFunc={this.nextPage}
              hasMoreToLoad={hasMore}
              markAsReadFunc={this.markAsRead}
            />
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    readed: state.notifications.readed,
    notifications: state.notifications.notifications,
    companyInfo: state.companies.companyInfo,
    updatedNotification: state.notifications.updatedNotification,
    loginModal: state.auth.loginModal,
    user: state.auth.user,
  };
}

NotificationsPage.propTypes = {
  getNotifications: PropTypes.func,
  notifications: PropTypes.shape({
    code: PropTypes.number,
    readed: PropTypes.bool,
    data: PropTypes.shape({
      data: PropTypes.array,
      current_page: PropTypes.number,
      total: PropTypes.number,
      last_page: PropTypes.number,
    }),
  }),
  readed: PropTypes.object,
  companyInfo: PropTypes.object,
  markAllNotificationsAsRead: PropTypes.func,
  companyInfoGet: PropTypes.func,
  markNotificationAsRead: PropTypes.func,
  isNotificationsPage: PropTypes.bool,
  loginModal: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps, {
  companyInfoGet,
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
})(NotificationsPage);
