import { useContext } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from '@esolidar/toolkit/build/utils/isEmpty';
import PropsContext from '../../contexts/PropsContext';
import User from '../../interfaces/user';
import { RootState } from '../../store';

const useUser = (): User => {
  const user = {};

  const propsContext = useContext(PropsContext);
  const { user: userServer } = propsContext;

  const emailUser = useSelector((store: RootState) => store.auth.user);
  const fbUser = useSelector((store: RootState) => store.auth.fbAuth);
  const userInfo = useSelector((store: RootState) => store.user.userInfo);

  if (!isEmpty(userInfo.data)) return userInfo.data;

  if (!isEmpty(emailUser)) {
    if (emailUser?.data) return emailUser.data.user;
    return emailUser;
  }

  if (!isEmpty(fbUser)) {
    if (fbUser?.data) return fbUser.data.user;
    return fbUser;
  }

  return isEmpty(user) ? userServer : user;
};

export default useUser;
