/* eslint-disable react/jsx-indent */
/* eslint-disable camelcase */
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import cookie from 'react-cookies';
import Button from '@esolidar/toolkit/build/elements/button';
import Steps, { Step } from 'rc-steps';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import CustomModal from '@esolidar/toolkit/build/elements/customModal';
import PasswordField from '@esolidar/toolkit/build/elements/passwordField';
import TextField from '@esolidar/toolkit/build/elements/textField';
import CheckboxField from '@esolidar/toolkit/build/elements/checkboxField';
import Loading from '@esolidar/toolkit/build/components/loading';
import isCompanyAdmin from '@esolidar/toolkit/build/utils/isCompanyAdmin';
import getUrlParam from '@esolidar/toolkit/build/utils/getUrlParam';
import { loginPost, toggleLoginModal } from '../../../store/actions/authUser';
import { postJoinUserMember, postJoinUserEmployee } from '../../../store/actions/members';
import { postResendemailVerification, getUser } from '../../../store/actions/user';
import { showAlert } from '../../../store/actions/alert';
import ColorContext from '../../../contexts/ColorContext';
import setLocalStorageUser from '../../../shared/utils/setLocalStorageUser';
import validateLoginForm from '../../common/validations/loginFormValidator';
import setAuthorizationToken from '../../../shared/utils/setAuthorizationToken';
import getQueryParams from '../../../shared/utils/getQueryParams';
import getUrlVars from '../../../shared/utils/getUrlVars';
import translateMessage from '../../../shared/translations/translateMessage';
import PropsContext from '../../../contexts/PropsContext';
import setUserCookie from '../../../shared/utils/setUserCookie';

const href = typeof window !== 'undefined' ? window.location.href : '';

const inviteEmail = getUrlParam('email') || '';

class FormLogin extends Component {
  static contextType = PropsContext;

  state = {
    companyCode: getUrlVars(href).company_code,
    invite_whitelabel: getUrlVars(href).invite_whitelabel,
    inviteCompany: getUrlVars(href).invide_company,
    email: inviteEmail.replace(' ', '+'),
    employee: getUrlVars(href).employee,
    member: getUrlVars(href).member,
    password: '',
    errors: {},
    pageLoading: false,
    redirect: false,
    disabledButton: false,
    disabledResendButton: false,
    showModal: false,
    showModalDeactivatedAccount: false,
    showModalNotValidated: false,
    showModalNotRegistred: false,
    showModalBlockedAccount: false,
    isLoggedIn: typeof window !== 'undefined' ? localStorage.getItem('token') : '',
    windowState: undefined,
    rememberMe: false,
    company: {},
  };

  componentDidMount() {
    if (this.context.query?.from) {
      this.updateState({ pageLoading: true });
    }
    this.setState({
      windowState: window,
      company:
        typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('config')).company : {},
    });

    ReactTooltip.rebuild();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userInfo !== this.props.userInfo) {
      if (this.props.userInfo.code === 200) {
        const user = this.props.userInfo.data;

        localStorage.setItem('user', JSON.stringify(user));
        window.location.href = '/';

        setUserCookie(user);

        this.updateState({
          isLoggedIn: true,
        });
      }
    }

    if (this.props.user !== prevProps.user) {
      if (this.props.user.code === 200) {
        const { user, token, refreshToken } = this.props.user.data;
        cookie.save('eSolidar_has_login', '1', {
          maxAge: 6 * 30 * 24 * 3600,
          path: '/',
        });

        setUserCookie(user);

        setAuthorizationToken(token, refreshToken);
        setLocalStorageUser(user, token, refreshToken);
        localStorage.setItem('rememberMe', this.state.rememberMe);

        const isAdmin = Boolean(isCompanyAdmin(this.state.company.id, user));

        if (this.props.loginModal.isOpen)
          this.props.toggleLoginModal({
            isOpen: false,
            isSuccess: true,
            isAdmin,
          });

        this.updateState({
          redirect: !this.props.loginModal.isOpen,
          disabledButton: false,
        });

        if (isAdmin) return;

        window.location.href = this.props.loginModal?.nextUrl
          ? this.props.loginModal?.nextUrl
          : `/${localStorage.getItem('lang')}/feed`;
      } else if (this.props.user.status === 400) {
        const errorsList = this.props.user.data.data;
        const { errors } = this.state;
        if (errorsList === 'Unauthorized') {
          errors.email = <FormattedMessage id="auth.login.invalid" />;
          errors.password = <FormattedMessage id="auth.login.invalid" />;
        } else {
          errorsList.map(error => {
            errors[error.field] = error.message;
            return errors;
          });
        }
        this.updateState({
          errors,
          password: '',
          disabledButton: false,
        });
      } else if (this.props.user.status === 401) {
        const error = this.props.user.data.data;
        const { errors } = this.state;

        switch (error) {
          case 'UNAUTHORIZED_COMMUNITY':
            this.updateState({ showModal: true });

            break;
          case 'UNAUTHORIZED_EMAIL':
            errors.email = <FormattedMessage id="auth.login.notRegistered" />;
            break;

          case 'UNAUTHORIZED_PASSWORD':
            errors.password = <FormattedMessage id="auth.login.invalid.password" />;
            break;

          case 'UNAUTHORIZED_STATUS_D':
            this.updateState({ showModalDeactivatedAccount: true });
            break;

          case 'UNAUTHORIZED_STATUS_B':
            this.updateState({ showModalDeactivatedAccount: true });
            break;

          case 'UNAUTHORIZED_COMMUNITY_MEMBER_STATUS_D':
            this.updateState({ showModalDeactivatedAccount: true });
            break;

          case 'UNAUTHORIZED_STATUS_P':
            this.updateState({ showModalNotValidated: true });
            break;

          case 'UNAUTHORIZED_COMMUNITY_MEMBER_STATUS_B':
            this.updateState({ showModalBlockedAccount: true });
            break;

          default:
            errors.email = <FormattedMessage id="auth.login.invalid" />;
            errors.password = <FormattedMessage id="auth.login.invalid" />;
        }

        this.updateState({
          errors,
          disabledButton: false,
          setPassword: '',
        });
      }
    }

    if (this.props.resendEmailVerification !== prevProps.resendEmailVerification) {
      if (this.props.resendEmailVerification.status === 404) {
        this.updateState({ disabledButton: false });
        this.props.showAlert({
          alertBox: {
            alertVisible: true,
            alertClass: 'warning',
            messageId: 'user.resendEmailVerification.error',
          },
        });
        this.updateState({
          disabledResendButton: false,
          pageLoading: false,
        });
      }
      if (this.props.resendEmailVerification.status === 400) {
        const { errors } = this.state;
        errors.email = <FormattedMessage id="auth.login.invalid" />;
        this.updateState({ disabledButton: false, errors });
      }
      if (this.props.resendEmailVerification.code === 200) {
        this.updateState({
          errors: {},
          disabledResendButton: false,
          showModalNotValidated: false,
          pageLoading: false,
        });
        this.props.showAlert({
          alertBox: {
            alertVisible: true,
            alertClass: 'success',
            messageId: 'user.resendEmailVerification.sussess',
          },
        });
      }
    }

    if (this.props.joinUserMember !== prevProps.joinUserMember) {
      if (this.props.joinUserMember.code === 200) {
        const user = this.props.joinUserMember.data;

        setAuthorizationToken(user.token, user.refreshToken);
        setLocalStorageUser(user, user.token, user.refreshToken);

        if (this.props.loginModal.isOpen)
          this.props.toggleLoginModal({
            isOpen: false,
            isSuccess: true,
            isAdmin: Boolean(isCompanyAdmin(this.state.company.id, user)),
          });

        this.updateState({
          redirect: true,
          disabledButton: false,
        });
        this.props.showAlert({
          alertBox: {
            alertVisible: true,
            alertClass: 'success',
            messageId: 'user.facebook.join.success',
          },
        });
      } else {
        this.updateState({
          redirect: false,
          disabledButton: false,
          pageLoading: false,
        });

        const { errors } = this.state;
        errors.email = translateMessage({
          id: 'user.join.error',
        });
        errors.password = translateMessage({
          id: 'user.join.error',
        });
        this.updateState({ errors });
        this.props.showAlert({
          alertBox: {
            alertVisible: true,
            alertClass: 'danger',
            messageId: 'user.join.error',
          },
        });
      }
    }

    if (this.props.joinUserEmployee !== prevProps.joinUserEmployee) {
      const { errors } = this.state;
      if (this.props.joinUserEmployee.code === 200) {
        const user = this.props.joinUserEmployee.data;

        setAuthorizationToken(user.token, user.refreshToken);
        setLocalStorageUser(user, user.token, user.refreshToken);

        if (this.props.loginModal.isOpen)
          this.props.toggleLoginModal({
            isOpen: false,
            isSuccess: true,
            isAdmin: Boolean(isCompanyAdmin(this.state.company.id, user)),
          });

        this.updateState({
          redirect: true,
          disabledButton: false,
        });
        this.props.showAlert({
          alertBox: {
            alertVisible: true,
            alertClass: 'success',
            messageId: 'user.facebook.join.success',
          },
        });
      } else if (this.props.joinUserEmployee.status === 404) {
        errors.email = translateMessage({ id: 'user.join.error' });
        errors.password = translateMessage({
          id: 'user.join.error',
        });
        this.updateState({
          errors,
          disabledButton: false,
          pageLoading: false,
        });
      } else {
        this.updateState({
          redirect: false,
          disabledButton: false,
          pageLoading: false,
        });
        if (this.props.joinUserEmployee.data.data === 'EMPLOYEE_EMAIL_EXISTS') {
          this.updateState({ showModalBlockedAccount: true });
        } else {
          errors.email = translateMessage({
            id: 'user.join.error.email.exists',
          });
          this.updateState({ errors });
          this.props.showAlert({
            alertBox: {
              alertVisible: true,
              alertClass: 'danger',
              messageId: 'user.join.error',
            },
          });
        }
      }
    }

    if (
      this.state.showModal !== prevState.showModal ||
      this.state.showModalDeactivatedAccount !== prevState.showModalDeactivatedAccount ||
      this.state.showModalBlockedAccount !== prevState.showModalBlockedAccount ||
      this.state.showModalNotValidated !== prevState.showModalNotValidated ||
      this.state.showModalNotRegistred !== prevState.showModalNotRegistred
    ) {
      const modalsArray = [
        this.state.showModal,
        this.state.showModalDeactivatedAccount,
        this.state.showModalBlockedAccount,
        this.state.showModalNotValidated,
        this.state.showModalNotRegistred,
      ];

      const someModalIsOpen = modalsArray.some(modal => modal === true);
      this.props.onChangeDisplay(someModalIsOpen);
    }
  }

  onChange = e => {
    this.updateState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRememberMe = () => {
    this.updateState({
      rememberMe: !this.state.rememberMe,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.isValid()) {
      this.updateState({
        errors: {},
        disabledButton: true,
      });

      this.props.loginPost({
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.rememberMe,
      });
    }
  };

  isValid = () => {
    const { errors, isValid } = validateLoginForm({
      email: this.state.email,
      password: this.state.email,
    });
    if (!isValid) {
      this.updateState({
        errors,
      });

      setTimeout(() => {
        const firstError = document.getElementsByClassName('has-error')[0];
        const input = firstError.getElementsByClassName('form-control')[0];
        if (input != null) {
          input.focus();
        }
      }, 0);
    }
    return isValid;
  };

  closeModal = () => {
    this.updateState({
      showModal: false,
      showModalDeactivatedAccount: false,
      showModalNotValidated: false,
      showModalNotRegistred: false,
      showModalBlockedAccount: false,
    });
  };

  goJoin = () => {
    const { email, password, companyCode, inviteCompany } = this.state;
    let data = {};
    if (companyCode) {
      data = {
        email,
        password,
        create_token: 1,
        company_code: companyCode,
      };
    } else if (inviteCompany) {
      const inviteCompanyLength = inviteCompany.split('-').length;

      data = {
        email,
        password,
        create_token: 1,
        invite_company: _.split(inviteCompany, '-', inviteCompanyLength - 1).join('-'),
      };
    } else if (this.state.invite_whitelabel) {
      data = {
        email,
        password,
        create_token: 1,
        invite_whitelabel: this.state.invite_whitelabel,
      };
    } else {
      data = {
        email,
        password,
        create_token: 1,
      };
    }

    if (companyCode) {
      this.updateState({
        disabledButton: true,
        pageLoading: true,
      });
      this.props.postJoinUserEmployee(data);
    } else if (inviteCompany) {
      this.updateState({
        disabledButton: true,
        pageLoading: true,
      });
      this.props.postJoinUserEmployee(data);
    } else {
      this.updateState({
        showModal: false,
        pageLoading: true,
      });

      this.props.postJoinUserMember(data);
    }
  };

  resendEmail = () => {
    this.props.postResendemailVerification({ email: this.state.email });
    this.updateState({
      disabledResendButton: true,
      showModalNotValidated: false,
      pageLoading: true,
    });
  };

  autoLogin = data => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('rememberMe', data.rememberMe);

    setAuthorizationToken(data.token, data.refreshToken);
    this.props.getUser(data.userId);
  };

  onLoadHandler = () => {
    const iframe = document.getElementById('iframeId-business');

    if (iframe) {
      if (window.addEventListener) {
        window.addEventListener('message', event => {
          const { data } = event;
          if (data.userId) {
            this.autoLogin({
              token: data.token,
              refreshToken: data.refreshToken,
              rememberMe: data.rememberMe,
              userId: data.userId,
            });
          } else {
            this.updateState({ pageLoading: false });
          }
        });
      } else {
        window.attachEvent('onmessage', event => {
          const { data } = event;
          if (data) {
            this.autoLogin({
              token: data.token,
              refreshToken: data.refreshToken,
              rememberMe: data.rememberMe,
              userId: data.userId,
            });
          }
        });
      }
      setTimeout(() => {
        window.removeEventListener('message', () => {});
        window.location.href = '/auth/login';
      }, 5000);
    } else {
      this.updateState({ pageLoading: false });
    }
  };

  updateState(state) {
    this.setState(state);
  }

  render() {
    const allowUsers =
      this.context.initialState?.whitelabel.whiteLabelInfo.data.allow_member_registration;

    const {
      companyCode,
      inviteCompany,
      employee,
      member,
      email,
      password,
      errors,
      pageLoading,
      disabledButton,
      disabledResendButton,
      showModal,
      showModalDeactivatedAccount,
      showModalNotValidated,
      showModalNotRegistred,
      showModalBlockedAccount,
      redirect,
      isLoggedIn,
      windowState,
      rememberMe,
    } = this.state;

    const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en';
    const next_url =
      typeof window !== 'undefined' ? getQueryParams('next_url', window.location.href) : null;

    const subdomain =
      typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('config')).subdomain : '';
    const domain =
      typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('config')).domain : '';

    if (
      (isLoggedIn && !this.context.query?.from) ||
      (isLoggedIn &&
        this.context.query?.from !== `https://${subdomain}` &&
        isLoggedIn &&
        this.context.query?.from !== `https://${domain}`)
    ) {
      if (next_url) window.location.href = next_url;
      else window.location.href = '/';
    }

    if (!this.props.loginModal.isOpen && redirect) {
      if (next_url) window.location.href = next_url;
      else {
        const checkout_step = getQueryParams('checkout_step', window.location.href);

        if (checkout_step) window.location.href = `/${lang}/checkout?checkout_step=2`;
        else window.location.href = `/${localStorage.getItem('lang')}/feed`;
      }
    }

    const href = windowState ? windowState.location.href : '';
    const isCheckoutPage = getUrlVars(href).checkout_step;
    const areEmailAndPasswordFilled = email !== '' && password !== '';

    return (
      <ColorContext.Consumer>
        {color => (
          <div>
            {this.context.query?.from && (
              <iframe
                onLoad={this.onLoadHandler}
                width="0"
                height="0"
                frameBorder="0"
                id="iframeId-business"
                src={`${process.env.NEXT_PUBLIC_ESOLIDAR_BUSINESS_URL}auth/whitelabel-login`}
                title="teste"
              />
            )}
            {pageLoading ? (
              <div style={{ height: '100vh' }}>
                <Loading css="loading-page" />
              </div>
            ) : (
              <section className={isCheckoutPage ? 'container checkout' : 'login-page'}>
                <div className="auth-container">
                  {isCheckoutPage && (
                    <div className="step-progress">
                      <Steps current={1}>
                        <Step />
                        <Step />
                        <Step />
                        <Step />
                        <Step />
                      </Steps>
                    </div>
                  )}
                  {windowState && !isCheckoutPage && (
                    <div className={isCheckoutPage ? 'step-progress' : 'text-center'}>
                      <Helmet>
                        <title>Log in to your account</title>
                      </Helmet>
                      <h1 className="title" style={{ color: color.primaryColor }}>
                        <FormattedMessage
                          id="login.title"
                          values={{
                            value:
                              typeof window !== 'undefined'
                                ? JSON.parse(localStorage.getItem('config')).company.name
                                : '',
                          }}
                        />
                      </h1>
                    </div>
                  )}
                  {getUrlVars(href).email && (
                    <div>
                      <div
                        className="box-note text-center"
                        style={{ padding: '25px', marginBottom: '35px' }}
                      >
                        <FormattedMessage id="join.accounts.note" />
                      </div>
                    </div>
                  )}
                  <div className={isCheckoutPage ? 'pt-60' : ''}>
                    <div className="mx-auto signin-box">
                      {typeof window !== 'undefined'
                        ? window.location.href.includes('checkout_step')
                        : false && (
                            <div>
                              <div className="login-register">
                                <a
                                  className="active"
                                  href={`/${lang}/auth/login${
                                    typeof window !== 'undefined' ? window.location.search : ''
                                  }`}
                                >
                                  <FormattedMessage id="login" />
                                </a>
                                <a
                                  style={{ color: color.primaryColor }}
                                  href={`/${lang}/auth/register${
                                    typeof window !== 'undefined' ? window.location.search : ''
                                  }`}
                                >
                                  <FormattedMessage id="signup" />
                                </a>
                              </div>
                            </div>
                          )}
                      <div className="login-box">
                        {this.props.recoverymessage && (
                          <div className="form-group">
                            <span className="help-block">{this.props.recoverymessage}</span>
                          </div>
                        )}
                        <form onSubmit={this.onSubmit}>
                          <TextField
                            label={translateMessage({ id: 'email' })}
                            onChange={this.onChange}
                            error={errors.email}
                            value={email}
                            field="email"
                            id="email"
                          />
                          <PasswordField
                            style={{ marginBottom: '16px' }}
                            label={translateMessage({ id: 'password' })}
                            onChange={this.onChange}
                            error={errors.password}
                            value={password}
                            field="password"
                            id="password"
                          />
                          <CheckboxField
                            checked={rememberMe}
                            label={translateMessage({ id: 'rememberMe' })}
                            name="rememberMe"
                            onChange={this.handleChangeRememberMe}
                            size="sm"
                          />
                          <div className="action-buttons">
                            <div
                              style={{ width: '100%' }}
                              data-tip={
                                disabledButton || !areEmailAndPasswordFilled
                                  ? translateMessage({
                                      id: 'whitelabel.glossary.tooltip.incomplete-form',
                                    })
                                  : ''
                              }
                            >
                              {(employee === '1' || member === '1') &&
                                email &&
                                (companyCode || inviteCompany || this.state.invite_whitelabel) && (
                                  <Button
                                    extraClass="success-full"
                                    onClick={this.goJoin}
                                    fullWidth={true}
                                    disabled={disabledButton || !areEmailAndPasswordFilled}
                                    text={translateMessage({
                                      id: 'register.modal.join.title',
                                    })}
                                    style={{
                                      backgroundColor: color.primaryColor,
                                      borderColor: color.primaryColor,
                                    }}
                                    size="xl"
                                  />
                                )}
                              {(!employee || !member) &&
                                !companyCode &&
                                !inviteCompany &&
                                !this.state.invite_whitelabel && (
                                  <Button
                                    extraClass="success-full"
                                    type="submit"
                                    fullWidth={true}
                                    disabled={disabledButton || !areEmailAndPasswordFilled}
                                    text={translateMessage({
                                      id: getUrlVars(href).email ? 'continue' : 'login',
                                    })}
                                    style={{
                                      backgroundColor: color.primaryColor,
                                      borderColor: color.primaryColor,
                                    }}
                                    size="xl"
                                  />
                                )}
                            </div>
                          </div>

                          <div className="action-links">
                            <div className="action-links-login">
                              {allowUsers && (
                                <>
                                  <span>
                                    <FormattedMessage id="whitelabel.visitor.login.register" />
                                  </span>

                                  <Button
                                    extraClass="link"
                                    href={`/${lang}/auth/register${
                                      typeof window !== 'undefined' ? window.location.search : ''
                                    }`}
                                    fullWidth={true}
                                    text={translateMessage({ id: 'whitelabel.sign-up' })}
                                    className="pl-2 pr-0"
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <Button
                                extraClass="link"
                                href={`/${lang}/auth/recover-password`}
                                text={translateMessage({ id: 'Login.forgot.password' })}
                                fullWidth={true}
                                className="px-0"
                              />
                            </div>
                          </div>
                          <hr />

                          <div className="action-links">
                            <div className="action-links-login-facebook">
                              <span>
                                <FormattedMessage id="whitelabel.visitor.login.facebook.description" />
                              </span>
                              <Button
                                extraClass="link"
                                href={`/${lang}/auth/configure-password`}
                                text={translateMessage({
                                  id: 'whitelabel.visitor.login.facebook.action',
                                })}
                                fullWidth={true}
                              />
                            </div>
                          </div>
                          {!isCheckoutPage && (
                            <div className="powered-esolidar">Powered by esolidar</div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <CustomModal
              actionsChildren={
                <div className="w-100 d-flex justify-content-center">
                  {!allowUsers ? (
                    <Button
                      extraClass="success-full"
                      onClick={this.closeModal}
                      text={translateMessage({ id: 'gotIt' })}
                      style={{
                        backgroundColor: color.primaryColor,
                        borderColor: color.primaryColor,
                      }}
                    />
                  ) : (
                    <>
                      <Button
                        extraClass="dark mr-1"
                        onClick={this.closeModal}
                        text={translateMessage({ id: 'modal.cancel.text' })}
                      />
                      <Button
                        extraClass="success-full ml-1"
                        onClick={this.goJoin}
                        text={translateMessage({ id: 'register.modal.join.title' })}
                        style={{
                          backgroundColor: color.primaryColor,
                          borderColor: color.primaryColor,
                        }}
                      />
                    </>
                  )}
                </div>
              }
              show={showModal}
              closeButton={false}
              title={translateMessage({ id: 'register.modal.join.blocked' })}
              bodyChildren={
                <p>
                  {translateMessage({
                    id: !allowUsers
                      ? 'login.UNAUTHORIZED_COMMUNITY.text.allowUsers'
                      : 'login.UNAUTHORIZED_COMMUNITY.text',
                  })}
                </p>
              }
            />

            <CustomModal
              actionsChildren={
                <div className="w-100 d-flex justify-content-centerer">
                  <Button
                    extraClass="success-full"
                    href="mailto:info@esolidar.com?subject=UNAUTHORIZED_STATUS"
                    text={translateMessage({ id: 'login.UNAUTHORIZED_STATUS.modal.contactUs' })}
                    style={{
                      backgroundColor: color.primaryColor,
                      borderColor: color.primaryColor,
                    }}
                  />
                </div>
              }
              onHide={this.closeModal}
              show={showModalDeactivatedAccount}
              title={translateMessage({ id: 'register.modal.join.blocked' })}
              bodyChildren={<p>{translateMessage({ id: 'login.UNAUTHORIZED_STATUS.text' })}</p>}
            />

            <CustomModal
              actionsChildren={
                <div className="w-100 d-flex justify-content-center">
                  <Button
                    extraClass="success-full"
                    onClick={this.closeModal}
                    text={translateMessage({ id: 'gotIt' })}
                    style={{
                      backgroundColor: color.primaryColor,
                      borderColor: color.primaryColor,
                    }}
                  />
                </div>
              }
              show={showModalBlockedAccount}
              title={translateMessage({
                id: 'register.modal.join.blocked',
              })}
              bodyChildren={
                <p>
                  {translateMessage({ id: 'login.UNAUTHORIZED_COMMUNITY_MEMBER_STATUS_B.text' })}
                </p>
              }
              closeButton={false}
            />

            <CustomModal
              actionsChildren={
                <>
                  <Button
                    extraClass="success-full"
                    className="mb-4"
                    onClick={this.closeModal}
                    text={translateMessage({ id: 'gotIt' })}
                    style={{
                      backgroundColor: color.primaryColor,
                      borderColor: color.primaryColor,
                    }}
                  />
                  <Button
                    extraClass="link"
                    className="mt-0"
                    onClick={this.resendEmail}
                    text={translateMessage({ id: 'login.modal.validation' })}
                    disabled={disabledResendButton}
                    size="sm"
                    style={{ color: color.primaryColor }}
                  />
                </>
              }
              show={showModalNotValidated}
              title={translateMessage({ id: 'login.UNAUTHORIZED_STATUS_P.modal.title' })}
              bodyChildren={<p>{translateMessage({ id: 'login.UNAUTHORIZED_STATUS_P.text' })}</p>}
              footerClassName="d-flex align-items-center justify-content-center flex-column"
              closeButton={false}
            />

            <CustomModal
              actionsChildren={
                <div className="w-100 d-flex justify-content-center">
                  <Button
                    extraClass="success-full"
                    href={`/${lang}/auth/register`}
                    text={translateMessage({ id: 'signup' })}
                    style={{
                      backgroundColor: color.primaryColor,
                      borderColor: color.primaryColor,
                    }}
                  />
                </div>
              }
              show={showModalNotRegistred}
              onHide={this.closeModal}
              title={translateMessage({ id: 'login.UNAUTHORIZED_FB_ID.modal.title' })}
              bodyChildren={<p>{translateMessage({ id: 'login.UNAUTHORIZED_FB_ID.text' })}</p>}
            />
          </div>
        )}
      </ColorContext.Consumer>
    );
  }
}

FormLogin.propTypes = {
  loginPost: PropTypes.func,
  user: PropTypes.object,
  loginModal: PropTypes.object,
  recoverymessage: PropTypes.bool,
  postJoinUserMember: PropTypes.func,
  showAlert: PropTypes.func,
  postResendemailVerification: PropTypes.func,
  resendEmailVerification: PropTypes.object,
  joinUserMember: PropTypes.object,
  joinUserEmployee: PropTypes.object,
  postJoinUserEmployee: PropTypes.func,
  toggleLoginModal: PropTypes.func,
  onChangeDisplay: PropTypes.func,
  getUser: PropTypes.func,
  userInfo: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    loginModal: state.auth.loginModal,
    resendEmailVerification: state.user.resendEmailVerification,
    joinUserMember: state.members.joinUserMember,
    joinUserEmployee: state.members.joinUserEmployee,
    userInfo: state.user.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAlert,
      loginPost,
      postJoinUserMember,
      postResendemailVerification,
      postJoinUserEmployee,
      toggleLoginModal,
      getUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FormLogin);

FormLogin.defaultProps = {
  onChangeDisplay: () => {},
};
