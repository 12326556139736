import setReducerFromLocalStorage from '../../shared/utils/setReducerFromLocalStorage';
import {
  FETCH_AUTHENTICATION,
  FETCH_RECOVERYPASSWORD,
  POST_FACEBOOK_LOGIN,
  POST_LOGOUT,
  POST_RECOVER_PASSWORD_CODE,
  POST_SET_PASSWORD,
  POST_VERIFY_USER_EMAIL,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_SWITCH_ACCOUNTS_MODAL,
} from '../actions';

const INITIAL_STATE = {
  user: setReducerFromLocalStorage('user'),
  recoveryPassword: {},
  recoverPass: {},
  fbAuth: setReducerFromLocalStorage('user'),
  userLogout: {},
  passwordCode: {},
  setNewPasswordResponse: {},
  verifyEmail: {},
  loginModal: { isOpen: false, nextUrl: undefined },
  switchAccountsModal: { isOpen: false, nextUrl: undefined, title: undefined },
};

export default function reducerAuth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AUTHENTICATION:
      if (action.payload.status === 200) {
        return {
          ...state,
          user: action.payload.data,
          logedin: true,
          shouldRedirect: true,
          feed: {},
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, user: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, user: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, user: action.payload.response };
      }
      break;

    case POST_LOGOUT:
      if (action.payload.status === 200) {
        return {
          ...state,
          userLogout: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userLogout: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userLogout: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userLogout: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userLogout: action.payload.response };
      }
      break;

    case FETCH_RECOVERYPASSWORD:
      if (action.payload.status === 200) {
        return {
          ...state,
          recoverPass: action.payload.data,
          recoveryPassword: true,
        };
      }
      if (action.payload.response.status === 400) {
        return { ...state, recoverPass: action.payload.response.data };
      }
      break;

    case POST_FACEBOOK_LOGIN:
      if (action.payload.status === 200) {
        return {
          ...state,
          fbAuth: action.payload.data,
          feed: {},
        };
      }
      if (action.payload.response.status === 400) {
        return { ...state, fbAuth: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, fbAuth: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, fbAuth: action.payload.response };
      }
      break;

    case POST_RECOVER_PASSWORD_CODE:
      if (action.payload.status === 200) {
        return { ...state, passwordCode: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, passwordCode: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, passwordCode: action.payload.response };
      }
      break;

    case POST_SET_PASSWORD:
      if (action.payload.status === 200) {
        return { ...state, setNewPasswordResponse: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, setNewPasswordResponse: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, setNewPasswordResponse: action.payload.response };
      }
      break;

    case POST_VERIFY_USER_EMAIL:
      if (action.payload.status === 200) {
        return { ...state, verifyEmail: action.payload.data };
      }
      if (action.payload.response.status === 400) {
        return { ...state, verifyEmail: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, verifyEmail: action.payload.response };
      }
      break;

    case TOGGLE_LOGIN_MODAL: {
      const { isAdmin, isSuccess } = action.payload;
      const { nextUrl } = state.loginModal;

      if (isAdmin)
        return {
          ...state,
          switchAccountsModal: {
            isOpen: true,
            nextUrl,
            title: 'welcomeBack',
          },
          loginModal: { isOpen: false, nextUrl: undefined },
        };

      if (INITIAL_STATE.user?.auth_email === 1 && isSuccess && nextUrl)
        window.location.href = nextUrl;

      return { ...state, loginModal: action.payload };
    }

    case TOGGLE_SWITCH_ACCOUNTS_MODAL: {
      const { redirectNextUrl } = action.payload;
      const { nextUrl } = state.switchAccountsModal;

      if (redirectNextUrl && nextUrl) window.location.href = nextUrl;

      return { ...state, switchAccountsModal: action.payload };
    }

    default:
      return state;
  }
}
