import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import cookie from 'react-cookies';
import classnames from 'classnames';
import UserDropdownMenu from '@esolidar/toolkit/build/components/userMenu';
import isCompanyAdmin from '@esolidar/toolkit/build/utils/isCompanyAdmin';
import PropsContext from '../../../contexts/PropsContext';
import setUserCookie from '../../../shared/utils/setUserCookie';
import useUser from '../../../shared/hooks/useUser';
import useIsLoggedIn from '../../../shared/hooks/useIsLoggedIn';
import { toggleSwitchAccountsModal } from '../../../store/actions/authUser';

const UserMenu = () => {
  const propsContext = useContext(PropsContext);
  const dispatch = useDispatch();
  const user = useUser();
  const isLoggedIn = useIsLoggedIn();

  const { company } = propsContext.initialState.whitelabel.whiteLabelInfo.data;

  const { locale } = propsContext;

  const userName = user?.name || '';
  const userThumb = user?.thumbs
    ? user?.thumbs?.thumb
    : `${process.env.NEXT_PUBLIC_cdn_static_url}/frontend/assets/no-image/user.svg`;

  if (isLoggedIn && !cookie.load('user')) setUserCookie(user);

  return (
    <nav
      className={classnames('user-top-menu text-right', {
        'user-top-menu-company': isCompanyAdmin(company?.id, user),
      })}
    >
      <UserDropdownMenu
        companyLogo={userThumb}
        companyName={company?.name}
        items={[
          {
            id: 0,
            title: userName || company?.name,
            onClick: () => {
              dispatch(
                toggleSwitchAccountsModal({
                  isOpen: true,
                  title: 'header.switchAccounts',
                })
              );
            },
            text: 'header.switchAccounts',
            className: 'user-top-menu-option',
            isVisible: Boolean(isCompanyAdmin(company?.id, user)),
          },
          {
            id: 1,
            href: '#',
            isDivider: true,
            isVisible: Boolean(isCompanyAdmin(company?.id, user)),
          },
          {
            id: 2,
            href: `/${locale}/user/settings`,
            text: 'menu.settings',
          },
          {
            id: 3,
            href: '#',
            isDivider: true,
          },
          {
            id: 4,
            href: `/${locale}/privacy`,
            text: 'whitelabel.terms.policy',
          },
          {
            id: 5,
            href: `${process.env.NEXT_PUBLIC_esolidar_help_url}`,
            text: 'header.helpCenter',
          },
          {
            id: 6,
            href: '#',
            isDivider: true,
          },
          {
            id: 7,
            href: `/${locale}/auth/logout`,
            text: 'logout',
          },
        ]}
      />
    </nav>
  );
};

export default UserMenu;
