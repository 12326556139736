import {
  POST_MEMBER_JOIN_FACEBOOK,
  POST_MEMBER_JOIN,
  POST_JOIN_USER_MEMBER,
  POST_JOIN_USER_EMPLOYEE,
} from '../actions';

const INITIAL_STATE = {
  memberJoinFacebook: {},
  memberJoin: null,
  joinUserMember: {},
  joinUserEmployee: {},
};

export default function reducerMembers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_MEMBER_JOIN_FACEBOOK:
      if (action.payload.status === 200) {
        return { ...state, memberJoinFacebook: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, memberJoinFacebook: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, memberJoinFacebook: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, memberJoinFacebook: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, memberJoinFacebook: action.payload.response };
      }
      break;

    case POST_MEMBER_JOIN:
      if (action.payload.status === 200) {
        return { ...state, memberJoin: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, memberJoin: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, memberJoin: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, memberJoin: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, memberJoin: action.payload.response };
      }
      break;

    case POST_JOIN_USER_MEMBER:
      if (action.payload.status === 200) {
        return { ...state, joinUserMember: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, joinUserMember: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, joinUserMember: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, joinUserMember: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, joinUserMember: action.payload.response };
      }
      if (action.payload.response.status === 409) {
        return { ...state, joinUserMember: action.payload.response };
      }
      if (action.payload.response.status === 500) {
        return { ...state, joinUserMember: action.payload.response };
      }
      break;

    case POST_JOIN_USER_EMPLOYEE:
      if (action.payload.status === 200) {
        return { ...state, joinUserEmployee: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, joinUserEmployee: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, joinUserEmployee: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, joinUserEmployee: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, joinUserEmployee: action.payload.response };
      }
      if (action.payload.response.status === 409) {
        return { ...state, joinUserEmployee: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
