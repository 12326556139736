const INITIAL_STATE = {
  alertBox: {
    alertVisible: false,
    alertClass: '',
    messageId: '',
  },
};

export default function reducerAlert(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_ALERT':
      return action.payload;

    default:
  }
  return state;
}
