import {
  FETCH_NOTIFICATIONS,
  POST_NOTIFICATIONS_ALL_AS_READ,
  POST_NOTIFICATION_AS_READ,
} from '../actions';

const INITIAL_STATE = {
  notifications: null,
  readed: null,
  updatedNotification: null,
};

export default function reducerNotifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      if (action.payload.status === 200) {
        const urlString = action.payload.request.responseURL;
        const url = new URL(urlString);
        const readed = url.searchParams.get('readed');
        const { data } = action.payload;
        data.readed = JSON.parse(readed);
        return { ...state, notifications: data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, notifications: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, notifications: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, notifications: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, notifications: action.payload.response };
      }
      break;

    case POST_NOTIFICATIONS_ALL_AS_READ:
      if (action.payload.status === 200) {
        return { ...state, readed: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, readed: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, readed: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, readed: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, readed: action.payload.response };
      }
      break;

    case POST_NOTIFICATION_AS_READ:
      if (action.payload.status === 200) {
        return { ...state, updatedNotification: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updatedNotification: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updatedNotification: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updatedNotification: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updatedNotification: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
