import React, { FC, useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import ErrorBoundary from '@esolidar/toolkit/build/components/errorBoundary';
import ColorContext from '../../../contexts/ColorContext';
import PoweredBy from '../../common/container/PoweredBy';
import SocialButtons from './SocialButtons';
import LanguageSelect from './LanguageSelect';
import CustomFooter from './CustomFooter';

const Footer: FC = (): JSX.Element => {
  const color = useContext(ColorContext);
  const [lang, setLang] = useState('');

  useEffect(() => {
    setLang(localStorage.getItem('lang'));
  });

  return (
    <div className="main-footer">
      <ErrorBoundary>
        <CustomFooter />
      </ErrorBoundary>
      <footer>
        <div className="container">
          <Row>
            <Col
              sm={12}
              lg={5}
              className="text-xs-center text-sm-center text-lg-left mb-xs-2 mb-sm-2 mb-lg-0"
            >
              <div className="menus">
                <div className="copyright-text" style={{ color: color.primaryColor }}>
                  <FormattedMessage
                    id="footer.copyright"
                    values={{ year: new Date().getFullYear() }}
                  />
                </div>
                <a href={`/${lang}/privacy`}>
                  <FormattedMessage id="whitelabel.terms.policy" />
                </a>
                <a href={`/${lang}/cookies`}>
                  <FormattedMessage id="footer.menu.cookies" />
                </a>
              </div>
            </Col>
            <Col sm={12} lg={2} className="text-center mt-mb-xs-d1">
              <PoweredBy className="poweredBy" />
            </Col>
            <Col sm={12} lg={5}>
              <Row className="social">
                <Col sm={7} className="text-xs-center mb-xs-d3 text-sm-left text-lg-right">
                  <ErrorBoundary>
                    <LanguageSelect color={color} />
                  </ErrorBoundary>
                </Col>
                <Col sm={5} className="text-xs-center text-sm-right">
                  <ErrorBoundary>
                    <SocialButtons />
                  </ErrorBoundary>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
