export const uploadFile = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    for (let i = 0; i < value.length; i += 1) {
      newFormData.append(`${key}[]`, value[i]);
    }
  });

  return newFormData;
};

export const uploadImage = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    newFormData.append(`${key}`, value);
  });

  return newFormData;
};

export const uploadFormData = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    if (key === 'admins' || key === 'admins_remove' || key === 'images')
      for (let i = 0; i < value.length; i += 1) {
        newFormData.append(`${key}[]`, value[i]);
      }
    else newFormData.append(key, value);
  });

  return newFormData;
};

export const uploadFeedGallery = data => {
  const newFormData = new FormData();

  data.map((file, i) => {
    newFormData.append('image[]', file);
    newFormData.append('position[]', i + 1);
  });

  return newFormData;
};

export const uploadCrowdfundingImages = data => {
  const newFormData = new FormData();

  Object.keys(data).map((key, index) => {
    const value = data[key];

    newFormData.append('image[]', value);
    newFormData.append('position[]', key);
    if (index === 0) newFormData.append('default[]', 1);
    else newFormData.append('default[]', 0);
  });

  return newFormData;
};

export const uploadImagesReview = (images, requestId, requestCompanyId) => {
  const newFormData = new FormData();

  Object.keys(images).map(key => {
    const value = images[key];

    newFormData.append('image[]', value);
    newFormData.append('position[]', key);
  });
  newFormData.append('request_id', requestId);
  newFormData.append('request_company_id', requestCompanyId);

  return newFormData;
};

export const uploadFilesReview = (files, requestId, requestCompanyId) => {
  const newFormData = new FormData();

  Object.keys(files).map(key => {
    const value = files[key];

    newFormData.append('file[]', value);
    newFormData.append('position[]', key);
  });
  newFormData.append('request_id', requestId);
  newFormData.append('request_company_id', requestCompanyId);

  return newFormData;
};

export const uploadCompanyFile = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    newFormData.append(key, value);
  });

  return newFormData;
};

export const uploadFormCrowdfunding = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    if (key === 'images')
      for (let i = 0; i < value.length; i += 1) {
        newFormData.append(`${key}[]`, value[i]);
      }
    else newFormData.append(key, value);
  });

  return newFormData;
};

export const uploadSettingsFormData = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    if (key === 'image')
      for (let i = 0; i < value.length; i += 1) {
        newFormData.append(key, value[i]);
      }
    else if (value && key === 'interests')
      for (let i = 0; i < value.length; i += 1) {
        newFormData.append('interests[]', value[i]);
      }
    else if (value && key === 'skills')
      for (let i = 0; i < value.length; i += 1) {
        newFormData.append('skills[]', value[i]);
      }
    else newFormData.append(key, value);
  });

  return newFormData;
};

export const uploadProject = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];
    if (key === 'images' || key === 'ods' || key === 'project_categories' || key === 'files')
      // FIXME: what should i send if images array is empty in edit project?
      // if (value.length === 0) newFormData.append(`${key}[]`, []);
      // else
      for (let i = 0; i < value.length; i += 1) {
        newFormData.append(`${key}[]`, value[i]);
      }
    else if (typeof value === 'boolean') newFormData.append(key, Number(value));
    else newFormData.append(key, value);
  });

  return newFormData;
};

export const uploadUserCrowdfundingImages = (data, length, position) => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    newFormData.append('image', value);
    newFormData.append('position', length + position);
    if (length + position === 0) newFormData.append('default', 1);
    else newFormData.append('default', 0);
  });

  return newFormData;
};

export const uploadProjectFile = data => {
  const newFormData = new FormData();

  Object.keys(data).map(key => {
    const value = data[key];

    if (typeof value === 'boolean') newFormData.append(key, Number(value));
    else newFormData.append(key, value);
  });

  return newFormData;
};
