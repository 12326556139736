import {
  FETCH_TICKETS,
  POST_TICKET,
  FETCH_TICKET,
  DELETE_TICKET,
  UPDATE_TICKET,
  POST_COMMENT,
  GET_COMMENTS,
} from '../actions';

const INITIAL_STATE = {
  tickets: null,
  comments: null,
  newComment: null,
  newTicket: null,
  deletedTicket: null,
  updateTicket: null,
  ticketById: {},
};

export default function reducerTickets(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TICKETS:
      if (action.payload.status === 200) {
        return { ...state, tickets: action.payload.data, newTicket: null };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, tickets: action.payload.message, newTicket: null };
      }
      if (action.payload.response.status === 400) {
        return { ...state, tickets: action.payload.response, newTicket: null };
      }
      if (action.payload.response.status === 401) {
        return { ...state, tickets: action.payload.response, newTicket: null };
      }
      if (action.payload.response.status === 404) {
        return { ...state, tickets: action.payload.response, newTicket: null };
      }
      break;

    case POST_TICKET:
      if (action.payload.status === 200) {
        return { ...state, newTicket: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newTicket: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newTicket: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newTicket: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, newTicket: action.payload.response };
      }
      break;

    case DELETE_TICKET:
      if (action.payload.status === 200) {
        return { ...state, deleteTicket: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deleteTicket: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deleteTicket: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deleteTicket: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deleteTicket: action.payload.response };
      }
      break;

    case UPDATE_TICKET:
      if (action.payload.status === 200) {
        return { ...state, updateTicket: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updateTicket: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updateTicket: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updateTicket: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updateTicket: action.payload.response };
      }
      if (action.payload.response.status === 405) {
        return { ...state, updateTicket: action.payload.response };
      }
      break;

    case FETCH_TICKET:
      if (action.payload.status === 200) {
        return { ...state, ticketById: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, ticketById: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, ticketById: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, ticketById: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, ticketById: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, ticketById: action.payload.response };
      }
      break;

    case POST_COMMENT:
      if (action.payload.status === 200) {
        return { ...state, newComment: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newComment: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newComment: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newComment: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, newComment: action.payload.response };
      }
      break;

    case GET_COMMENTS:
      if (action.payload.status === 200) {
        return { ...state, comments: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, comments: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, comments: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, comments: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, comments: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
