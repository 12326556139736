import translateMessage from '../../src/shared/translations/translateMessage';
import getRoute from '../../src/shared/utils/routes';

export const renderMainMenu = (features, locale, router, isVisible) => {
  const items = [];
  if (features) {
    features.map(feature => {
      switch (feature.name) {
        case 'feed':
          items.push({
            order: 0,
            icon: 'Feed',
            text: translateMessage({
              id: 'menu.feed',
            }),
            showNotificationsIcon: false,
            disabled: false,
            isActive: router.asPath === '/feed' || router.asPath === '/',
            href: `/${locale}/feed`,
            isVisible: feature.pivot.isVisible,
          });
          break;

        case 'projects':
          items.push({
            order: 1,
            icon: 'Accelerator',
            text: translateMessage({
              id: 'whitelabel.accelarator.title',
            }),
            showNotificationsIcon: false,
            disabled: false,
            isActive: router.asPath.includes('accelerator') || router.asPath.includes('projects'),
            href: getRoute.private.accelerator.program.LIST(locale),
            isVisible: feature.pivot.isVisible,
          });
          break;

        case 'needs':
          items.push({
            order: 3,
            icon: 'Needs',
            text: translateMessage({
              id: 'whitelabel.header.needs',
            }),
            showNotificationsIcon: false,
            disabled: false,
            isActive:
              router.asPath === '/needs' ||
              router.asPath.includes('crowdfunding') ||
              router.asPath.includes('auction'),
            href: `/${locale}/needs`,
            isVisible: true,
          });
          break;

        case 'volunteering':
          items.push({
            order: 4,
            icon: 'Nonprofit',
            text: translateMessage({
              id: 'request.detail.project.volunteering',
            }),
            showNotificationsIcon: false,
            disabled: false,
            isActive: router.asPath === '/user/request',
            href: `/${locale}/user/request`,
            isVisible,
          });
          break;

        case 'donations':
          items.push({
            order: 5,
            icon: 'Donations',
            text: translateMessage({
              id: 'whitelabel.donations.title',
            }),
            showNotificationsIcon: false,
            disabled: false,
            isActive: router.asPath === '/donations',
            href: `/${locale}/donations`,
          });
          break;

        case 'survey':
          items.push({ order: 5, separator: true, isVisible });
          items.push({
            order: 6,
            icon: 'Surveys',
            text: translateMessage({
              id: 'menu.survey',
            }),
            showNotificationsIcon: false,
            disabled: false,
            isActive: router.asPath === '/user/survey',
            href: `/${locale}/user/survey`,
            isVisible,
          });
          break;

        // case 'documents':
        //   items.push({
        //     order: 7,
        //     icon: 'FileText',
        //     text: translateMessage({
        //       id: 'menu.documents',
        //     }),
        //     showNotificationsIcon: false,
        //     disabled: false,
        //     isActive: router.asPath === '/user/documents',
        //     href: `/${locale}/user/documents`,
        //     isVisible,
        //   });
        //   break;

        default:
      }
    });
  }
  items.sort((a, b) => (a.order > b.order ? 1 : -1));
  // eslint-disable-next-line no-param-reassign
  items.forEach(item => delete item.order);
  return items;
};

export const renderSettingsMenu = (locale, router) => [
  {
    icon: 'UserAccountSeetings',
    text: translateMessage({
      id: 'whitelabel.account.settings',
    }),
    href: `/${locale}/user/settings`,
    showNotificationsIcon: false,
    disabled: false,
    isActive: router.asPath === '/user/settings',
  },
  {
    icon: 'Preferences',
    text: translateMessage({
      id: 'menu.user.settings.preferences',
    }),
    href: `/${locale}/user/settings/preferences`,
    showNotificationsIcon: false,
    disabled: false,
    isActive: router.asPath === '/user/settings/preferences',
  },
  {
    icon: 'Security',
    text: translateMessage({
      id: 'whitelabel.privacy.security',
    }),
    href: `/${locale}/user/settings/privacy`,
    showNotificationsIcon: false,
    disabled: false,
    isActive: router.asPath === '/user/settings/privacy',
  },
  {
    icon: 'CreditCard',
    text: translateMessage({
      id: 'whitelabel.billing.information',
    }),
    href: `/${locale}/user/settings/billing-information`,
    showNotificationsIcon: false,
    disabled: false,
    isActive: router.asPath === '/user/settings/billing-information',
  },
];
