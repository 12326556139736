import { FETCH_LANGUAGES, POST_LANGUAGES } from '../actions';

const INITIAL_STATE = {
  languages: null,
  updatedLang: null,
};

export default function reducerLanguages(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_LANGUAGES:
      if (action.payload.status === 200) {
        return { ...state, languages: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, languages: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, languages: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, languages: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, languages: action.payload.response };
      }
      break;

    case POST_LANGUAGES:
      if (action.payload.status === 200) {
        return { ...state, updatedLang: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updatedLang: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updatedLang: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updatedLang: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updatedLang: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
