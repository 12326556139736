import axios from 'axios';
import {
  POST_MEMBER_JOIN_FACEBOOK,
  POST_MEMBER_JOIN,
  POST_JOIN_USER_MEMBER,
  POST_JOIN_USER_EMPLOYEE,
} from '..';

import ROOT_URL from '../../../shared/constants/apiUrl';

export function postMemberJoinFacebook(data) {
  const request = axios.post(`${ROOT_URL}members/join/facebook`, data);

  return {
    type: POST_MEMBER_JOIN_FACEBOOK,
    payload: request,
  };
}

export function postMemberJoin(data) {
  const request = axios.post(`${ROOT_URL}members/join/facebook`, data);

  return {
    type: POST_MEMBER_JOIN,
    payload: request,
  };
}

export function postJoinUserMember(data) {
  const request = axios.post(`${ROOT_URL}signup/join/members`, data);

  return {
    type: POST_JOIN_USER_MEMBER,
    payload: request,
  };
}

export function postJoinUserEmployee(data) {
  const request = axios.post(`${ROOT_URL}signup/join/employees`, data);

  return {
    type: POST_JOIN_USER_EMPLOYEE,
    payload: request,
  };
}
