import { HYDRATE } from 'next-redux-wrapper';
import {
  GET_WHITELABEL_INFO,
  GET_WHITELABEL_ABOUT,
  GET_WHITELABEL_ABOUT_AS_ADMIN,
} from '../actions';

const INITIAL_STATE = {
  whiteLabelInfo: {},
  whiteLabelAbout: {},
};

export default function reducerWitelabel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.data };

    case GET_WHITELABEL_INFO:
      return {
        ...state,
        whiteLabelInfo: action.payload.data,
      };

    case GET_WHITELABEL_ABOUT:
      if (action.payload.status === 200) {
        return {
          ...state,
          whiteLabelAbout: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, whiteLabelAbout: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, whiteLabelAbout: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, whiteLabelAbout: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, whiteLabelAbout: action.payload.response };
      }
      break;

    case GET_WHITELABEL_ABOUT_AS_ADMIN:
      if (action.payload.status === 200) {
        return {
          ...state,
          whiteLabelAbout: action.payload.data,
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, whiteLabelAbout: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, whiteLabelAbout: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, whiteLabelAbout: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, whiteLabelAbout: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
