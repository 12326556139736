import { FETCH_CURRENCIES } from '../actions';

const INITIAL_STATE = { currencies: null };

export default function reducerCurrencies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CURRENCIES:
      if (action.payload.status === 200) {
        return { ...state, currencies: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, currencies: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, currencies: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, currencies: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, currencies: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
