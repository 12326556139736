import React, { FC, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import isObject from 'lodash/isObject';
import ColorContext from '../../../contexts/ColorContext';
import PropsContext from '../../../contexts/PropsContext';

const CustomFooter: FC = (): JSX.Element => {
  const context = useContext(PropsContext);
  const configData = context.initialState.whitelabel.whiteLabelInfo.data;
  const customFooter = isObject(JSON.parse(configData.data))
    ? JSON.parse(configData.data).customFooter
    : null;

  if (customFooter) {
    const renderItems = () =>
      customFooter.items.map((item, i) => {
        if (item.url) {
          return (
            <a
              key={i}
              href={item.url ? item.url : '#'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ paddingRight: '20px' }}
            >
              <img
                src={`${process.env.NEXT_PUBLIC_server_less_resize_image}/${item.image}?width=160`}
                alt={item.title}
                width="160"
              />
            </a>
          );
        }
        return (
          <img
            key={i}
            src={`${process.env.NEXT_PUBLIC_server_less_resize_image}/${item.image}?width=160`}
            alt={item.title}
            style={{ paddingRight: '20px' }}
            width="160"
          />
        );
      });

    if (customFooter.active === 1) {
      return (
        <ColorContext.Consumer>
          {color => (
            <div className="custom-footer">
              <div className="container">
                <Row>
                  <Col sm={12}>
                    {customFooter.title && (
                      <div
                        style={{
                          paddingRight: '20px',
                          color: color.primaryColor,
                          fontSize: '12px',
                        }}
                      >
                        {customFooter.title}
                      </div>
                    )}
                    {renderItems()}
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ColorContext.Consumer>
      );
    }
  }

  return <div />;
};

export default CustomFooter;
