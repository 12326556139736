import {
  POST_CROWDFUNDING_IMAGES,
  POST_CROWDFUNDING_CAMPAIGN,
  UPDATE_CROWDFUNDING_CAMPAIGN,
  FETCH_CROWDFUNDING_DETAIL,
  POST_CROWDFUNDING_CONTRIBUTE_STATUS,
} from '../actions';

const INITIAL_STATE = {
  crowdfundingImages: null,
  newCrowdfunding: null,
  updatedCrowdfunding: null,
  crowdfundingDetail: null,
  crowdfundingContributeStatus: null,
};

export default function reducerPrivatecrowdfunding(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_CROWDFUNDING_IMAGES:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingImages: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingImages: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingImages: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingImages: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingImages: action.payload.response };
      }
      break;

    case POST_CROWDFUNDING_CAMPAIGN:
      if (action.payload.status === 200) {
        return { ...state, newCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, newCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, newCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, newCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, newCrowdfunding: action.payload.response };
      }
      break;

    case UPDATE_CROWDFUNDING_CAMPAIGN:
      if (action.payload.status === 200) {
        return { ...state, updatedCrowdfunding: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updatedCrowdfunding: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updatedCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updatedCrowdfunding: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updatedCrowdfunding: action.payload.response };
      }
      break;

    case FETCH_CROWDFUNDING_DETAIL:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingDetail: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, crowdfundingDetail: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, crowdfundingDetail: action.payload.response };
      }
      break;

    case POST_CROWDFUNDING_CONTRIBUTE_STATUS:
      if (action.payload.status === 200) {
        return { ...state, crowdfundingContributeStatus: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return {
          ...state,
          crowdfundingContributeStatus: action.payload.message,
        };
      }
      if (action.payload.response.status === 400) {
        return {
          ...state,
          crowdfundingContributeStatus: action.payload.response,
        };
      }
      if (action.payload.response.status === 401) {
        return {
          ...state,
          crowdfundingContributeStatus: action.payload.response,
        };
      }
      if (action.payload.response.status === 404) {
        return {
          ...state,
          crowdfundingContributeStatus: action.payload.response,
        };
      }
      break;

    default:
      return state;
  }
}
