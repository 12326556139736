import axios from 'axios';
import { uploadSettingsFormData } from '../../../shared/utils/formData';
import {
  POST_MOBILE_VALIDATE,
  POST_MOBILE_CONFIRM,
  POST_SIGNUP_MEMBERS,
  POST_SIGNUP_EMPLOYEES,
  POST_VALIDATE_EMAIL,
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  POST_USER_PASSWORD,
  POST_FACEBOOK_TURNOFF,
  POST_FACEBOOK_CONNECT,
  DELETE_USER,
  UPDATE_USER_COMPANY,
  GET_EMPLOYEE,
  POST_VERIFY_EMAIL_FOR_REGISTRATION,
  POST_RESEND_EMAIL_VERIFICATION,
  GET_WHITELABELS_LIST,
  DELETE_WHITELABELS_LIST,
  GET_STRIPE_CREDIT_CARD_LIST,
  POST_STRIPE_CREDIT_CARD,
  PUT_PAYMENT_BANK_TRANSFER,
} from '..';
import ROOT_URL from '../../../shared/constants/apiUrl';

export function mobileValidatePost(userId, props) {
  const request = axios.post(`${ROOT_URL}user/${userId}/mobile-validate`, props);

  return {
    type: POST_MOBILE_VALIDATE,
    payload: request,
  };
}

export function mobileConfirmPost(userId, props) {
  const request = axios.post(`${ROOT_URL}user/${userId}/mobile-confirm`, props);

  return {
    type: POST_MOBILE_CONFIRM,
    payload: request,
  };
}

export function postSignupMembers(data) {
  const request = axios.post(`${ROOT_URL}signup/members`, data);

  return {
    type: POST_SIGNUP_MEMBERS,
    payload: request,
  };
}
export function postSignupEmployees(data) {
  const request = axios.post(`${ROOT_URL}signup/employees`, data);

  return {
    type: POST_SIGNUP_EMPLOYEES,
    payload: request,
  };
}

export function validateUserEmail(email, code) {
  const request = axios.post(`${ROOT_URL}auth/verify-user-email`, {
    code,
    email,
  });

  return {
    type: POST_VALIDATE_EMAIL,
    payload: request,
  };
}

export function getUser(userId) {
  const request = axios.get(`${ROOT_URL}user/${userId}`);

  return {
    type: GET_USER,
    payload: request,
  };
}

export function getEmployee(companyId) {
  const request = axios.get(
    `${ROOT_URL}user/employees${companyId ? `?company_id=${companyId}` : ''}`
  );

  return {
    type: GET_EMPLOYEE,
    payload: request,
  };
}

export function updateUserPost(userId, data) {
  const request = axios.post(`${ROOT_URL}user/${userId}`, uploadSettingsFormData(data));

  return {
    type: UPDATE_USER,
    payload: request,
  };
}

export function updateUserPassword(userId, data) {
  const request = axios.post(`${ROOT_URL}user/${userId}/update-password`, data);

  return {
    type: UPDATE_USER_PASSWORD,
    payload: request,
  };
}

export function createUserPassword(userId, data) {
  const request = axios.post(`${ROOT_URL}user/${userId}/create-password`, data);

  return {
    type: POST_USER_PASSWORD,
    payload: request,
  };
}

export function fbTurnOffPost(userId) {
  const request = axios.post(`${ROOT_URL}user/${userId}/fb-turnoff`, '');

  return {
    type: POST_FACEBOOK_TURNOFF,
    payload: request,
  };
}

export function fbConnectPost(userId, data) {
  const request = axios.post(`${ROOT_URL}user/${userId}/fb-connect`, {
    accessToken: data,
  });

  return {
    type: POST_FACEBOOK_CONNECT,
    payload: request,
  };
}

export function deleteUserPost(userId) {
  const request = axios.delete(`${ROOT_URL}user/${userId}`);

  return {
    type: DELETE_USER,
    payload: request,
  };
}

export function updateUserCompanyPost(userId, companyId, data) {
  const request = axios.post(`${ROOT_URL}employees/${userId}/companies/${companyId}/update`, data);

  return {
    type: UPDATE_USER_COMPANY,
    payload: request,
  };
}

export function postVerifyEmailForRegistration(data) {
  const request = axios.post(`${ROOT_URL}user/verify-email-for-registration`, data);

  return {
    type: POST_VERIFY_EMAIL_FOR_REGISTRATION,
    payload: request,
  };
}

export function postResendemailVerification(data) {
  const request = axios.post(`${ROOT_URL}user/resend-email-verification`, data);

  return {
    type: POST_RESEND_EMAIL_VERIFICATION,
    payload: request,
  };
}

export function getWhitelabelsList() {
  const request = axios.get(`${ROOT_URL}user/members`);

  return {
    type: GET_WHITELABELS_LIST,
    payload: request,
  };
}

export function deleteWhitelabelList(id) {
  const request = axios.delete(`${ROOT_URL}user/members/${id}?id=${id}`);

  return {
    type: DELETE_WHITELABELS_LIST,
    payload: request,
  };
}

export function getStripeCreditCardsList() {
  const request = axios.get(`${ROOT_URL}user/cc-stripe`);

  return {
    type: GET_STRIPE_CREDIT_CARD_LIST,
    payload: request,
  };
}

export function postStripeCreditCards(data) {
  const request = axios.post(`${ROOT_URL}user/cc-stripe`, data);

  return {
    type: POST_STRIPE_CREDIT_CARD,
    payload: request,
  };
}

export function putPaymentBankAccount(userId, data) {
  const request = axios.put(`${ROOT_URL}users/${userId}/payments/bank-transfer`, data);

  return {
    type: PUT_PAYMENT_BANK_TRANSFER,
    payload: request,
  };
}
