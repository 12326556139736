/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import cookie from 'react-cookies';

const ROOT_URL = `${process.env.NEXT_PUBLIC_app_url}${process.env.NEXT_PUBLIC_app_version}/`;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  response => response,
  error => {
    const hasLogin = typeof window !== 'undefined' && !!localStorage.getItem('token');
    const originalRequest = error.config;
    const unauthenticated =
      error.response.status === 401 && error.response.data.data.error === 'Unauthenticated.';

    if (hasLogin && (unauthenticated || error.response.status === 403) && !originalRequest._retry) {
      if (isRefreshing && typeof window !== 'undefined') {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem('refreshToken');
      const rememberMe = localStorage.getItem('rememberMe');
      return new Promise(function (resolve, reject) {
        axios
          .post(`${ROOT_URL}refresh-token`, { refreshToken, rememberMe })
          .then(({ data }) => {
            const { token, refreshToken } = data.data;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            cookie.save('token_whitelabel', token, { path: '/' });
            cookie.save('refreshToken_whitelabel', refreshToken, { path: '/' });
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            originalRequest.headers.Authorization = `Bearer ${token}`;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
            if (unauthenticated && typeof window !== 'undefined') {
              localStorage.setItem('showSessionExpired', '1');
              window.location.href = '/auth/logout?next-url=/auth/login';
            } else if (typeof window === 'undefined') {
              return Promise.reject(error);
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default function async({ dispatch }) {
  return next => action => {
    if (!action.payload || !action.payload.then) {
      return next(action);
    }

    action.payload.then(response => {
      const newAction = { ...action, payload: response };
      dispatch(newAction);
    });
  };
}
