import React, { FC, useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { Navbar, Nav } from 'react-bootstrap';
import MainMenu from './MainMenu';
import NotificationsPage from '../notifications/NotificationsPage';
import UserButtons from './UserButtons';
import PropsContext from '../../../contexts/PropsContext';
import isExceptionPages from '../../../shared/utils/isExceptionPages';

interface Props {
  hideMenu?: boolean;
  hideBtnSignIn?: boolean;
  hideBtnSignUp?: boolean;
  logo: string;
}

const Header: FC<Props> = ({
  logo,
  hideMenu,
  hideBtnSignIn,
  hideBtnSignUp,
}: Props): JSX.Element => {
  const [shadow, setShadow] = useState(null);
  const [openIcon, setOpenIcon] = useState(false);
  const [config, setConfig] = useState(null);
  const propsContext = useContext(PropsContext);
  const { query, token } = propsContext;
  const isLoggedIn = !!token;
  const router = useRouter();
  const isExceptionPage = isExceptionPages(router.pathname);

  useEffect(() => {
    setConfig(typeof window !== 'undefined' ? localStorage.getItem('config') : null);
  }, []);

  useEffect(() => {
    if (shadow) {
      return;
    }

    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('keyup', listenToScroll);
  }, [shadow]);

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;

    if (scrolled > 0 && (!isLoggedIn || isExceptionPage)) {
      setShadow({
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
      });
    } else {
      setShadow(null);
    }
  };

  return (
    <Navbar
      fixed={!isLoggedIn || isExceptionPage ? 'top' : undefined}
      className="header"
      expand="lg"
      style={shadow}
    >
      {!hideMenu && (
        <Navbar.Toggle aria-controls="basic-navbar-nav" label="Menu">
          <div className="open-nav">
            <div
              id="nav-icon3"
              onClick={() => setOpenIcon(!openIcon)}
              onKeyDown={() => setOpenIcon(!openIcon)}
              className={openIcon ? 'open' : ''}
            >
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </Navbar.Toggle>
      )}
      {(!isLoggedIn || isExceptionPage) && (
        <Navbar.Brand href="/">
          <img
            className="styleLogo"
            src={
              logo
                ? `${process.env.NEXT_PUBLIC_cdn_uploads_url}/${logo}`
                : `${process.env.NEXT_PUBLIC_cdn_static_url}/frontend/logo/esolidar/logo.svg`
            }
            width={170}
            height={40}
            alt={config ? JSON.parse(config).company.name : ''}
          />
        </Navbar.Brand>
      )}

      {!hideMenu && (
        <Navbar.Collapse id="basic-navbar-nav" className="collapseAnimation">
          <MainMenu initiatives={config ? JSON.parse(config).initiatives : ''} />
        </Navbar.Collapse>
      )}
      <Nav
        className={
          hideMenu
            ? 'align-items-center justify-content-center ml-auto'
            : 'align-items-center justify-content-center'
        }
      >
        {!query?.from && (
          <>
            {isLoggedIn && <NotificationsPage isNotificationsPage={false} />}
            <UserButtons
              hideBtnSignIn={hideBtnSignIn}
              hideBtnSignUp={hideBtnSignUp}
              isLoggedIn={isLoggedIn}
            />
          </>
        )}
      </Nav>
    </Navbar>
  );
};

export default Header;
