import Head from 'next/head';

const AccessDenied = () => (
  <>
    <Head>
      <title>eSolidar - Compra, Vende & Ajuda (Client not found)</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1"
      />
      <meta
        name="description"
        content="eSolidar é um mercado online onde, ao comprar, vender e licitar, estás a ajudar quem mais precisa. Aqui podes ajudar diferentes instituições de solidariedade."
      />
      <meta
        name="og:description"
        content="eSolidar é um mercado online onde, ao comprar, vender e licitar, estás a ajudar quem mais precisa. Aqui podes ajudar diferentes instituições de solidariedade."
      />
      <meta property="og:title" content="eSolidar - Compra, Vende & Ajuda" />
      <meta property="og:image" content="https://www.esolidar.com/images/fb_esolidar.png" />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="465" />
      <meta property="og:image:alt" content="eSolidar - Compra, Vende & Ajuda" />
      <meta property="og:image:type" content="image/png" />
      <link
        href={`${process.env.NEXT_PUBLIC_cdn_static_url}/frontend/logo/favicon/favicon.png`}
        rel="shortcut icon"
        type="image/vnd.microsoft.icon"
      />
    </Head>
    <div
      className="access-denied"
      style={{
        backgroundImage: `url(${process.env.NEXT_PUBLIC_cdn_static_url}/whitelabel-frontend/assets/disconnected.png)`,
      }}
    >
      <h1>This page is temporarily unavailable!!!!</h1>
      <div className="access-denied-footer">
        <a
          href="https://www.esolidar.com"
          title="Powered by eSolidar"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_cdn_static_url}/frontend/assets/esolidar-powered.png`}
            alt="Powered by eSolidar"
            className="powered-by-img"
          />
        </a>
      </div>
    </div>
  </>
);

export default AccessDenied;
