import { createContext } from 'react';
import variables from '@esolidar/toolkit/build/assets/sass/_export.module.scss';

const defaultValue = {
  primaryColor: variables['theme-colors-primary'],
  secondaryColor: variables['theme-colors-dark-primary'],
  fontFamily: variables['fonts-main'],
};

export default createContext(defaultValue);
