import { HYDRATE } from 'next-redux-wrapper';
import {
  GET_PROJECT_CATEGORIES,
  GET_PROJECT_CONFIG,
  GET_PROJECT_CONFIG_BY_ID,
  UPLOAD_PROJECT_IMAGE,
  DELETE_PROJECT_IMAGE,
  POST_CREATE_PROJECT,
  POST_UPDATE_PROJECT,
  GET_USER_PROJECTS_LIST,
  GET_PROJECTS_DETAIL,
  GET_PUBLIC_PROJECTS_LIST,
} from '../actions';

const INITIAL_STATE = {
  projectCategories: {},
  configProject: {},
  configProjectsById: null,
  projectImage: {},
  deletedProjectImage: {},
  createProject: {},
  updateProject: {},
  userProjects: {},
  userProjectDetail: {},
  publicProjects: {},
};

export default function reducerProjects(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.data };

    case GET_PROJECTS_DETAIL:
      return {
        ...state,
        userProjectDetail: action.payload.data,
      };
    // if (action.payload.status === 200) {
    //   return { ...state, userProjectDetail: action.payload.data };
    // }
    // if (action.payload.message === 'Network Error') {
    //   return { ...state, userProjectDetail: action.payload.message };
    // }
    // if (action.payload.response.status === 400) {
    //   return { ...state, userProjectDetail: action.payload.response };
    // }
    // if (action.payload.response.status === 401) {
    //   return { ...state, userProjectDetail: action.payload.response };
    // }
    // if (action.payload.response.status === 404) {
    //   return { ...state, userProjectDetail: action.payload.response };
    // }
    // break;

    case GET_PROJECT_CATEGORIES:
      if (action.payload.status === 200) {
        return { ...state, projectCategories: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, projectCategories: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, projectCategories: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, projectCategories: action.payload.response };
      }
      break;

    case GET_PROJECT_CONFIG:
      if (action.payload.status === 200) {
        return { ...state, configProject: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, configProject: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, configProject: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, configProject: action.payload.response };
      }
      break;

    case GET_PROJECT_CONFIG_BY_ID:
      if (action.payload.status === 200) {
        return { ...state, configProjectsById: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, configProjectsById: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, configProjectsById: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, configProjectsById: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, configProjectsById: action.payload.response };
      }
      break;

    case UPLOAD_PROJECT_IMAGE:
      if (action.payload.status === 200) {
        return { ...state, projectImage: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, projectImage: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        const urlString = action.payload.response.request.responseURL;
        const url = new URL(urlString);
        const position = url.searchParams.get('position');
        const { data } = action.payload.response;
        data.position = position;
        return { ...state, projectImage: data };
      }
      if (action.payload.response.status === 401) {
        return { ...state, projectImage: action.payload.response };
      }
      break;

    case DELETE_PROJECT_IMAGE:
      if (action.payload.status === 200) {
        return { ...state, deletedProjectImage: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deletedProjectImage: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deletedProjectImage: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deletedProjectImage: action.payload.response };
      }
      break;

    case POST_CREATE_PROJECT:
      if (action.payload.status === 200) {
        return { ...state, createProject: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, createProject: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, createProject: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, createProject: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, createProject: action.payload.response };
      }
      break;

    case POST_UPDATE_PROJECT:
      if (action.payload.status === 200) {
        return { ...state, createProject: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, createProject: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, createProject: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, createProject: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, createProject: action.payload.response };
      }
      break;

    case GET_USER_PROJECTS_LIST:
      if (action.payload.status === 200) {
        return { ...state, userProjects: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userProjects: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userProjects: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userProjects: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userProjects: action.payload.response };
      }
      break;

    case GET_PUBLIC_PROJECTS_LIST:
      if (action.payload.status === 200) {
        return { ...state, publicProjects: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, publicProjects: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, publicProjects: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, publicProjects: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, publicProjects: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
