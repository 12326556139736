/* eslint-disable global-require */
import axios from 'axios';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import cookie from 'react-cookies';

if (typeof window !== 'undefined') {
  window.Pusher = require('pusher-js');
}

export default function setAuthorizationToken(token, refreshToken) {
  if (token) {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    cookie.save('token_whitelabel', token, { path: '/' });
    cookie.save('refreshToken_whitelabel', refreshToken, { path: '/' });

    if (process.env.NEXT_PUBLIC_ENV !== 'production') {
      Pusher.logToConsole = process.env.NEXT_PUBLIC_pusher_console;
    }

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.NEXT_PUBLIC_pusher_key,
      cluster: process.env.NEXT_PUBLIC_pusher_cluster,
      encrypted: true,
      authEndpoint: `${process.env.NEXT_PUBLIC_app_url}broadcasting/auth`,
      auth: {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    });
  } else {
    if (typeof window !== 'undefined') {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.NEXT_PUBLIC_pusher_key,
        cluster: process.env.NEXT_PUBLIC_pusher_cluster,
      });
    }
    delete axios.defaults.headers.common.Authorization;
  }
}
