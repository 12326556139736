import { combineReducers } from 'redux';
import reducerAlert from './reducerAlert';
import reducerAuth from './reducerAuth';
import reducerAuctions from './reducerAuctions';
import reducerCompanies from './reducerCompanies';
import reducerWhitelabel from './reducerWhitelabel';
import reducerFeed from './reducerFeed';
import reducerCountries from './reducerCountries';
import reducerCurrencies from './reducerCurrencies';
import reducerLanguages from './reducerLanguages';
import reducerUser from './reducerUser';
import reducerMembers from './reducerMembers';
import reducerSubscriptions from './reducerSubscriptions';
import reducerProjects from './reducerProjects';
import reducerOds from './reducerOds';
import reducerNotifications from './reducerNotifications';
import reducerCrowdfunding from './reducerCrowdfunding';
import reducerOrders from './reducerOrders';
import reducerGiftCards from './reducerGiftCards';
import reducerInstitutions from './reducerInstitutions';
import reducerSurveys from './reducerSurveys';
import reducerProjectsFiles from './reducerProjectsFiles';
import reducerTickets from './reducerTickets';
import reducerPrivatecrowdfunding from './reducerPrivatecrowdfunding';
import reducerMisc from './reducerMisc';
import reducerSkills from './reducerSkills';
import reducerInterests from './reducerInterests';
import reducerRequests from './reducerRequests';

export const rootReducer = combineReducers({
  alert: reducerAlert,
  auctions: reducerAuctions,
  auth: reducerAuth,
  companies: reducerCompanies,
  whitelabel: reducerWhitelabel,
  feed: reducerFeed,
  countries: reducerCountries,
  currencies: reducerCurrencies,
  languages: reducerLanguages,
  user: reducerUser,
  members: reducerMembers,
  subscription: reducerSubscriptions,
  projects: reducerProjects,
  ods: reducerOds,
  notifications: reducerNotifications,
  crowdfunding: reducerCrowdfunding,
  orders: reducerOrders,
  giftCards: reducerGiftCards,
  institutions: reducerInstitutions,
  surveys: reducerSurveys,
  files: reducerProjectsFiles,
  tickets: reducerTickets,
  privateCrowdfunding: reducerPrivatecrowdfunding,
  misc: reducerMisc,
  skills: reducerSkills,
  interests: reducerInterests,
  requests: reducerRequests,
});

export type RootState = ReturnType<typeof rootReducer>;
