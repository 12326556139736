import { GET_ODS } from '../actions';

const INITIAL_STATE = {
  ods: {},
};

export default function reducerOds(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ODS:
      if (action.payload.status === 200) {
        return { ...state, ods: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, ods: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, ods: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, ods: action.payload.response };
      }

      break;
    default:
      return state;
  }
}
