import { useState, useContext, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '@esolidar/toolkit/build/elements/customModal';
import { toggleLoginModal } from '../../../store/actions/authUser';
import FormLogin from './FormLogin';
import ColorContext from '../../../contexts/ColorContext';
import translateMessage from '../../../shared/translations/translateMessage';
import { RootState } from '../../../store/reducers';

const LoginPage: FC = (): JSX.Element => {
  const [displayNone, setDisplayNone] = useState(false);

  const dispatch = useDispatch();
  const colorContext = useContext(ColorContext);
  const loginModal = useSelector((state: RootState) => {
    return state.auth.loginModal;
  });

  const handleOnHide = () => dispatch(toggleLoginModal({ isOpen: false, nextUrl: undefined }));
  const handleChangeDisplay = value => setDisplayNone(value);

  const companyName =
    typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('config')).company.name : '';

  return (
    <CustomModal
      backdrop={true}
      bodyChildren={<FormLogin onChangeDisplay={handleChangeDisplay} />}
      dialogClassName={`login-modal ${displayNone ? 'd-none' : 'd-block'}`}
      onHide={handleOnHide}
      show={loginModal.isOpen}
      showFooter={false}
      style={{ fontFamily: colorContext.fontFamily }}
      title={
        <div className="custom-title">
          {translateMessage({
            id: 'login.title.modal',
          })}
          <br />
          <span className="login-modal-title">{companyName}</span>
        </div>
      }
    />
  );
};

export default LoginPage;
