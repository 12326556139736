import React, { FC, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { Nav, NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import translateMessage from '../../../shared/translations/translateMessage';
import PropsContext from '../../../contexts/PropsContext';
import isExceptionPages from '../../../shared/utils/isExceptionPages';
import renderMenu from './menus';

interface Props {
  initiatives: initiatives;
}
interface initiatives {
  auctions: boolean;
  crowdfundings: boolean;
  crowdfundingsPrivate: boolean;
  posts: boolean;
  projects: boolean;
}

const MainMenu: FC<Props> = ({ initiatives }: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const router = useRouter();
  const { initialState, locale, token } = useContext(PropsContext);
  const isLoggedIn = !!token;
  const hasProjects = initialState.projects.configProject.data;
  const subscriptions = initialState.whitelabel.whiteLabelInfo.data.company.subscription.features;
  const menus = renderMenu(locale, initiatives, subscriptions, isLoggedIn);
  const isExceptionPage = isExceptionPages(router.pathname);

  if (!hasProjects) {
    const index = subscriptions.findIndex(i => i.name === 'projects');
    if (index >= 0) subscriptions.splice(index, 1);
  }

  const toggleDropdown = () => {
    setShow(!show);
  };

  return (
    <Nav className="ml-auto header-menu">
      {(!isLoggedIn || isExceptionPage) &&
        menus.map((menu, index) => {
          if (menu.show && menu.isFeatureActive && !menu.showSubmenus) {
            return (
              <Nav.Link href={menu.href} key={index} className={menu.active}>
                <FormattedMessage id={menu.id} />
              </Nav.Link>
            );
          }
          if (menu.show && menu.isFeatureActive && menu.showSubmenus) {
            return (
              <NavDropdown
                title={translateMessage({ id: menu.id })}
                key={index}
                className={menu.active}
                id="basic-nav-dropdown-projects"
                show={isMobile ? true : show}
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                {menu.submenus &&
                  menu.submenus.map((submenu, i) => {
                    if (submenu.show) {
                      return (
                        <NavDropdown.Item href={submenu.href} className={submenu.active} key={i}>
                          <FormattedMessage id={submenu.id} />
                        </NavDropdown.Item>
                      );
                    }
                  })}
              </NavDropdown>
            );
          }
        })}
    </Nav>
  );
};

export default MainMenu;
