import axios from 'axios';
import {
  FETCH_AUTHENTICATION,
  FETCH_RECOVERYPASSWORD,
  POST_FACEBOOK_LOGIN,
  POST_LOGOUT,
  POST_RECOVER_PASSWORD_CODE,
  POST_SET_PASSWORD,
  POST_VERIFY_USER_EMAIL,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_SWITCH_ACCOUNTS_MODAL,
} from '..';

import ROOT_URL from '../../../shared/constants/apiUrl';

export function loginPost(props) {
  const request = axios.post(`${ROOT_URL}auth/whitelabel`, props);

  return {
    type: FETCH_AUTHENTICATION,
    payload: request,
  };
}

export function logoutPost(props) {
  const request = axios.post(`${ROOT_URL}auth/logout`, props);

  return {
    type: POST_LOGOUT,
    payload: request,
  };
}

export function recoveryPost(props, template) {
  let params = '';
  if (template) params = `?template=${template}`;

  const request = axios.post(`${ROOT_URL}auth/recover-password${params}`, props);

  return {
    type: FETCH_RECOVERYPASSWORD,
    payload: request,
  };
}

export function facebookLogin(data) {
  const request = axios.post(`${ROOT_URL}auth/whitelabel/facebook`, data);

  return {
    type: POST_FACEBOOK_LOGIN,
    payload: request,
  };
}

export function recoverPasswordCode(data) {
  const request = axios.post(`${ROOT_URL}auth/recover-password/verify-code`, data);

  return {
    type: POST_RECOVER_PASSWORD_CODE,
    payload: request,
  };
}

export function setNewPassword(data) {
  const request = axios.post(`${ROOT_URL}auth/recover-password/set-password`, data);

  return {
    type: POST_SET_PASSWORD,
    payload: request,
  };
}

export function postVerifyUserEmail(data) {
  const request = axios.post(`${ROOT_URL}auth/verify-user-email`, data);

  return {
    type: POST_VERIFY_USER_EMAIL,
    payload: request,
  };
}

export function toggleLoginModal({
  isOpen,
  nextUrl = undefined,
  isSuccess = undefined,
  isAdmin = undefined,
}) {
  return {
    type: TOGGLE_LOGIN_MODAL,
    payload: { isOpen, nextUrl, isSuccess, isAdmin },
  };
}

export function toggleSwitchAccountsModal({
  isOpen,
  redirectNextUrl = undefined,
  title = undefined,
}) {
  return {
    type: TOGGLE_SWITCH_ACCOUNTS_MODAL,
    payload: { isOpen, redirectNextUrl, title },
  };
}
