import { HYDRATE } from 'next-redux-wrapper';
import { GET_SUBSCRIPTION_BY_ID } from '../actions';

const INITIAL_STATE = {
  subscriptionById: {},
};

export default function reducerSubscriptions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.data };

    case GET_SUBSCRIPTION_BY_ID:
      return {
        ...state,
        subscriptionById: action.payload.data,
      };

    // if (action.payload.status === 200) {
    //   return { ...state, subscriptionById: action.payload.data };
    // }
    // if (action.payload.message === 'Network Error') {
    //   return { ...state, subscriptionById: action.payload.message };
    // }
    // if (action.payload.response.status === 400) {
    //   return { ...state, subscriptionById: action.payload.response };
    // }
    // if (action.payload.response.status === 401) {
    //   return { ...state, subscriptionById: action.payload.response };
    // }
    // if (action.payload.response.status === 404) {
    //   return { ...state, subscriptionById: action.payload.response };
    // }
    // break;

    default:
      return state;
  }
}
