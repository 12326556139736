import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const serverErrorsRegex = new RegExp(
  `401 Unauthorized|Request failed with status code 403|Request failed with status code 404|502 Bad Gateway|503 Service Unavailable`,
  'mi'
);

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  ignoreErrors: [serverErrorsRegex],
});
