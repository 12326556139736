/* eslint-disable global-require */
import { SUPPORTED_LOCALES } from '../constants/locales';

const getLocaleTranslations = lang => {
  switch (lang) {
    case SUPPORTED_LOCALES.BR:
      return Object.assign(
        require('@esolidar/i18n/projects/toolkit/br'),
        require('@esolidar/i18n/projects/crowdfunding/br'),
        require('@esolidar/i18n/projects/feed/br'),
        require('@esolidar/i18n/projects/whitelabel/br')
      );
    case SUPPORTED_LOCALES.EN:
      return Object.assign(
        require('@esolidar/i18n/projects/toolkit/en'),
        require('@esolidar/i18n/projects/crowdfunding/en'),
        require('@esolidar/i18n/projects/feed/en'),
        require('@esolidar/i18n/projects/whitelabel/en')
      );
    case SUPPORTED_LOCALES.PT:
      return Object.assign(
        require('@esolidar/i18n/projects/toolkit/pt'),
        require('@esolidar/i18n/projects/crowdfunding/pt'),
        require('@esolidar/i18n/projects/feed/pt'),
        require('@esolidar/i18n/projects/whitelabel/pt')
      );
    default:
  }
};
export default getLocaleTranslations;
