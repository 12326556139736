/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PropTypes from 'prop-types';
import axios from 'axios';
import Echo from 'laravel-echo';
import * as Sentry from '@sentry/nextjs';
import { loadReCaptcha } from 'react-recaptcha-google';
import cookie from 'react-cookies';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import { IntlProvider } from 'react-intl';
import { storeWrapper } from '../src/store';
import { getWhiteLabel } from '../src/store/actions/whitelabel';
import { getProjectConfig, getServerProjectConfigById } from '../src/store/actions/projects';
import Layout from '../components/layout';
import AccessDenied from '../src/components/views/AccessDenied';
import PropsContext from '../src/contexts/PropsContext';
import { SUPPORTED_LOCALES } from '../src/shared/constants/locales';
import getLocaleTranslations from '../src/shared/utils/locales';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.scss';
import Theme from '../src/style/Theme';

function MyApp({ Component, pageProps, store, forbidden, contextProps }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const localeProps = contextProps.locale;
  const locale = useSelector(state => state.misc.locale) || localeProps;

  useEffect(() => {
    cookie.save('NEXT_LOCALE', locale, { path: '/' });

    if (localStorage.getItem('user')) {
      Sentry.configureScope(scope => {
        scope.setUser({ user: JSON.parse(localStorage.getItem('user')) });
      });
    }
  }, []);

  // if (process.env.NEXT_PUBLIC_ENV === 'development') {
  //   const whyDidYouRender = require('@welldone-software/why-did-you-render');
  //   whyDidYouRender(React, {
  //     trackAllPureComponents: true,
  //   });
  // }

  if (locale) {
    switch (locale) {
      case 'pt':
        moment.locale('pt');
        break;
      case 'br':
        moment.locale('pt-br');
        break;
      case 'en':
        moment.locale('en-gb');
        break;
      default:
        moment.locale('en-gb');
    }
  }

  if (typeof window !== 'undefined') {
    loadReCaptcha();
    if (!contextProps.token) {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.NEXT_PUBLIC_pusher_key,
        cluster: process.env.NEXT_PUBLIC_pusher_cluster,
        encrypted: true,
      });
    }
  }

  if (forbidden) return <AccessDenied />;

  if (typeof window !== 'undefined') {
    localStorage.setItem(
      'config',
      JSON.stringify(store.initialState.whitelabel.whiteLabelInfo.data)
    );
    localStorage.setItem('lang', locale);
  }

  // eslint-disable-next-line no-nested-ternary
  const messages =
    locale === 'pt'
      ? getLocaleTranslations(SUPPORTED_LOCALES.PT)
      : locale === 'br'
      ? getLocaleTranslations(SUPPORTED_LOCALES.BR)
      : getLocaleTranslations(SUPPORTED_LOCALES.EN);

  const defaultRichTextElements = {
    br: <br />,
    b: chunks => <b>{chunks}</b>,
    p: chunks => <p>{chunks}</p>,
    i: chunks => <i>{chunks}</i>,
    strong: chunks => <strong>{chunks}</strong>,
  };

  return (
    <PropsContext.Provider value={{ ...contextProps, ...store }}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider
          locale={locale}
          messages={messages}
          defaultRichTextElements={defaultRichTextElements}
        >
          <Theme>
            <Layout
              config={store.initialState.whitelabel.whiteLabelInfo.data}
              subscription={
                store.initialState.whitelabel.whiteLabelInfo.data.company.subscription.features
              }
              contextProps={contextProps}
            >
              <Component {...pageProps} {...contextProps} {...store} />
            </Layout>
            {process.env.NEXT_PUBLIC_ENV === 'development' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </Theme>
        </IntlProvider>
      </QueryClientProvider>
    </PropsContext.Provider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.any,
  contextProps: PropTypes.shape({
    locale: PropTypes.string,
    token: PropTypes.string,
    error: PropTypes.any,
  }),
  forbidden: PropTypes.bool,
  host: PropTypes.string,
  pageProps: PropTypes.object,
  store: PropTypes.object,
};

MyApp.getInitialProps = async props => {
  const { ctx } = props;
  const origin = ctx.req.headers['x-forwarded-host'] || ctx.req.headers.host;
  const localeId = ctx.locale;
  const { user, sidebar_collapsed } = ctx.req.cookies;

  const regex = new RegExp('(?:[0-9]{1,3}.){3}[0-9]{1,3}.*');
  const isValidOrigin = origin?.match(regex);

  if (!origin || !!isValidOrigin)
    return {
      forbidden: true,
      contextProps: {
        error: 'no origin',
        host: origin,
        locale: localeId,
        query: ctx.query,
        token: '',
      },
    };

  axios.defaults.headers.origin = `https://${origin}`;

  const { token_whitelabel } = ctx.req.cookies;
  if (token_whitelabel) axios.defaults.headers.common.Authorization = `Bearer ${token_whitelabel}`;

  try {
    const whitelabelInfo = await getWhiteLabel();
    ctx.store.dispatch(whitelabelInfo);
  } catch (error) {
    if (error) {
      Sentry.captureException(error);

      return {
        forbidden: true,
        contextProps: {
          host: origin,
          error,
          locale: localeId,
          query: ctx.query,
          token: '',
          user: user ? JSON.parse(Buffer.from(user, 'base64').toString()) : {},
        },
      };
    }
  }

  try {
    const { data } = ctx.store.getState().whitelabel.whiteLabelInfo;
    const whitelabelId = data.id;
    const { features } = data.company.subscription;
    const hasProjects = features.find(f => f.name === 'projects');

    if (hasProjects) {
      const projectConfig = await getProjectConfig(whitelabelId);
      ctx.store.dispatch(projectConfig);
    }

    if (
      props.ctx.pathname === '/accelerator/[id]' ||
      props.ctx.pathname === '/accelerator/detail/[id]' ||
      props.ctx.pathname === '/accelerator/[id]/projects' ||
      props.ctx.pathname === '/user/accelerator/[id]' ||
      props.ctx.pathname === '/user/accelerator/[id]/projects/[projectId]' ||
      props.ctx.pathname === '/accelerator/[id]/projects/[projectId]' ||
      props.ctx.pathname === '/accelerator/[id]/projects/[projectId]/initiatives' ||
      props.ctx.pathname === '/user/accelerator/[id]/projects/[projectId]/messages' ||
      props.ctx.pathname === '/user/accelerator/[id]/projects/[projectId]/messages/[messageId]' ||
      props.ctx.pathname === '/accelerator/[id]/projects/[projectId]/messages' ||
      props.ctx.pathname === '/accelerator/[id]/projects/[projectId]/messages/[messageId]'
    ) {
      const queryId = ctx.query.id;
      const projects = await getServerProjectConfigById(whitelabelId, queryId);
      ctx.store.dispatch(projects);
    }
  } catch (error) {
    if (error) {
      Sentry.captureException(error);
    }
  }

  const store = ctx.store.getState();
  axios.defaults.headers.common['X-Esolidar-Configuration'] =
    store.whitelabel.whiteLabelInfo.data.company.id;

  ctx.store.dispatch({ type: 'LOCALE', payload: localeId });

  const userAgent = ctx.req.headers['user-agent'];
  const isMobile = Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
  );

  return {
    store: { initialState: ctx.store.getState() },
    contextProps: {
      locale: localeId,
      query: ctx.query,
      pathname: ctx.asPath,
      host: origin,
      token: token_whitelabel || '',
      user: user ? JSON.parse(Buffer.from(user, 'base64').toString()) : {},
      sidebarCollapsed: sidebar_collapsed,
      isMobile,
    },
  };
};

export default storeWrapper.withRedux(MyApp);
