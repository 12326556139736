/* eslint-disable max-len */

import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropsContext from '../../src/contexts/PropsContext';

const CookiesBox = props => {
  const context = useContext(PropsContext);
  const { locale } = context;
  return (
    <div className="cookies-message">
      <Row>
        <Col sm={10}>
          <FormattedMessage id="user.cookies.message" />
          &nbsp;
          <a href={`/${locale}/cookies`} style={{ color: props.config.data.primaryColor }}>
            <FormattedMessage id="user.cookies.message.learn.more" />
          </a>
        </Col>
        <Col sm={2} className="text-center">
          <button onClick={props.hideCookiesMessage} className="close-cookies">
            <FormattedMessage id="user.cookies.message.agree" />
          </button>
        </Col>
      </Row>
    </div>
  );
};

CookiesBox.propTypes = {
  config: PropTypes.object,
  hideCookiesMessage: PropTypes.func,
};

export default CookiesBox;
