import axios from 'axios';

const ROOT_URL = `${process.env.NEXT_PUBLIC_app_url}${process.env.NEXT_PUBLIC_app_version}/`;

export const GET_INTERESTS = 'GET_INTERESTS';

export function getInterests() {
  const request = axios.get(`${ROOT_URL}interests`);

  return {
    type: GET_INTERESTS,
    payload: request,
  };
}
