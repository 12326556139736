/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import isDefined from '@esolidar/toolkit/build/utils/isDefined';
import getLocaleTranslations from '../utils/locales';
import { store } from '../../store';
import { DEFAULT_LOCALE } from '../constants/locales';

const translateMessage = ({ id, values = {} }) => {
  const lang =
    typeof window !== 'undefined'
      ? localStorage.getItem('lang') || DEFAULT_LOCALE
      : store.getState().misc.serverLocale;

  const hasValues = Object.keys(values).length;
  const translations = getLocaleTranslations(lang);
  let text = translations[id] || id;

  if (hasValues && isDefined(text)) {
    const newValues = {};

    for (const [key, value] of Object.entries(values)) {
      newValues[`{${key}}`] = value;
    }

    const regExp = new RegExp(Object.keys(newValues).join('|'), 'gi');
    text = text.replace(regExp, matched => newValues[matched]);
  }

  return text;
};

export default translateMessage;
