import { POST_ORDER, GET_SIBS_CHECKOUT_STATUS, GET_STATUS_PAYMENT } from '../actions';

const INITIAL_STATE = {
  order: null,
  orderStatus: null,
  paymentStatus: null,
};

export default function reducerOrders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_ORDER:
      if (action.payload.status === 200) {
        return { ...state, order: action.payload.data };
      }
      return { ...state, order: action.payload.response };

    case GET_SIBS_CHECKOUT_STATUS:
      if (action.payload.status === 200) {
        return { ...state, orderStatus: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, orderStatus: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, orderStatus: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, orderStatus: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, orderStatus: action.payload.response };
      }
      break;

    case GET_STATUS_PAYMENT:
      if (action.payload.status === 200) {
        return { ...state, paymentStatus: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, paymentStatus: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, paymentStatus: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, paymentStatus: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, paymentStatus: action.payload.response };
      }
      break;

    default:
      return state;
  }
}
