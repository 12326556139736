import cookie from 'react-cookies';

export default function setUserCookie(user) {
  const userCookie = Buffer.from(
    JSON.stringify({
      id: user.id,
      email: user.email,
      name: user.name,
      thumbs: user.thumbs,
      work_email: user.work_email,
    })
  ).toString('base64');

  cookie.save('user', userCookie, {
    path: '/',
  });
}
