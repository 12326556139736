import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cookie from 'react-cookies';
import { Dropdown } from 'react-bootstrap';
import Icon from '@esolidar/toolkit/build/components/icon';
import PropsContext from '../../../contexts/PropsContext';

interface Props {
  color: color;
}
interface color {
  primaryColor: string;
}

const locales = [
  { id: 'pt', name: 'Português' },
  { id: 'br', name: 'Português (BR)' },
  { id: 'en', name: 'English' },
];

const LanguageSelect: FC<Props> = ({ color }: Props): JSX.Element => {
  const context = useContext(PropsContext);
  const [activeLocale, setActiveLocale] = useState('');

  const locale = useSelector((state: any) => state.misc.locale);

  useEffect(() => {
    locales.map(({ id, name }) => {
      if (locale === id) setActiveLocale(name);
    });
  });

  const handleChange = useCallback(id => {
    cookie.save('NEXT_LOCALE', id, { path: '/' });
    window.location.href = `/${id}${context.pathname}`;
  }, []);

  return (
    <div className="change-language">
      <Icon iconClass="icon-language" />
      <Dropdown drop="up">
        <Dropdown.Toggle as="div" id="dropdown-1">
          {activeLocale}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {locales.map(({ id, name }) => {
            return (
              <Dropdown.Item
                key={id}
                as="div"
                eventKey={id}
                onClick={() => handleChange(id)}
                style={{
                  color: context.locale === id ? color.primaryColor : '',
                  textDecoration: context.locale === id ? 'underline' : 'none',
                }}
              >
                {name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguageSelect;
