import setReducerFromLocalStorage from '../../shared/utils/setReducerFromLocalStorage';
import getUrlVars from '../../shared/utils/getUrlVars';
import setUserCookie from '../../shared/utils/setUserCookie';

import {
  POST_SIGNUP_MEMBERS,
  POST_SIGNUP_EMPLOYEES,
  POST_MOBILE_VALIDATE,
  POST_MOBILE_CONFIRM,
  POST_VALIDATE_EMAIL,
  GET_USER,
  GET_EMPLOYEE,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  POST_USER_PASSWORD,
  POST_FACEBOOK_TURNOFF,
  POST_FACEBOOK_CONNECT,
  DELETE_USER,
  UPDATE_USER_COMPANY,
  POST_VERIFY_EMAIL_FOR_REGISTRATION,
  POST_RESEND_EMAIL_VERIFICATION,
  GET_WHITELABELS_LIST,
  DELETE_WHITELABELS_LIST,
  GET_STRIPE_CREDIT_CARD_LIST,
  POST_STRIPE_CREDIT_CARD,
  PUT_PAYMENT_BANK_TRANSFER,
} from '../actions';

const INITIAL_STATE = {
  signupMembers: null,
  validatePhone: {},
  confirmPhone: {},
  emailValid: {},
  userInfo: { data: setReducerFromLocalStorage('user') },
  updatedUser: {},
  userUpdatePassword: {},
  userAddPassword: {},
  turnOffFb: {},
  fbConnect: {},
  deleteUser: {},
  updatedUserCompany: {},
  emailRegistration: null,
  resendEmailVerification: {},
  whitelabelsList: {},
  deletedWhitelabelsList: {},
  creditcardList: {},
  creditcard: {},
  paymentBankTransfer: {},
};

export default function reducerUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_SIGNUP_MEMBERS:
    case POST_SIGNUP_EMPLOYEES:
      if (action.payload.status === 200) {
        return {
          ...state,
          signupMembers: action.payload.data,
          shouldRedirect: true,
        };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, signupMembers: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, signupMembers: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, signupMembers: action.payload.response };
      }
      if (action.payload.response.status === 409) {
        return { ...state, signupMembers: action.payload.response };
      }
      if (action.error === true) {
        return { ...state, signupMembers: action.payload.response };
      }
      break;

    case POST_MOBILE_VALIDATE:
      if (action.payload.status === 200) {
        return { ...state, validatePhone: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, validatePhone: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, validatePhone: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, validatePhone: action.payload.response };
      }
      break;

    case POST_MOBILE_CONFIRM:
      if (action.payload.status === 200) {
        return { ...state, confirmPhone: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, confirmPhone: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, confirmPhone: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, confirmPhone: action.payload.response };
      }
      break;

    case POST_VALIDATE_EMAIL:
      if (action.payload.status === 200) {
        return { ...state, emailValid: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, emailValid: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, emailValid: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, emailValid: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, emailValid: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, emailValid: action.payload.response };
      }
      break;

    case GET_USER:
      if (action.payload.status === 200) {
        return { ...state, userInfo: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userInfo: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userInfo: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userInfo: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, userInfo: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userInfo: action.payload.response };
      }
      break;

    case GET_EMPLOYEE:
      if (action.payload.status === 200) {
        return { ...state, employee: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, employee: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, employee: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, employee: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, employee: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, employee: action.payload.response };
      }
      break;

    case UPDATE_USER:
      if (action.payload.status === 200) {
        localStorage.setItem('user', JSON.stringify(action.payload.data.data));
        setUserCookie(action.payload.data.data);
        return { ...state, updatedUser: action.payload.data, userInfo: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updatedUser: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updatedUser: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updatedUser: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, updatedUser: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updatedUser: action.payload.response };
      }
      break;

    case UPDATE_USER_PASSWORD:
      if (action.payload.status === 200) {
        return { ...state, userUpdatePassword: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userUpdatePassword: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userUpdatePassword: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userUpdatePassword: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, userUpdatePassword: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userUpdatePassword: action.payload.response };
      }
      break;

    case POST_USER_PASSWORD:
      if (action.payload.status === 200) {
        return { ...state, userAddPassword: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, userAddPassword: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, userAddPassword: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, userAddPassword: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, userAddPassword: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, userAddPassword: action.payload.response };
      }
      break;

    case POST_FACEBOOK_TURNOFF:
      if (action.payload.status === 200) {
        return { ...state, turnOffFb: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, turnOffFb: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, turnOffFb: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, turnOffFb: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, turnOffFb: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, turnOffFb: action.payload.response };
      }
      break;

    case POST_FACEBOOK_CONNECT:
      if (action.payload.status === 200) {
        return { ...state, fbConnect: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, fbConnect: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, fbConnect: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, fbConnect: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, fbConnect: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, fbConnect: action.payload.response };
      }
      break;

    case DELETE_USER:
      if (action.payload.status === 200) {
        return { ...state, deleteUser: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deleteUser: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deleteUser: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deleteUser: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, deleteUser: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deleteUser: action.payload.response };
      }
      break;

    case UPDATE_USER_COMPANY:
      if (action.payload.status === 200) {
        return { ...state, updatedUserCompany: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, updatedUserCompany: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, updatedUserCompany: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, updatedUserCompany: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, updatedUserCompany: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, updatedUserCompany: action.payload.response };
      }
      break;

    case POST_VERIFY_EMAIL_FOR_REGISTRATION:
      if (action.payload.status === 200) {
        return { ...state, emailRegistration: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, emailRegistration: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, emailRegistration: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, emailRegistration: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, emailRegistration: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, emailRegistration: action.payload.response };
      }
      break;

    case POST_RESEND_EMAIL_VERIFICATION:
      if (action.payload.status === 200) {
        return { ...state, resendEmailVerification: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, resendEmailVerification: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, resendEmailVerification: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, resendEmailVerification: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, resendEmailVerification: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, resendEmailVerification: action.payload.response };
      }
      break;

    case GET_WHITELABELS_LIST:
      if (action.payload.status === 200) {
        return { ...state, whitelabelsList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, whitelabelsList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, whitelabelsList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, whitelabelsList: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, whitelabelsList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, whitelabelsList: action.payload.response };
      }
      break;

    case DELETE_WHITELABELS_LIST:
      if (action.payload.status === 200) {
        const paramId = getUrlVars(action.payload.request.responseURL).id;
        const { data } = action.payload;
        data.memberId = paramId;
        return { ...state, deletedWhitelabelsList: data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, deletedWhitelabelsList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, deletedWhitelabelsList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, deletedWhitelabelsList: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, deletedWhitelabelsList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, deletedWhitelabelsList: action.payload.response };
      }
      break;

    case GET_STRIPE_CREDIT_CARD_LIST:
      if (action.payload.status === 200) {
        return { ...state, creditcardList: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, creditcardList: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, creditcardList: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, creditcardList: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, creditcardList: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, creditcardList: action.payload.response };
      }
      break;

    case POST_STRIPE_CREDIT_CARD:
      if (action.payload.status === 200) {
        return { ...state, creditcard: action.payload.data };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, creditcard: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, creditcard: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, creditcard: action.payload.response };
      }
      if (action.payload.response.status === 403) {
        return { ...state, creditcard: action.payload.response };
      }
      if (action.payload.response.status === 404) {
        return { ...state, creditcard: action.payload.response };
      }
      break;

    case PUT_PAYMENT_BANK_TRANSFER:
      if (action.payload.status === 200) {
        return { ...state, paymentBankTransfer: action.payload.data };
      }
      return { ...state, paymentBankTransfer: action.payload.response };

    default:
      return state;
  }
}
