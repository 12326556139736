import { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import ToolkitSidebar from '@esolidar/toolkit/build/components/sidebar';
import PropsContext from '../../src/contexts/PropsContext';
import useUser from '../../src/shared/hooks/useUser';
import useCompany from '../../src/shared/hooks/useCompany';
import useSubscriptions from '../../src/shared/hooks/useSubscriptions';
import translateMessage from '../../src/shared/translations/translateMessage';
import { renderSettingsMenu, renderMainMenu } from './renderMenus';

const Sidebar = () => {
  const [mainMenuItems, setMainMenuItems] = useState([]);
  const intl = useIntl();
  const router = useRouter();
  const context = useContext(PropsContext);
  const user = useUser();
  const company = useCompany();
  const subscriptions = useSubscriptions();
  const { sidebarCollapsed, isMobile } = context;
  const { id, logo, acronym } = company;
  let isVisible = false;
  if (company) {
    isVisible = !!user.work_email?.find(item => item.company_id === id);
  }
  const hasNeeds = !!subscriptions.find(
    feature =>
      feature.name === 'auctions' ||
      feature.name === 'crowdfunding' ||
      feature.name === 'crowdfunding-public'
  );
  const hasDonations = !!subscriptions.find(
    feature => feature.name === 'matchdonation' || feature.name === 'giftcards'
  );
  const allSubscriptions = [...subscriptions];

  if (hasNeeds) {
    allSubscriptions.push({
      name: 'needs',
    });
  }
  if (hasDonations) {
    allSubscriptions.push({
      name: 'donations',
    });
  }

  useEffect(() => {
    setMainMenuItems(renderMainMenu(allSubscriptions, intl.locale, router, isVisible));
  }, [intl]);

  return (
    <ToolkitSidebar
      collapsed={isMobile || !!sidebarCollapsed}
      companyInfo={{
        logo,
        name: acronym,
        esolidarLogo: `${process.env.NEXT_PUBLIC_cdn_static_url}/frontend/logo/esolidar/logo-xsmall.svg`,
      }}
      mainMenu={mainMenuItems}
      bottomMenu={[
        {
          icon: 'Settings',
          text: translateMessage({
            id: 'menu.settings',
          }),
          href: '#',
          showNotificationsIcon: false,
          isActive:
            router.asPath === '/user/settings' ||
            router.asPath === '/user/settings/preferences' ||
            router.asPath === '/user/settings/privacy' ||
            router.asPath === '/user/settings/billing-information',
          submenu: renderSettingsMenu(intl.locale, router),
          keepSubMenuOpen: !isMobile ? router.asPath.includes('settings') : false,
        },
      ]}
    />
  );
};

export default Sidebar;
