import { GET_SKILLS } from '../actions/skills';

const INITIAL_STATE = {
  skills: null,
  softSkills: [],
  hardSkills: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SKILLS:
      if (action.payload.status === 200) {
        const { data } = action.payload;
        const { data: skillsList } = data;

        const hardSkills = skillsList.filter(item => item.type === 'hard');
        const softSkills = skillsList.filter(item => item.type === 'soft');

        return { ...state, skills: data, hardSkills, softSkills };
      }
      if (action.payload.message === 'Network Error') {
        return { ...state, skills: action.payload.message };
      }
      if (action.payload.response.status === 400) {
        return { ...state, skills: action.payload.response };
      }
      if (action.payload.response.status === 401) {
        return { ...state, skills: action.payload.response };
      }

      break;
    default:
      return state;
  }
}
