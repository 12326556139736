import axios from 'axios';
import { GET_WHITELABEL_INFO, GET_WHITELABEL_ABOUT, GET_WHITELABEL_ABOUT_AS_ADMIN } from '..';
import ROOT_URL from '../../../shared/constants/apiUrl';

export async function getWhiteLabel() {
  const request = await axios.get(`${ROOT_URL}whitelabel`);

  return {
    type: GET_WHITELABEL_INFO,
    payload: request,
  };
}

export async function getWhiteLabelAbout() {
  const request = await axios.get(`${ROOT_URL}whitelabel/about-page?sort=position,asc`);

  return {
    type: GET_WHITELABEL_ABOUT,
    payload: request,
  };
}

export function getWhitelabelAboutAsAdmin(whitelabelId) {
  const request = axios.get(`${ROOT_URL}whitelabel/${whitelabelId}/about-page?sort=position,asc`);

  return {
    type: GET_WHITELABEL_ABOUT_AS_ADMIN,
    payload: request,
  };
}
