// *** ALERT ***
export const CHANGE_ALERT = 'CHANGE_ALERT';

// *** AUCTIONS ***
export const POST_BID = 'POST_BID';
export const POST_SUBSCRIBE = 'POST_SUBSCRIBE';
export const POST_USER_COMMENT = 'POST_USER_COMMENT';
export const POST_COMPANY_COMMENT = 'POST_COMPANY_COMMENT';
export const GET_SUBSCRIBE = 'GET_SUBSCRIBE';
export const GET_AUCTION_DETAIL = 'GET_AUCTION_DETAIL';
export const DELETE_AUCTION_COMMENT = 'DELETE_AUCTION_COMMENT';
export const FETCH_AUCTIONS = 'FETCH_AUCTIONS';
export const FETCH_AUCTIONS_LIST = 'FETCH_AUCTIONS_LIST';
export const FETCH_AUCTIONS_COMMENTS_LIST = 'FETCH_AUCTIONS_COMMENTS_LIST';
export const FETCH_AUCTIONS_BID_LIST = 'FETCH_AUCTIONS_BID_LIST';
export const FETCH_STATISTICS = 'FETCH_STATISTICS';
export const FETCH_ADD = 'FETCH_ADD';
export const FETCH_ADDIMAGES = 'FETCH_ADDIMAGES';
export const FETCH_DELETEIMAGES = 'FETCH_DELETEIMAGES';
export const FETCH_PUBLIC_AUCTIONS_BY_COMPANY = 'FETCH_PUBLIC_AUCTIONS_BY_COMPANY';
export const FETCH_AUCTIONS_USER_COMMENTS_RESPONSE = 'FETCH_AUCTIONS_USER_COMMENTS_RESPONSE';

// *** AUTH USER ***
export const FETCH_AUTHENTICATION = 'FETCH_AUTHENTICATION';
export const FETCH_RECOVERYPASSWORD = 'FETCH_RECOVERYPASSWORD';
export const POST_FACEBOOK_LOGIN = 'POST_FACEBOOK_LOGIN';
export const POST_LOGOUT = 'POST_LOGOUT';
export const POST_RECOVER_PASSWORD_CODE = 'POST_RECOVER_PASSWORD_CODE';
export const POST_SET_PASSWORD = 'POST_SET_PASSWORD';
export const POST_VERIFY_USER_EMAIL = 'POST_VERIFY_USER_EMAIL';
export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';
export const TOGGLE_SWITCH_ACCOUNTS_MODAL = 'TOGGLE_SWITCH_ACCOUNTS_MODAL';

// *** COMPANIES ***
export const POST_COMPANY = 'POST_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const POST_UPDATE_MY_COMPANY = 'POST_UPDATE_MY_COMPANY';
export const GET_ADMINS_COMPANY = 'GET_ADMINS_COMPANY';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const POST_CREATE_DEPARTMENT = 'POST_CREATE_DEPARTMENT';
export const GET_ALL_DEPARTMENTS = 'GET_ALL_DEPARTMENTS';
export const POST_EDIT_DEPARTMENT = 'POST_EDIT_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const POST_UPLOAD_DOCUMENT_COMPANY = 'POST_UPLOAD_DOCUMENT_COMPANY';
export const GET_UPLOAD_DOCUMENT_COMPANY = 'GET_UPLOAD_DOCUMENT_COMPANY';
export const DELETE_UPLOAD_DOCUMENT_COMPANY = 'DELETE_UPLOAD_DOCUMENT_COMPANY';
export const UPDATE_VOLUNTEER_HOURS = 'UPDATE_VOLUNTEER_HOURS';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_TEAMS_WITH_FILTERS = 'GET_TEAMS_WITH_FILTERS';

// *** COUNTRIES ***
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';

// *** CROUDFUNDING ***
export const GET_CROUDFUNDING = 'GET_CROUDFUNDING';
export const GET_CROUDFUNDING_DETAIL = 'GET_CROUDFUNDING_DETAIL';
export const GET_CROUDFUNDING_CONTRIBUTES = 'GET_CROUDFUNDING_CONTRIBUTES';
export const GET_USERS_CONTRIBUTES_LIST = 'GET_USERS_CONTRIBUTES_LIST';
export const GET_SERVERTIME = 'GET_SERVERTIME';
export const GET_USERS_CROWDFUNDING_COMMENTS = 'GET_USERS_CROWDFUNDING_COMMENTS';
export const POST_USERS_CROWDFUNDING_COMMENTS = 'POST_USERS_CROWDFUNDING_COMMENTS';
export const DELETE_USERS_CROWDFUNDING_COMMENTS = 'DELETE_USERS_CROWDFUNDING_COMMENTS';
export const POST_CROWDFUNDING_COMMENT_RESPONSE = 'POST_CROWDFUNDING_COMMENT_RESPONSE';
export const GET_USER_CROWDFUNDING_COMMENT_RESPONSE = 'GET_USER_CROWDFUNDING_COMMENT_RESPONSE';
export const POST_USER_CROWDFUNDING_COMMENT_RESPONSE = 'POST_USER_CROWDFUNDING_COMMENT_RESPONSE';
export const GET_USERS_CROWDFUNDING_COMMENTS_PUSHER = 'GET_USERS_CROWDFUNDING_COMMENTS_PUSHER';
export const GET_USER_CROWDFUNDING_COMMENT_RESPONSE_PUSHER =
  'GET_USER_CROWDFUNDING_COMMENT_RESPONSE_PUSHER';
export const GET_PRIVATE_CROWDFUNDING_LIST = 'GET_PRIVATE_CROWDFUNDING_LIST';
export const GET_PRIVATE_CROWDFUNDING_DETAIL = 'GET_PRIVATE_CROWDFUNDING_DETAIL';
export const GET_PRIVATE_CROWDFUNDING_CONTRIBUTES_LIST =
  'GET_PRIVATE_CROWDFUNDING_CONTRIBUTES_LIST';
export const FETCH_PRIVATE_CROWDFUNDING_COMMENTS = 'FETCH_PRIVATE_CROWDFUNDING_COMMENTS';
export const DELETE_PRIVATE_CROWDFUNDING_COMMENT = 'DELETE_PRIVATE_CROWDFUNDING_COMMENT';
export const POST_PRIVATE_CROWDFUNDING_COMMENT = 'POST_PRIVATE_CROWDFUNDING_COMMENT';
export const GET_CROWDFUNDING_COMMENT_RESPONSE = 'GET_CROWDFUNDING_COMMENT_RESPONSE';
export const POST_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE =
  'POST_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE';
export const DELETE_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE =
  'DELETE_PRIVATE_CROWDFUNDING_COMMENT_RESPONSE';
export const POST_PRIVATE_CAMPAIGN_CONTRIBUTE = 'POST_PRIVATE_CAMPAIGN_CONTRIBUTE';
export const UPLOAD_USER_IMAGE_CROWDFUNDING = 'UPLOAD_USER_IMAGE_CROWDFUNDING';
export const POST_USER_CROWDFUNDING = 'POST_USER_CROWDFUNDING';
export const DELETE_USER_CROWDFUNDING_IMAGE = 'DELETE_USER_CROWDFUNDING_IMAGE';
export const GET_USER_CROWDFUNDING = 'GET_USER_CROWDFUNDING';
export const POST_UPDATE_CROWDFUNDING = 'POST_UPDATE_CROWDFUNDING';
export const GET_USER_MY_CROWDFUNDING = 'GET_USER_MY_CROWDFUNDING';
export const GET_MY_PRIVATE_CROWDFUNDING_LIST = 'GET_MY_PRIVATE_CROWDFUNDING_LIST';

// *** CURRENCIES ***
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';

// *** FEED ***
export const FETCH_FEED = 'FETCH_FEED';
export const GET_PUBLIC_FEED = 'GET_PUBLIC_FEED';
export const FETCH_OTHER_COMPANIES_FEED = 'FETCH_OTHER_COMPANIES_FEED';
export const FETCH_POST_COMMENT = 'FETCH_POST_COMMENT';
export const FETCH_POST = 'FETCH_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const FETCH_COMMENT_POST = 'FETCH_COMMENT_POST';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const FETCH_RESPONSE_COMMENT = 'FETCH_RESPONSE_COMMENT';
export const POST_RESPONSE_COMMENT = 'POST_RESPONSE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_RESPONSE = 'DELETE_RESPONSE';
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE';
export const FETCH_LOVE = 'FETCH_LOVE';
export const FETCH_SCRAPTER = 'FETCH_SCRAPTER';
export const UPLOAD_GALLERY = 'UPLOAD_GALLERY';
export const DELETE_IMAGE_GALLERY = 'DELETE_IMAGE_GALLERY';
export const FETCH_POST_BY_ID = 'FETCH_POST_BY_ID';
export const FETCH_POST_PUBLIC_COMMENT = 'FETCH_POST_PUBLIC_COMMENT';
export const FETCH_PUBLIC_RESPONSE_COMMENT = 'FETCH_PUBLIC_RESPONSE_COMMENT';
export const FETCH_NEW_POST_BY_ID = 'FETCH_NEW_POST_BY_ID';
export const FETCH_PUBLIC_POST_COMMENT = 'FETCH_PUBLIC_POST_COMMENT';
export const FETCH_PUBLIC_REPLIES = 'FETCH_PUBLIC_REPLIES';

// *** GIFTCARDS ***
export const FETCH_GIFTCARDS_BY_EMPLOYEE = 'FETCH_GIFTCARDS_BY_EMPLOYEE';
export const POST_GIFTCARDS_EMPLOYEE_DONATE = 'POST_GIFTCARDS_EMPLOYEE_DONATE';

// *** INDEX ***
export const GET_WHITELABEL_INFO = 'GET_WHITELABEL_INFO';
export const GET_WHITELABEL_ABOUT = 'GET_WHITELABEL_ABOUT';
export const GET_WHITELABEL_ABOUT_AS_ADMIN = 'GET_WHITELABEL_ABOUT_AS_ADMIN';

// *** INSTITUTIONS ***
export const FETCH_INSTITUTIONS = 'FETCH_INSTITUTIONS';
export const FETCH_INSTITUTIONS_CATEGORIES = 'FETCH_INSTITUTIONS_CATEGORIES';
export const POST_INSTITUTION_PAYPAL_DONATION = 'POST_INSTITUTION_PAYPAL_DONATION';
export const POST_INSTITUTION_CANCEL_PAYPAL_DONATION = 'POST_INSTITUTION_CANCEL_PAYPAL_DONATION';
export const POST_INSTITUTION_EXECUTE_PAYPAL_DONATION = 'POST_INSTITUTION_EXECUTE_PAYPAL_DONATION';
export const GET_NPO_DONATIONS_LIST = 'GET_NPO_DONATIONS_LIST';
export const GET_NPO_PAYMENTS = 'GET_NPO_PAYMENTS';
export const GET_NPO_PAYMENT_DETAIL = 'GET_NPO_PAYMENT_DETAIL';
export const POST_INSTITUTION_RECEIPT = 'POST_INSTITUTION_RECEIPT';
export const POST_REQUIRE_PAYMENT = 'POST_REQUIRE_PAYMENT';
export const GET_INSTITUTION = 'GET_INSTITUTION';
export const POST_INSTITUTION_CROWDFUNDING_IMAGES = 'POST_INSTITUTION_CROWDFUNDING_IMAGES';
export const DELETE_CROWDFUNDING_INSTITUTION_IMAGE = 'DELETE_CROWDFUNDING_INSTITUTION_IMAGE';
export const POST_INSTITUTION_CROWDFUNDING = 'POST_INSTITUTION_CROWDFUNDING';
export const GET_INSTITUTION_CROWDFUNDING_DETAIL = 'GET_INSTITUTION_CROWDFUNDING_DETAIL';
export const EDIT_INSTITUTION_CROWDFUNDING = 'EDIT_INSTITUTION_CROWDFUNDING';
export const POST_INSTITUTIONS = 'POST_INSTITUTIONS';

// *** LANGUAGES ***
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const POST_LANGUAGES = 'POST_LANGUAGES';

// *** MEMBERS ***
export const POST_MEMBER_JOIN_FACEBOOK = 'POST_MEMBER_JOIN_FACEBOOK';
export const POST_MEMBER_JOIN = 'POST_MEMBER_JOIN';
export const POST_JOIN_USER_MEMBER = 'POST_JOIN_USER_MEMBER';
export const POST_JOIN_USER_EMPLOYEE = 'POST_JOIN_USER_EMPLOYEE';

// *** NOTIFICATIONS ***
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const POST_NOTIFICATIONS_ALL_AS_READ = 'POST_NOTIFICATIONS_ALL_AS_READ';
export const POST_NOTIFICATION_AS_READ = 'POST_NOTIFICATION_AS_READ';

// *** ODS ***
export const GET_ODS = 'GET_ODS';

// *** ORDER ***
export const POST_ORDER = 'POST_ORDER';
export const GET_SIBS_CHECKOUT_STATUS = 'GET_SIBS_CHECKOUT_STATUS';
export const GET_STATUS_PAYMENT = 'GET_STATUS_PAYMENT';

// *** PRIVATE CROWDFUNDING ***
export const POST_CROWDFUNDING_IMAGES = 'POST_CROWDFUNDING_IMAGES';
export const POST_CROWDFUNDING_CAMPAIGN = 'POST_CROWDFUNDING_CAMPAIGN';
export const UPDATE_CROWDFUNDING_CAMPAIGN = 'UPDATE_CROWDFUNDING_CAMPAIGN';
export const FETCH_CROWDFUNDING_DETAIL = 'FETCH_CROWDFUNDING_DETAIL';
export const POST_CROWDFUNDING_CONTRIBUTE_STATUS = 'POST_CROWDFUNDING_CONTRIBUTE_STATUS';

// *** PROJECTS ***
export const GET_PROJECT_CATEGORIES = 'GET_PROJECT_CATEGORIES';
export const GET_PROJECT_CONFIG = 'GET_PROJECT_CONFIG';
export const GET_PROJECT_CONFIG_BY_ID = 'GET_PROJECT_CONFIG_BY_ID';
export const UPLOAD_PROJECT_IMAGE = 'UPLOAD_PROJECT_IMAGE';
export const DELETE_PROJECT_IMAGE = 'DELETE_PROJECT_IMAGE';
export const POST_CREATE_PROJECT = 'POST_CREATE_PROJECT';
export const GET_USER_PROJECTS_LIST = 'GET_USER_PROJECTS_LIST';
export const GET_PROJECTS_DETAIL = 'GET_PROJECTS_DETAIL';
export const POST_UPDATE_PROJECT = 'POST_UPDATE_PROJECT';
export const GET_PUBLIC_PROJECTS_LIST = 'GET_PUBLIC_PROJECTS_LIST';

// *** PROJECT FILES ***
export const GET_FILES = 'GET_FILES';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FILE = 'DELETE_FILE';

// *** SUBSCRIPTION ***
export const GET_SUBSCRIPTION_BY_ID = 'GET_SUBSCRIPTION_BY_ID';

// *** SURVEYS ***
export const FETCH_FORM = 'FETCH_FORM';
export const POST_SURVEY_RESPONSE = 'POST_SURVEY_RESPONSE';

// *** TICKETS ***
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const POST_TICKET = 'POST_TICKET';
export const FETCH_TICKET = 'FETCH_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const POST_COMMENT = 'POST_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';

// *** USER ***
export const POST_MOBILE_VALIDATE = 'POST_MOBILE_VALIDATE';
export const POST_MOBILE_CONFIRM = 'POST_MOBILE_CONFIRM';
export const POST_SIGNUP_MEMBERS = 'POST_SIGNUP_MEMBERS';
export const POST_SIGNUP_EMPLOYEES = 'POST_SIGNUP_EMPLOYEES';
export const POST_VALIDATE_EMAIL = 'POST_VALIDATE_EMAIL';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const POST_USER_PASSWORD = 'POST_USER_PASSWORD';
export const POST_FACEBOOK_TURNOFF = 'POST_FACEBOOK_TURNOFF';
export const POST_FACEBOOK_CONNECT = 'POST_FACEBOOK_CONNECT';
export const VALIDATE_PAYPAL_EMAIL = 'VALIDATE_PAYPAL_EMAIL';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER_COMPANY = 'UPDATE_USER_COMPANY';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const POST_VERIFY_EMAIL_FOR_REGISTRATION = 'POST_VERIFY_EMAIL_FOR_REGISTRATION';
export const POST_RESEND_EMAIL_VERIFICATION = 'POST_RESEND_EMAIL_VERIFICATION';
export const GET_WHITELABELS_LIST = 'GET_WHITELABELS_LIST';
export const DELETE_WHITELABELS_LIST = 'DELETE_WHITELABELS_LIST';
export const GET_STRIPE_CREDIT_CARD_LIST = 'GET_STRIPE_CREDIT_CARD_LIST';
export const POST_STRIPE_CREDIT_CARD = 'POST_STRIPE_CREDIT_CARD';
export const PUT_PAYMENT_BANK_TRANSFER = 'PUT_PAYMENT_BANK_TRANSFER';
