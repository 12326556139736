import { useContext } from 'react';
import PropsContext from '../../contexts/PropsContext';

const useCompany = () => {
  const context = useContext(PropsContext);
  const { company } = context.initialState.whitelabel.whiteLabelInfo.data;

  return company;
};

export default useCompany;
