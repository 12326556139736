import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as AlertBTS } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Toast from '@esolidar/toolkit/build/elements/toast';
import Banner from '@esolidar/toolkit/build/elements/banner';
import useInterval from '@esolidar/toolkit/build/hooks/useInterval';
import lastElemOf from '@esolidar/toolkit/build/utils/lastElemOf';
import { resetReducer } from '../../../store/actions/misc';

/**
 * Alert interface
 * @param {'banner'|'toast'} type default: banner
 * @param {'snack-bar'|'description'} variant default: description
 * @param {'success'|'warning'|'danger'|'info'} alertClass default: success
 * @param {string} messageTitleId
 * @param {string} messageId
 * @param {void} onClose
 * @param {{label: string, onClick: void}} primaryButton
 * @param {{label: string, onClick: void}} secondaryButton
 */

const AlertBox = () => {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert);

  const [alertsArray, setAlertsArray] = useState([]);

  useEffect(() => {
    const { alertBox: newAlert } = alert;
    if (newAlert.alertVisible === false) return;

    const id = lastElemOf(alertsArray)?.id + 1 || 1;

    dispatch(resetReducer('alertBox'));
    if (!alertsArray.length) setAlertsArray([{ ...newAlert, id }]);
    else {
      const alertAlreadyExists = alertsArray.some(item => item.messageId === newAlert.messageId);
      if (!alertAlreadyExists) setAlertsArray([...alertsArray, { ...newAlert, id }]);
    }
  }, [alert]);

  const handleRemoveAlert = id => setAlertsArray(alertsArray.filter(alert => alert.id !== id));

  return (
    <div className="alert-container">
      {alertsArray.length > 0 &&
        alertsArray.map(alert => (
          <Alert key={alert.id} alert={alert} onRemoveAlert={handleRemoveAlert} />
        ))}
    </div>
  );
};

const Alert = ({ alert, onRemoveAlert }) => {
  const intl = useIntl();
  const {
    id,
    type = 'banner',
    alertClass: status = 'success',
    messageTitleId: title,
    messageId: subtitle,
    onClose,
    primaryButton,
    secondaryButton,
  } = alert;

  let { variant = 'description' } = alert;

  useInterval(() => {
    onRemoveAlert(id);
  }, 5000);

  const handleClose = () => {
    if (onClose) onClose();
    onRemoveAlert(id);
  };

  if (type === 'banner' && variant === 'description' && !title) variant = 'snack-bar';

  return (
    <>
      {type === 'toast' ? (
        <AlertBTS style={{ justifyContent: variant === 'description' ? 'end' : 'center' }}>
          <Toast
            variant={variant}
            status={status}
            title={title ? intl.formatMessage({ id: title }) : undefined}
            subtitle={subtitle ? intl.formatMessage({ id: subtitle }) : undefined}
            onClose={handleClose}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            boxShadow
          />
        </AlertBTS>
      ) : (
        <AlertBTS>
          <Banner
            variant={variant}
            status={status}
            title={title ? intl.formatMessage({ id: title }) : undefined}
            subtitle={subtitle ? intl.formatMessage({ id: subtitle }) : undefined}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            boxShadow
          />
        </AlertBTS>
      )}
    </>
  );
};

Alert.propTypes = {
  alert: PropTypes.any,
  onRemoveAlert: PropTypes.func,
};

export default AlertBox;
